import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Container,
} from "@mui/material";
import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addTypeOfGuestRoom,
  getTypeOfGuestRoom,
  editTypeOfGuestRoom,
} from "../../../services/venues/typeOfGuestRoomAPI";
import { fetchTypeOfGuestRoomGalleries } from "../../../services/venues/venueGuestRoomGalleryAPI";
import ButtonBack from "../../../components/ButtonBack";

const AddEditTypeOfGuestRoom = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode = location === "add-new-type-of-guest-room" ? false : true;

  const [typeOfGuestRoomData, setTypeOfGuestRoomData] = useState({
    icon: "",
    icon_alt_text: "",
    title: "",
    amount: "",
    galleries: [],
  });

  const [guestRoomGallery, setGuestRoomGallery] = useState([]);

  useEffect(() => {
    fetchTypeOfGuestRoomGalleries()
      .then((data) => {
        setGuestRoomGallery(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (id) {
      getTypeOfGuestRoom(id)
        .then((data) => {
          setTypeOfGuestRoomData({
            icon: data.icon,
            icon_alt_text: data.icon_alt_text,
            title: data.title,
            amount: data.amount,
            galleries: data.galleries
              ? data.galleries?.map((list) => list.id)
              : [],
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTypeOfGuestRoomData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isEditMode) {
      editTypeOfGuestRoom(id, typeOfGuestRoomData)
        .then(() => {
          navigate("/type-of-guest-room");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addTypeOfGuestRoom(typeOfGuestRoomData)
        .then(() => {
          navigate("/type-of-guest-room");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  const handleGalleries = (event) => {
    setTypeOfGuestRoomData((prevData) => ({
      ...prevData,
      galleries: event.target.value,
    }));
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Type Of Guest Room
            </Typography>
            <form onSubmit={handleSubmit}>
              <Container>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="title"
                    label="Title"
                    required
                    value={typeOfGuestRoomData.title}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="icon"
                    label="Icon"
                    value={typeOfGuestRoomData.icon}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="icon_alt_text"
                    label="Icon Alt Text"
                    value={typeOfGuestRoomData.icon_alt_text}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="amount"
                    label="Amount"
                    required
                    value={typeOfGuestRoomData.amount}
                    type="number"
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <FormControl sx={{ marginTop: "30px", width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Guest Room Gallery
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiple
                      value={typeOfGuestRoomData.galleries}
                      label="VenueTypes"
                      onChange={handleGalleries}
                    >
                      {guestRoomGallery?.map((gallery) => (
                        <MenuItem key={gallery.id} value={gallery.id}>
                          <img
                            src={gallery.image}
                            alt={gallery.alt_text}
                            style={{ width: "100px", height: "100px" }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Container>

              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-type-of-guest-room"
                    ? "Publish"
                    : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditTypeOfGuestRoom;
