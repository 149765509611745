import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import Layout from "../../../../../layout/Layout";
import { Link } from "react-router-dom";

import TableChartIcon from "@mui/icons-material/TableChart";
import Pagination from "@mui/material/Pagination";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  deleteAmenitiesList,
  fetchAmenitiesLists,
} from "../../../../../services/coworking/unit/AmenitiesListAPI";

export default function CoworkingUnitsAmenitiesList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("title");
  const [isTabularView, setIsTabularView] = useState(false);
  const [data, setData] = useState([]);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const itemsPerPage = 6;

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleDelete = () => {
    if (dataToDelete) {
      deleteAmenitiesList(dataToDelete)
        .then(() => {
          setDeleteDialogOpen(false);
          setData((prevData) =>
            prevData.filter((data) => data.id !== dataToDelete)
          );
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
    setDeleteDialogOpen(false); // Close the dialog after delete
  };

  const handleDeleteButtonClicked = (id) => {
    setDeleteDialogOpen(true);
    setDataToDelete(id);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleTabularViewToggle = () => {
    setIsTabularView(!isTabularView);
  };

  const filteredData = data.filter((item) => {
    if (searchValue === "") return true;
    if (searchBy === "title") {
      return item.title.toLowerCase().includes(searchValue.toLowerCase());
    }
    return true;
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  useEffect(() => {
    fetchAmenitiesLists().then((data) => {
      setData(data);
    });
  }, []);

  return (
    <Layout>
      <div>
        <div>
          <Link
            to="/coworking-units-amenities-section"
            style={{ textDecoration: "none", marginRight: "20px" }}
          >
            <Button variant="outlined">
              <ArrowBackIcon />
              Back
            </Button>
          </Link>
          <TextField
            value={searchValue}
            onChange={handleSearchInputChange}
            label="Search"
            variant="outlined"
            fullWidth
          />

          <select value={searchBy} onChange={handleSearchByChange}>
            <option value="title">Title</option>
          </select>
        </div>

        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Coworking Units Amenities List
        </Typography>

        <div>
          <Link
            to={{
              pathname: "/add-new-coworking-units-amenities-list",
              state: { isEditMode: false },
            }}
          >
            <Button variant="outlined">Add New</Button>
          </Link>

          <Button
            startIcon={<TableChartIcon />}
            onClick={handleTabularViewToggle}
          ></Button>
        </div>

        {filteredData.length === 0 ? (
          <Typography
            variant="h6"
            style={{
              marginTop: "20px",
              color: "red",
              textAlign: "center",
            }}
          >
            There are no results
          </Typography>
        ) : (
          ""
        )}

        {!isTabularView ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Icon</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.reverse().map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <img src={item.icon} alt={item.icon_alt_text} />
                    </TableCell>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>
                      <div>
                        <Link
                          to={{
                            pathname: `/edit-coworking-units-amenities-list/${item.id}`,
                            state: { isEditMode: true },
                          }}
                        >
                          <Button variant="outlined">Edit Item</Button>
                        </Link>
                        <Button
                          variant="outlined"
                          onClick={() => handleDeleteButtonClicked(item.id)}
                        >
                          Delete
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid container spacing={2}>
            {paginatedData.reverse().map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {item.icon}
                    </Typography>
                    <Typography color="textSecondary">{item.title}</Typography>
                    <Link
                      to={{
                        pathname: `/edit-coworking-units-amenities-list/${item.id}`,
                        state: { isEditMode: true },
                      }}
                    >
                      <Button variant="outlined">Edit item</Button>
                    </Link>
                    <Button
                      variant="outlined"
                      onClick={() => handleDeleteButtonClicked(item.id)}
                    >
                      Delete
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        <Pagination
          page={currentPage}
          onChange={handlePageChange}
          style={{ marginTop: "1rem", float: "right" }}
          count={Math.ceil(filteredData.length / itemsPerPage)}
        />

        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Delete Confirmation</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Layout>
  );
}
