import React, { useEffect, useState } from "react";
import { TextField, Button, Grid, Box } from "@mui/material";
import Layout from "../../layout/Layout";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  addCategory,
  editCategory,
  getCategory,
} from "../../services/categoryAPI";
import ButtonBack from "../../components/ButtonBack";

export default function AddEditCategory() {
  const navigate = useNavigate();
  const { id } = useParams();
  let location = useLocation();
  location = location.pathname.slice(1);
  const isEditMode = location === "add-new-category" ? false : true;

  const [category, setCategory] = useState({
    name_me: "",
    name_en: "",
    short_description_me: "",
    short_description_en: "",
    slug_me: "",
    slug_en: "",
    main_image: "",
    meta_title_me: "",
    meta_title_en: "",
    meta_description_me: "",
    meta_description_en: "",
    order: "",
    color: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCategory((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEditMode) {
      editCategory(id, category)
        .then(() => {
          navigate("/categories");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju kategorije:", error);
        });
    } else {
      addCategory(category)
        .then(() => {
          navigate("/categories");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju kategorije:", error);
        });
    }
  };

  useEffect(() => {
    if (id) {
      getCategory(id)
        .then((data) => {
          setCategory({
            name_me: data.name_me,
            name_en: data.name_en,
            short_description_me: data.short_description_me,
            short_description_en: data.short_description_en,
            slug_me: data.slug_me,
            slug_en: data.slug_en,
            main_image: data.main_image,
            meta_title_me: data.meta_title_me,
            meta_title_en: data.meta_title_en,
            meta_description_me: data.meta_description_me,
            meta_description_en: data.meta_description_en,
            order: data.order,
            color: data.color,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  return (
    <Layout>
      <div>
        <ButtonBack />
        <form onSubmit={handleSubmit}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            paddingTop={2}
          >
            <Grid item xs={12} md={6}>
              <h3
                style={{
                  fontWeight: "600",
                  color: "#49559d",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Mne
              </h3>
              <div>
                <TextField
                  name="name_me"
                  label="Title"
                  type="text"
                  required
                  fullWidth
                  value={category.name_me}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="short_description_me"
                  label="Short Description"
                  type="text"
                  multiline
                  rows={3}
                  required
                  fullWidth
                  value={category.short_description_me}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="slug_me"
                  label="Slug"
                  type="text"
                  required
                  fullWidth
                  value={category.slug_me}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="meta_description_me"
                  label="Meta Description"
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  value={category.meta_description_me ?? ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="meta_title_me"
                  label="Meta Title"
                  type="text"
                  fullWidth
                  value={category.meta_title_me ?? ""}
                  onChange={handleInputChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <h3
                style={{
                  fontWeight: "600",
                  color: "#49559d",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Eng
              </h3>
              <div>
                <TextField
                  name="name_en"
                  label="Title"
                  type="text"
                  required
                  fullWidth
                  value={category.name_en}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="short_description_en"
                  label="Short Description"
                  type="text"
                  multiline
                  rows={3}
                  required
                  fullWidth
                  value={category.short_description_en}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="slug_en"
                  label="Slug"
                  type="text"
                  required
                  fullWidth
                  value={category.slug_en}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="meta_description_en"
                  label="Meta Description"
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  value={category.meta_description_en ?? ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="meta_title_en"
                  label="Meta Title"
                  type="text"
                  fullWidth
                  value={category.meta_title_en ?? ""}
                  onChange={handleInputChange}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <TextField
                  name="main_image"
                  label="Main Image URL"
                  required
                  fullWidth
                  value={category.main_image}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="order"
                  type="number"
                  label="Order"
                  fullWidth
                  value={category.order ?? ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="color"
                  type="text"
                  label="Color"
                  fullWidth
                  value={category.color ?? ""}
                  onChange={handleInputChange}
                />
              </div>
            </Grid>
          </Grid>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button type="submit" variant="outlined">
              {location === "add-new-category" ? "Add New" : "Edit Category"}
            </Button>
          </Box>
        </form>
      </div>
    </Layout>
  );
}
