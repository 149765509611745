import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = "https://montenegromice.com/mice-api";

export const fetchDistanceLists = () => {
  return axios
    .get(`${API_URL}/distance-list`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteDistanceList = (distanceListId) => {
  return axios
    .delete(`${API_URL}/distance-list/${distanceListId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju dokumenta: ${error.message}`);
    });
};

export const getDistanceList = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/distance-list/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju dokumenta: ${error.message}`);
  }
};

export const editDistanceList = async (id, distanceListData) => {
  try {
    const response = await axios.put(
      `${API_URL}/distance-list/${id}`,
      distanceListData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju dokumenta: ${error.message}`);
  }
};

export const addDistanceList = (distanceListData) => {
  return axios
    .post(`${API_URL}/distance-list`, distanceListData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju dokumenta: ${error.message}`);
    });
};
