import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Typography,
} from "@mui/material";
import { addFile } from "../services/fileAPI";
import {
  addImageToGallery,
  fetchVenuePhotoGalleries,
} from "../services/venues/venuePhotoGalleryAPI";

const FileUpload = () => {
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [error, setError] = useState("");
  const [galleries, setGalleries] = useState([]);
  const [selectedGallery, setSelectedGallery] = useState("");

  useEffect(() => {
    const loadGalleries = async () => {
      try {
        const data = await fetchVenuePhotoGalleries();
        setGalleries(data);
      } catch (error) {
        setError("Failed to load galleries.");
        console.error("Error fetching galleries:", error);
      }
    };

    loadGalleries();
  }, []);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedExtensions = ["png", "webp", "jpg", "jpeg"];
    const maxSize = 2 * 1024 * 1024; // 2MB

    const filteredFiles = files.filter((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const isAllowedExtension = allowedExtensions.includes(fileExtension);
      const isAllowedSize = file.size <= maxSize;

      if (!isAllowedExtension) {
        setError(
          `Invalid file format: ${file.name}. Only PNG, WEBP, JPG, JPEG are allowed.`
        );
        return false;
      }

      if (!isAllowedSize) {
        setError(`File too large: ${file.name}. Maximum size allowed is 2MB.`);
        return false;
      }

      return true;
    });

    if (filteredFiles.length !== files.length) {
      setSelectedFiles([]); // Clear files if any of them are invalid
    } else {
      setError(""); // Clear error if all files are valid
      setSelectedFiles(filteredFiles);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedFiles.length === 0) {
      setError("Please select at least one valid file!");
      return;
    }

    if (!selectedGallery) {
      setError("Please select a gallery!");
      return;
    }

    setIsLoading(true);
    setError("");
    setUploadStatus("");

    try {
      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append("profile_image", file);
        formData.append("name", file.name);
        formData.append("path", "temporary-path");
        formData.append("folder_id", 1);

        const response = await addFile(formData);
        console.log(response);

        const imageId = response.file.id;
        await addImageToGallery(selectedGallery, imageId);
      }

      setUploadStatus("All files successfully uploaded and added to gallery!");
    } catch (error) {
      setUploadStatus("Failed to upload files or add to gallery.");
      setError(`Error: ${error.message}`);
      console.error("Error uploading files or adding to the gallery:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        padding: "30px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px",
      }}
    >
      <Typography variant="h5" style={{ paddingBottom: "30px" }}>
        Upload images to Gallery:
      </Typography>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          width: "100%",
        }}
      >
        <FormControl style={{ flex: 1 }}>
          <InputLabel id="gallery-select-label">Select Gallery</InputLabel>
          <Select
            labelId="gallery-select-label"
            value={selectedGallery}
            onChange={(e) => setSelectedGallery(e.target.value)}
            required
          >
            {galleries.map((gallery) => (
              <MenuItem key={gallery.id} value={gallery.id}>
                {gallery.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box>
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            multiple
            style={{ display: "none" }}
          />
          <Button
            variant="outlined"
            onClick={() => fileInputRef.current.click()}
            style={{ width: "150px", height: "60px" }}
          >
            Choose File
          </Button>
        </Box>

        <Button
          type="submit"
          variant="outlined"
          disabled={isLoading}
          style={{ width: "150px", height: "60px" }}
        >
          {isLoading ? "Uploading..." : "Upload"}
        </Button>
      </form>

      {/* Prikaz broja izabranih fajlova */}
      {selectedFiles.length > 0 && (
        <Typography variant="body1" style={{ marginTop: "20px" }}>
          {selectedFiles.length} file(s) selected
        </Typography>
      )}

      {uploadStatus && <p>{uploadStatus}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default FileUpload;
