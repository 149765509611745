import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../../../layout/Layout";
import VenueMenu from "../../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addTypeOfGuestRoomGallery,
  getTypeOfGuestRoomGallery,
  editTypeOfGuestRoomGallery,
} from "../../../../services/venues/venueGuestRoomGalleryAPI";
import ButtonBack from "../../../../components/ButtonBack";

const AddEditTypeOfGuestRoom = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode =
    location === "add-new-venue-guest-room-gallery" ? false : true;

  const [guestRoomGalleryData, setGuestRoomGalleryData] = useState({
    image: "",
    description: "",
  });

  useEffect(() => {
    if (id) {
      getTypeOfGuestRoomGallery(id)
        .then((data) => {
          setGuestRoomGalleryData({
            image: data.image,
            description: data.description,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setGuestRoomGalleryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newGuestRoomGalleryData = {
      image: guestRoomGalleryData.image,
      description: guestRoomGalleryData.description,
    };
    if (isEditMode) {
      editTypeOfGuestRoomGallery(id, newGuestRoomGalleryData)
        .then((response) => {
          setGuestRoomGalleryData({
            image: "",
            description: "",
          });

          navigate("/venue-guest-room-gallery");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addTypeOfGuestRoomGallery(newGuestRoomGalleryData)
        .then((response) => {
          setGuestRoomGalleryData({
            ...guestRoomGalleryData,
            image: response.image,
            description: response.description,
          });
          navigate("/venue-guest-room-gallery");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Guest Room Gallery
            </Typography>
            <form onSubmit={handleSubmit}>
              <Container>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="image"
                    label="Image"
                    required
                    value={guestRoomGalleryData.image}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="description"
                    label="Description"
                    multiline
                    required
                    rows={6}
                    value={guestRoomGalleryData.description}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
              </Container>

              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-venue-guest-room-gallery"
                    ? "Publish"
                    : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditTypeOfGuestRoom;
