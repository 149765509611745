import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = "https://montenegromice.com/mice-api";

export const fetchVenuePhotoGalleries = () => {
  return axios
    .get(`${API_URL}/venue-photo-gallery`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteVenuePhotoGallery = (venuePhotoGalleryId) => {
  return axios
    .delete(`${API_URL}/venue-photo-gallery/${venuePhotoGalleryId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju ${error.message}`);
    });
};

export const getVenuePhotoGallery = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/venue-photo-gallery/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju ${error.message}`);
  }
};

export const editVenuePhotoGallery = async (id, venuePhotoGalleryData) => {
  try {
    const response = await axios.put(
      `${API_URL}/venue-photo-gallery/${id}`,
      venuePhotoGalleryData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju ${error.message}`);
  }
};

export const addVenuePhotoGallery = (venuePhotoGalleryData) => {
  return axios
    .post(`${API_URL}/venue-photo-gallery`, venuePhotoGalleryData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju ${error.message}`);
    });
};

export const addImageToGallery = (galleryId, imageId) => {
  return axios
    .post(`${API_URL}/add-image-to-gallery`, {
      gallery_id: galleryId,
      image_id: imageId,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        `Greška pri dodavanju slike u galeriju: ${error.message}`
      );
    });
};

export const getGalleryImages = (galleryId) => {
  return axios
    .post(`${API_URL}/get-gallery-images`, {
      gallery_id: galleryId,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        `Greška pri dobavljanju slika iz galerije: ${error.message}`
      );
    });
};

export const removeImageFromGallery = (galleryId, imageId) => {
  return axios
    .post(`${API_URL}/remove-image-from-gallery`, {
      gallery_id: galleryId,
      image_id: imageId,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        `Greška pri uklanjanju slike iz galerije: ${error.message}`
      );
    });
};

export const checkIfImageExists = async (galleryId, imageId) => {
  try {
    const response = await axios.get(`${API_URL}/check-image-exists`, {
      params: {
        galleryId,
        imageId,
      },
    });

    return response.data.exists;
  } catch (error) {
    console.error(
      "Greška pri proveri postojanja slike u galeriji:",
      error.message
    );
    throw error;
  }
};
