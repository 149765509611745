import axios from "axios";
import { API_URL } from "./venues/apiUrl";

export const fetchCities = () => {
  return axios
    .get(`${API_URL}/cities`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteCity = (cityId) => {
  return axios
    .delete(`${API_URL}/cities/${cityId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju grada: ${error.message}`);
    });
};

export const getCity = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/cities/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju grada: ${error.message}`);
  }
};

export const editCity = async (id, cityData) => {
  try {
    const response = await axios.put(`${API_URL}/cities/${id}`, cityData);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju grada: ${error.message}`);
  }
};

export const addCity = (cityData) => {
  return axios
    .post(`${API_URL}/cities`, cityData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju grada: ${error.message}`);
    });
};
