import axios from 'axios';

export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchAmenitiesSections = () => {
  return axios.get(`${API_URL}/coworking/amenities-sections`)
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.message);
    });
};

export const deleteAmenitiesSection = (amenitiesSectionId) => {
    return axios.delete(`${API_URL}/coworking/amenities-sections/${amenitiesSectionId}`)
      .then(response => response.data)
      .catch(error => {
        throw new Error(`Greška pri brisanju: ${error.message}`);
      });
  };

  export const getAmenitiesSection= async (id) => {
    try {
      const response = await axios.get(`${API_URL}/coworking/amenities-sections/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Greška pri dobavljanju: ${error.message}`);
    }
  };

  export const editAmenitiesSection = async (id, amenitiesSectionData) => {
    try {
      const response = await axios.put(`${API_URL}/coworking/amenities-sections/${id}`, amenitiesSectionData);
      return response.data;
    } catch (error) {
      throw new Error(`Greška pri editovanju: ${error.message}`);
    }
  };

  export const addAmenitiesSection = (amenitiesSectionData) => {
    return axios.post(`${API_URL}/coworking/amenities-sections`, amenitiesSectionData)
      .then(response => response.data)
      .catch(error => {
        throw new Error(`Greška pri dodavanju: ${error.message}`);
      });
  };
