import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Box, Container } from "@mui/material";
import Layout from "../../../layout/Layout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addPhotoGallery,
  getPhotoGallery,
  editPhotoGallery,
} from "../../../services/coworking/photoGalleryAPI";

const AddEditCoworkingPhotoGallery = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode =
    location === "add-new-coworking-photo-gallery" ? false : true;

  const [photoGalleryData, setPhotoGalleryData] = useState({
    image: "",
    altText: "",
  });

  useEffect(() => {
    if (id) {
      getPhotoGallery(id)
        .then((data) => {
          setPhotoGalleryData({
            image: data.image,
            altText: data.alt_text,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPhotoGalleryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newPhotoGalleryData = {
      image: photoGalleryData.image,
      alt_text: photoGalleryData.altText,
    };
    if (isEditMode) {
      editPhotoGallery(id, newPhotoGalleryData)
        .then((response) => {
          setPhotoGalleryData({
            image: "",
            altText: "",
          });

          navigate("/coworking-photo-gallery");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addPhotoGallery(newPhotoGalleryData)
        .then((response) => {
          setPhotoGalleryData({
            ...photoGalleryData,
            image: response.image,
            altText: response.alt_text,
          });
          navigate("/coworking-photo-gallery");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  const handleBack = () => {
    // Handle Back action here
  };

  return (
    <Layout>
      <div>
        <Box style={{ display: "flex" }}>
          <Link
            to="/coworking-photo-gallery"
            style={{ textDecoration: "none" }}
          >
            <Button variant="outlined" onClick={handleBack}>
              <ArrowBackIcon />
              Back
            </Button>
          </Link>
        </Box>
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Coworking Photo Gallery
        </Typography>

        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="image"
              label="Image"
              value={photoGalleryData.image}
              fullWidth
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="altText"
              label="Alt Text"
              value={photoGalleryData.altText}
              fullWidth
              onChange={handleInputChange}
            />
          </div>

          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              {location === "add-new-coworking-photo-gallery"
                ? "Publish"
                : "Save"}
            </Button>
          </Container>
        </form>
      </div>
    </Layout>
  );
};

export default AddEditCoworkingPhotoGallery;
