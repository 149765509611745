import React from "react";
import {
  Button,
  Container,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import Modal from "react-modal";

const DistanceModal = ({
  isFormOpenDistance,
  closeFormModalDistance,
  handleDistanceSubmit,
  distanceSectionList,
  handleInputChangeDistance,
  distanceSectionListData,
  handleInputChangeDistanceUnit,
}) => {
  return (
    <Modal
      isOpen={isFormOpenDistance}
      onRequestClose={closeFormModalDistance}
      contentLabel="Add New Unit Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(73, 85, 157, 0.5)",
        },
        content: {
          backgroundColor: "white",
          top: "20%",
          left: "29%",
          right: "5%",
          bottom: "15%",
          zIndex: "10",
        },
      }}
    >
      <div>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: "center", marginTop: "30px" }}
        >
          Distance
        </Typography>
        <form onSubmit={handleDistanceSubmit}>
          <Container
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <TextField
              sx={{ marginTop: "30px", width: "45%" }}
              name="title"
              label="Title"
              required
              value={distanceSectionList.title}
              onChange={handleInputChangeDistance}
            />
            <TextField
              sx={{ marginTop: "30px", width: "45%" }}
              name="distance_amount"
              type="number"
              label="Distance Amount"
              value={distanceSectionListData.distance_amount}
              onChange={handleInputChangeDistance}
              inputProps={{ step: "0.01", min: "0" }}
            />
            <Select
              defaultValue="km"
              value={distanceSectionListData.distance_unit}
              style={{ height: "55px", marginTop: "30px" }}
              onChange={handleInputChangeDistanceUnit}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="km">km</MenuItem>
              <MenuItem value="m">m</MenuItem>
            </Select>
          </Container>
          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              Publish
            </Button>
          </Container>
        </form>
      </div>
      <Button variant="outlined" onClick={closeFormModalDistance}>
        Close
      </Button>
    </Modal>
  );
};

export default DistanceModal;
