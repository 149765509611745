import React, { useState, useEffect } from "react";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import Layout from "../../../layout/Layout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addTestimonial,
  editTestimonial,
  getTestimonial,
} from "../../../services/coworking/TestimonialAPI";

const AddEditCoworkingTestimonials = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  let location = useLocation();
  location = location.pathname.slice(1);
  const [data, setData] = useState({
    name: "",
    stars: "",
    reviews_text: "",
    image: "",
    position: "",
  });

  const isEditMode =
    location === "add-new-coworking-testimonials" ? false : true;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEditMode) {
      editTestimonial(id, data)
        .then(() => {
          navigate("/coworking-testimonials");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju kategorije:", error);
        });
    } else {
      addTestimonial(data)
        .then(() => {
          navigate("/coworking-testimonials");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju kategorije:", error);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      getTestimonial(id)
        .then((data) => {
          setData({
            name: data.name,
            stars: data.stars,
            reviews_text: data.reviews_text,
            image: data.image,
            position: data.position,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  return (
    <Layout>
      <div>
        <Box style={{ display: "flex" }}>
          <Link to="/coworking-testimonials" style={{ textDecoration: "none" }}>
            <Button variant="outlined" onClick={handleBack}>
              <ArrowBackIcon />
              Back
            </Button>
          </Link>
        </Box>
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Coworking Testimonials
        </Typography>
        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="name"
              label="Name"
              fullWidth
              value={data.name}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="stars"
              label="Stars"
              type="number"
              fullWidth
              value={data.stars}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="image"
              label="Image"
              fullWidth
              value={data.image}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="reviews_text"
              label="Review Text"
              fullWidth
              value={data.reviews_text}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="position"
              label="Position"
              fullWidth
              value={data.position}
              onChange={handleInputChange}
            />
          </div>
          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              {location === "add-new-coworking-testimonials"
                ? "Publish"
                : "Save"}
            </Button>
          </Container>
        </form>
      </div>
    </Layout>
  );
};

export default AddEditCoworkingTestimonials;
