import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { CollectionsOutlined } from "@mui/icons-material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

const pages = [
  {
    name: "Main Gallery",
    icon: <CollectionsOutlined />,
    link: "/gallery",
  },
  {
    name: "Venue Gallery",
    icon: <CollectionsOutlined />,
    link: "/gallery-venue",
  },
];

const GalleryMenu = () => {
  const [active, setActive] = useState("");
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const activePage = pages.find((page) => currentPath.startsWith(page.link));
    if (activePage) {
      setActive(activePage.name);
    } else {
      setActive("");
    }
  }, [location]);

  return (
    <div
      style={{
        width: "25%",
        height: "100%",
        borderRight: "1px solid #ccc",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
        marginTop: "-50px",
        position: "fixed",
        backgroundColor: "#49559d",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{ textAlign: "center", paddingTop: "20px", color: "white" }}
      >
        Venue Menu
      </Typography>
      <div
        style={{
          height: "82%",
          overflowY: "auto",
          borderTop: "1px solid #ccc",
        }}
      >
        <List>
          {pages.map((page) => (
            <ListItem
              key={page.name}
              disablePadding
              style={{
                backgroundColor: active === page.name ? "#e2e2e2" : "#49559d",
                boxShadow:
                  active === page.name ? "2px 2px 4px rgba(0, 0, 0, 0.3)" : "",
                borderRadius: "4px",
              }}
            >
              <Link
                to={page.link}
                style={{
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                <ListItemButton>
                  <ListItemIcon
                    style={{
                      color: active === page.name ? "#49559d" : "#e2e2e2",
                    }}
                  >
                    {page.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={page.name}
                    style={{
                      color: active === page.name ? "#49559d" : "#e2e2e2",
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export default GalleryMenu;
