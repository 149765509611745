import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchDocumentations = () => {
  return axios
    .get(`${API_URL}/documentation`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteDocumentations = (documentationId) => {
  return axios
    .delete(`${API_URL}/documentation/${documentationId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju dokumenta: ${error.message}`);
    });
};

export const getDocumentation = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/documentation/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju dokumenta: ${error.message}`);
  }
};

export const editDocumentation = async (id, documentationData) => {
  try {
    const response = await axios.put(
      `${API_URL}/documentation/${id}`,
      documentationData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju dokumenta: ${error.message}`);
  }
};

export const addDocumentation = (documentationData) => {
  return axios
    .post(`${API_URL}/documentation`, documentationData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju dokumenta: ${error.message}`);
    });
};
