import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container, Box } from "@mui/material";
import Layout from "../../../layout/Layout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addReview,
  editReview,
  getReview,
} from "../../../services/coworking/ReviewsAPI";

const AddEditCoworkingReviews = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);
  const [data, setData] = useState({
    name: "",
    review_link: "",
    stars: "",
  });

  const isEditMode = location === "add-new-coworking-reviews" ? false : true;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEditMode) {
      editReview(id, data)
        .then(() => {
          navigate("/coworking-reviews");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju kategorije:", error);
        });
    } else {
      addReview(data)
        .then(() => {
          navigate("/coworking-reviews");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju kategorije:", error);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      getReview(id)
        .then((data) => {
          setData({
            name: data.name,
            review_link: data.review_link,
            stars: data.stars,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  return (
    <Layout>
      <div>
        <Box style={{ display: "flex" }}>
          <Link to="/coworking-reviews" style={{ textDecoration: "none" }}>
            <Button variant="outlined" onClick={handleBack}>
              <ArrowBackIcon />
              Back
            </Button>
          </Link>
        </Box>
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Coworking Reviews
        </Typography>

        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="name"
              label="Name"
              fullWidth
              value={data.name}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="review_link"
              label="Review Link"
              fullWidth
              value={data.review_link}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="stars"
              label="Stars"
              type="number"
              fullWidth
              value={data.stars}
              onChange={handleInputChange}
            />
          </div>

          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              {location === "add-new-coworking-reviews" ? "Publish" : "Save"}
            </Button>
          </Container>
        </form>
      </div>
    </Layout>
  );
};

export default AddEditCoworkingReviews;
