import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel CSS
import axios from "axios"; // Use Axios for HTTP requests
import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import ButtonBack from "../../../components/ButtonBack";
import { API_URL } from "../../../services/venues/apiUrl";

function PreviewSlider() {
  const [sliderImages, setSliderImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch slider data
  useEffect(() => {
    const fetchSliderImages = async () => {
      try {
        const response = await axios.get(`${API_URL}/get-slider-images`);

        // Filter and sort images by slider_order
        const filteredImages = response.data
          .filter((venue) => venue.is_public)
          .sort((a, b) => a.slider_order - b.slider_order);

        setSliderImages(filteredImages);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };

    fetchSliderImages();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Layout>
      <VenueMenu />
      <ButtonBack />
      <div style={{ width: "73%", marginLeft: "26%" }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: "center", marginBottom: "30px" }}
        >
          Slider Preview
        </Typography>
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          interval={5000}
          showThumbs={false}
          showStatus={false}
          showArrows={true}
          emulateTouch={true}
        >
          {sliderImages.map((image, index) => (
            <div key={image.id} style={{ position: "relative" }}>
              <img
                src={image.featured_image}
                alt={image.name}
                style={{ width: "100%", height: "60vh", borderRadius: "8px" }}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: "20px",
                  left: "20px",
                  color: "white",
                  background: "rgba(0, 0, 0, 0.5)",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="h5">{image.name}</Typography>
                <Typography variant="body2">
                  {image.cities.map((city) => city.name).join(", ")}
                </Typography>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </Layout>
  );
}

export default PreviewSlider;
