import "./App.css";
import Venues from "./pages/Venues/Venues";
import AddEditVenues from "./pages/Venues/AddEditVenue";
import Blog from "./pages/Blog/Blog";
import AddEditBlog from "./pages/Blog/AddEditBlog";
import Categories from "./pages/Categories/Categories";
import Login from "./components/Login/Login";
import Forms from "./pages/Forms";
import Gallery from "./pages/Gallery";
import AddEditCategory from "./pages/Categories/AddEditCategory";
import { Context } from "./context/Context";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useState } from "react";
import Dashboard from "./pages/Dashboard";
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import TypeOfGuestRoom from "./pages/Venues/TypeOfGuestRoom/TypeOfGuestRoom";
import AddEditTypeOfGuestRoom from "./pages/Venues/TypeOfGuestRoom/AddEditTypeOfGuestRoom";
import Distance from "./pages/Venues/Distance/Distance";
import AddEditDistance from "./pages/Venues/Distance/AddEditDistance";
import Documentation from "./pages/Venues/DocumentationPage/Documentation";
import AddEditDocumentation from "./pages/Venues/DocumentationPage/AddEditDocumentation";
import Testimonials from "./pages/Venues/Testimonials/Testimonials";
import AddEditTestimonials from "./pages/Venues/Testimonials/AddEditTestimonials";
import Review from "./pages/Venues/ReviewsPage/Review";
import AddEditReview from "./pages/Venues/ReviewsPage/AddEditReview";
import SocialMedia from "./pages/Venues/SocialMedia/SocialMedia";
import AddEditSocialMedia from "./pages/Venues/SocialMedia/AddEditSocialMedia";
import GeneralInformation from "./pages/Venues/GeneralInformation/GeneralInformation";
import AddEditGeneralInformation from "./pages/Venues/GeneralInformation/AddEditGeneralInformation";
import AddEditAmenities from "./pages/Venues/Amenities/AddEditAmenities";
import VenuePhotoGallery from "./pages/Venues/VenuePhotoGallery/VenuePhotoGallery";
import AddEditVenuePhotoGallery from "./pages/Venues/VenuePhotoGallery/AddEditVenuePhotoGallery";
import TypeOfEvent from "./pages/Venues/TypeOfEvent/TypeOfEvent";
import AddEditTypeOfEvent from "./pages/Venues/TypeOfEvent/AddEditTypeOfEvent";
import VenueVideoGallery from "./pages/Venues/VenueVideoGallery/VenueVideoGallery";
import AddEditVenueVideoGallery from "./pages/Venues/VenueVideoGallery/AddEditVenueVideoGallery";
import VenueUnit from "./pages/Venues/VenueUnist/VenueUnit";
import AddEditVenueUnit from "./pages/Venues/VenueUnist/AddEditVenueUnit";
import Amenities from "./pages/Venues/Amenities/Amenities";
import Coworking from "./pages/Coworking/Coworking";
import AddEditCoworking from "./pages/Coworking/AddEditCoworking";
import AddEditCoworkingAmenities from "./pages/Coworking/CoworkingAmenitiesSection/AddEditCoworkingAmenities";
import AddEditCoworkingCategory from "./pages/Coworking/CoworkingCategory/AddEditCoworkingCategory";
import AddEditCoworkingDistanceSection from "./pages/Coworking/CoworkingDistanceSection/AddEditCoworkingDistanceSection";
import AddEditCoworkingDocumentation from "./pages/Coworking/CoworkingDocumentation/AddEditCoworkingDocumentation";
import AddEditCoworkingGeneralInformation from "./pages/Coworking/CoworkingGeneralInformation/AddEditCoworkingGeneralInformation";
import AddEditCoworkingPhotoGallery from "./pages/Coworking/CoworkingPhotoGallery/AddEditCoworkingPhotoGallery";
import AddEditCoworkingReviews from "./pages/Coworking/CoworkingReviews/AddEditCoworkingReviews";
import AddEditCoworkingSocialMedia from "./pages/Coworking/CoworkingSocialMedia/AddEditCoworkingSocialMedia";
import AddEditCoworkingTestimonials from "./pages/Coworking/CoworkingTestimonials/AddEditCoworkingTestimonials";
import AddEditCoworkingTypeOfEvent from "./pages/Coworking/CoworkingTypeOfEvents/AddEditCoworkingTypeOfEvent";
import AddEditCoworkingUnits from "./pages/Coworking/CoworkingUnits/AddEditCoworkingUnits";
import AddEditCoworkingVideoGallery from "./pages/Coworking/CoworkingVideoGallery/AddEditCoworkingVideoGallery";

import CoworkingAmenities from "./pages/Coworking/CoworkingAmenitiesSection/CoworkingAmenities";
import CoworkingCategory from "./pages/Coworking/CoworkingCategory/CoworkingCategory";
import CoworkingDistanceSection from "./pages/Coworking/CoworkingDistanceSection/CoworkingDistanceSection";
import CoworkingDocumentation from "./pages/Coworking/CoworkingDocumentation/CoworkingDocumentation";
import CoworkingGeneralInformation from "./pages/Coworking/CoworkingGeneralInformation/CoworkingGeneralInformation";
import CoworkingPhotoGallery from "./pages/Coworking/CoworkingPhotoGallery/CoworkingPhotoGallery";
import CoworkingReviews from "./pages/Coworking/CoworkingReviews/CoworkingReviews";
import CoworkingSocialMedia from "./pages/Coworking/CoworkingSocialMedia/CoworkingSocialMedia";
import CoworkingTestimonials from "./pages/Coworking/CoworkingTestimonials/CoworkingTestimonials";
import CoworkingTypeOfEvent from "./pages/Coworking/CoworkingTypeOfEvents/CoworkingTypeOfEvent";
import CoworkingUnits from "./pages/Coworking/CoworkingUnits/CoworkingUnits";
import CoworkingVideoGallery from "./pages/Coworking/CoworkingVideoGallery/CoworkingVideoGallery";
import Category from "./pages/Venues/Category/Category";

import VenueCategory from "./pages/Venues/Category/Category";
import AddEditVenueCategory from "./pages/Venues/Category/AddEditCategory";
import VenueGuestRoomGallery from "./pages/Venues/TypeOfGuestRoom/VenueGuestRoomGallery/VenueGuestRoomGallery";
import AddEditVenueGuestRoomGallery from "./pages/Venues/TypeOfGuestRoom/VenueGuestRoomGallery/AddEditVenueGuestRoomGallery";
import VenueDistanceList from "./pages/Venues/Distance/VenueDistanceList/VenueDistanceList";
import AddEditVenueDistanceList from "./pages/Venues/Distance/VenueDistanceList/AddEditVenueDistanceList";
import VenueAmenitiesList from "./pages/Venues/Amenities/VenueAmenitiesList/VenueAmenitiesList";
import AddEditVenueAmenitiesList from "./pages/Venues/Amenities/VenueAmenitiesList/AddEditVenueAmenitiesList";
import CoworkingDistanceList from "./pages/Coworking/CoworkingDistanceSection/CoworkingDistanceList/CoworkingDistanceList";
import AddEditCoworkingDistanceList from "./pages/Coworking/CoworkingDistanceSection/CoworkingDistanceList/AddEditCoworkingDistanceList";
import AddEditCoworkingAmenitiesList from "./pages/Coworking/CoworkingAmenitiesSection/CoworkingAmenitiesList/AddEditCoworkingAmenitiesList";
import CoworkingAmenitiesList from "./pages/Coworking/CoworkingAmenitiesSection/CoworkingAmenitiesList/CoworkingAmenitiesList";

import CoworkingMeetingRoomCapacities from "./pages/Coworking/CoworkingUnits/CoworkingUnitesMeetingRoomCapacities/CoworkingUnitMeetingRoomCapacities";
import AddEditCoworkingMeetingRoomCapacities from "./pages/Coworking/CoworkingUnits/CoworkingUnitesMeetingRoomCapacities/AddEditCoworkingUnitMeetingRoomCapacities";
import CoworkingUnitesPhotoGallery from "./pages/Coworking/CoworkingUnits/CoworkingUnitsPhotoGallery/CoworkingUnitsPhotoGallery";
import AddEditCoworkingUnitesPhotoGallery from "./pages/Coworking/CoworkingUnits/CoworkingUnitsPhotoGallery/AddEditCoworkingUnitsPhotoGallery";
import CoworkingUnitsExhibits from "./pages/Coworking/CoworkingUnits/CoworkingUnitsExhibits/CoworkingUnitsExhibits";
import AddEditCoworkingUnitsExhibits from "./pages/Coworking/CoworkingUnits/CoworkingUnitsExhibits/AddEditCoworkingUnitsExhibits";
import CoworkingUnitsAmenitiesSection from "./pages/Coworking/CoworkingUnits/CoworkingUnitsAmenitiesSection/CoworkingUnitsAmenitiesSection";
import AddEditCoworkingUnitsAmenitiesSection from "./pages/Coworking/CoworkingUnits/CoworkingUnitsAmenitiesSection/AddEditCoworkingUnitsAmenitiesSection";
import CoworkingUnitsAmenitiesList from "./pages/Coworking/CoworkingUnits/CoworkingUnitsAmenitiesSection/CoworkingUnitsAmenitiesList/CoworkingUnitsAmenitiesList";
import AddEditCoworkingUnitsAmenitiesList from "./pages/Coworking/CoworkingUnits/CoworkingUnitsAmenitiesSection/CoworkingUnitsAmenitiesList/AddEditCoworkingUnitsAmenitiesList";

import VenueMeetingRoomCapacities from "./pages/Venues/VenueUnist/VenueUnitMeetingRoomCapacities/VenueUnitMeetingRoomCapacities";
import AddEditVenueMeetingRoomCapacities from "./pages/Venues/VenueUnist/VenueUnitMeetingRoomCapacities/AddEditVenueUnitMeetingRoomCapacities";
import VenueUnitesPhotoGallery from "./pages/Venues/VenueUnist/VenueUnitsPhotoGallery/VenueUnitsPhotoGallery";
import AddEditVenueUnitesPhotoGallery from "./pages/Venues/VenueUnist/VenueUnitsPhotoGallery/AddEditVenueUnitsPhotoGallery";
import VenueUnitsExhibits from "./pages/Venues/VenueUnist/VenueUnitsExhibits/VenueUnitsExhibits";
import AddEditVenueUnitsExhibits from "./pages/Venues/VenueUnist/VenueUnitsExhibits/AddEditVenueUnitsExhibits";
import VenueUnitsAmenitiesSection from "./pages/Venues/VenueUnist/VenueUnitsAmenitiesSection/VenueUnitsAmenitiesSection";
import AddEditVenueUnitsAmenitiesSection from "./pages/Venues/VenueUnist/VenueUnitsAmenitiesSection/AddEditVenueUnitsAmenitiesSection";
import VenueUnitsAmenitiesList from "./pages/Venues/VenueUnist/VenueUnitsAmenitiesSection/VenueUnitsAmenitiesList/VenueUnitsAmenitiesList";
import AddEditVenueUnitsAmenitiesList from "./pages/Venues/VenueUnist/VenueUnitsAmenitiesSection/VenueUnitsAmenitiesList/AddEditVenueUnitsAmenitiesList";
import VenueInquiry from "./pages/Venues/VenueInquiry/VenueInquiry";
import AddEditVenueInquiry from "./pages/Venues/VenueInquiry/AddEditVenueInquiry";
import VenueUnitsType from "./pages/Venues/VenueUnist/VenueUnitsType/VenueUnitsType";
import AddEditVenueUnitsType from "./pages/Venues/VenueUnist/VenueUnitsType/AddEditVenueUnitsType";
import Cities from "./pages/Cities/Cities";
import AddEditCities from "./pages/Cities/AddEditCities";
import Styles from "./pages/StylesPage/Styles";
import AddEditStyles from "./pages/StylesPage/AddEditStyles";
import PreviewSlider from "./pages/Venues/VenuesSliderOrder/PreviewSlider";
import VenueSliderOrder from "./pages/Venues/VenuesSliderOrder/VenuesSliderOrder";
import GalleryVenue from "./pages/GalleryVenue";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "/venues",
      element: <Venues />,
    },
    {
      path: "/add-new-venue",
      element: <AddEditVenues />,
    },
    {
      path: "/edit-venue",
      element: <AddEditVenues />,
    },
    {
      path: "/edit-venue/:id",
      element: <AddEditVenues />,
    },
    {
      path: "/venues-slider-order",
      element: <VenueSliderOrder />,
    },
    {
      path: "/preview-slider",
      element: <PreviewSlider />,
    },
    {
      path: "/blog",
      element: <Blog />,
    },
    {
      path: "/add-new-blog",
      element: <AddEditBlog />,
    },
    {
      path: "/edit-blog/:id",
      element: <AddEditBlog />,
    },

    {
      path: "/categories",
      element: <Categories />,
    },
    {
      path: "/gallery",
      element: <Gallery />,
    },
    {
      path: "/gallery-venue",
      element: <GalleryVenue />,
    },
    {
      path: "/forms",
      element: <Forms />,
    },
    {
      path: "/add-new-category",
      element: <AddEditCategory />,
    },
    {
      path: "/edit-category",
      element: <AddEditCategory />,
    },
    {
      path: "/type-of-guest-room",
      element: <TypeOfGuestRoom />,
    },
    {
      path: "/add-new-type-of-guest-room",
      element: <AddEditTypeOfGuestRoom />,
    },
    {
      path: "/edit-type-of-guest-room",
      element: <AddEditTypeOfGuestRoom />,
    },
    {
      path: "/edit-type-of-guest-room/:id",
      element: <AddEditTypeOfGuestRoom />,
    },
    {
      path: "/distance",
      element: <Distance />,
    },
    {
      path: "/add-new-distance",
      element: <AddEditDistance />,
    },
    {
      path: "/edit-distance",
      element: <AddEditDistance />,
    },
    {
      path: "/edit-distance/:id",
      element: <AddEditDistance />,
    },
    {
      path: "/documentation",
      element: <Documentation />,
    },
    {
      path: "/add-new-documentation",
      element: <AddEditDocumentation />,
    },
    {
      path: "/edit-documentation",
      element: <AddEditDocumentation />,
    },
    {
      path: "/edit-documentation/:id",
      element: <AddEditDocumentation />,
    },
    {
      path: "/testimonials",
      element: <Testimonials />,
    },
    {
      path: "/add-new-testimonials",
      element: <AddEditTestimonials />,
    },
    {
      path: "/edit-testimonials",
      element: <AddEditTestimonials />,
    },
    {
      path: "/edit-testimonials/:id",
      element: <AddEditTestimonials />,
    },
    {
      path: "/reviews",
      element: <Review />,
    },
    {
      path: "/add-new-reviews",
      element: <AddEditReview />,
    },
    {
      path: "/edit-reviews",
      element: <AddEditReview />,
    },
    {
      path: "/edit-reviews/:id",
      element: <AddEditReview />,
    },
    {
      path: "/social-media",
      element: <SocialMedia />,
    },
    {
      path: "/add-new-social-media",
      element: <AddEditSocialMedia />,
    },
    {
      path: "/edit-social-media",
      element: <AddEditSocialMedia />,
    },
    {
      path: "/edit-social-media/:id",
      element: <AddEditSocialMedia />,
    },
    {
      path: "/general-information",
      element: <GeneralInformation />,
    },
    {
      path: "/add-new-general-information",
      element: <AddEditGeneralInformation />,
    },
    {
      path: "/edit-general-information",
      element: <AddEditGeneralInformation />,
    },
    {
      path: "/edit-general-information/:id",
      element: <AddEditGeneralInformation />,
    },
    {
      path: "/amenities",
      element: <Amenities />,
    },
    {
      path: "/add-new-amenities",
      element: <AddEditAmenities />,
    },
    {
      path: "/edit-amenities",
      element: <AddEditAmenities />,
    },
    {
      path: "/edit-amenities/:id",
      element: <AddEditAmenities />,
    },
    {
      path: "/venue-photo-gallery",
      element: <VenuePhotoGallery />,
    },
    {
      path: "/add-new-venue-photo-gallery",
      element: <AddEditVenuePhotoGallery />,
    },
    {
      path: "/edit-venue-photo-gallery",
      element: <AddEditVenuePhotoGallery />,
    },
    {
      path: "/edit-venue-photo-gallery/:id",
      element: <AddEditVenuePhotoGallery />,
    },
    {
      path: "/type-of-events",
      element: <TypeOfEvent />,
    },
    {
      path: "/add-new-type-of-events",
      element: <AddEditTypeOfEvent />,
    },
    {
      path: "/edit-type-of-events",
      element: <AddEditTypeOfEvent />,
    },
    {
      path: "/edit-type-of-events/:id",
      element: <AddEditTypeOfEvent />,
    },
    {
      path: "/venue-video-gallery",
      element: <VenueVideoGallery />,
    },
    {
      path: "/add-new-venue-video-gallery",
      element: <AddEditVenueVideoGallery />,
    },
    {
      path: "/edit-venue-video-gallery",
      element: <AddEditVenueVideoGallery />,
    },
    {
      path: "/edit-venue-video-gallery/:id",
      element: <AddEditVenueVideoGallery />,
    },
    {
      path: "/venue-units",
      element: <VenueUnit />,
    },
    {
      path: "/add-new-venue-units",
      element: <AddEditVenueUnit />,
    },
    {
      path: "/edit-venue-units",
      element: <AddEditVenueUnit />,
    },
    {
      path: "/edit-venue-units/:id",
      element: <AddEditVenueUnit />,
    },
    {
      path: "/coworking",
      element: <Coworking />,
    },
    {
      path: "/add-coworking",
      element: <AddEditCoworking />,
    },
    {
      path: "/edit-coworking",
      element: <AddEditCoworking />,
    },
    {
      path: "/edit-coworking/:id",
      element: <AddEditCoworking />,
    },
    {
      path: "/Coworking-amenities-section",
      element: <CoworkingAmenities />,
    },
    {
      path: "/add-new-Coworking-amenities-section",
      element: <AddEditCoworkingAmenities />,
    },
    {
      path: "/edit-Coworking-amenities-section",
      element: <AddEditCoworkingAmenities />,
    },
    {
      path: "/edit-Coworking-amenities-section/:id",
      element: <AddEditCoworkingAmenities />,
    },
    {
      path: "/coworking-category",
      element: <CoworkingCategory />,
    },
    {
      path: "/add-new-coworking-category",
      element: <AddEditCoworkingCategory />,
    },
    {
      path: "/edit-coworking-category",
      element: <AddEditCoworkingCategory />,
    },
    {
      path: "/edit-coworking-category/:id",
      element: <AddEditCoworkingCategory />,
    },
    {
      path: "/coworking-distance-section",
      element: <CoworkingDistanceSection />,
    },
    {
      path: "/add-new-coworking-distance-section",
      element: <AddEditCoworkingDistanceSection />,
    },
    {
      path: "/edit-coworking-distance-section",
      element: <AddEditCoworkingDistanceSection />,
    },
    {
      path: "/edit-coworking-distance-section/:id",
      element: <AddEditCoworkingDistanceSection />,
    },
    {
      path: "/coworking-documentation",
      element: <CoworkingDocumentation />,
    },
    {
      path: "/add-new-coworking-documentation",
      element: <AddEditCoworkingDocumentation />,
    },
    {
      path: "/edit-coworking-documentation",
      element: <AddEditCoworkingDocumentation />,
    },
    {
      path: "/edit-coworking-documentation/:id",
      element: <AddEditCoworkingDocumentation />,
    },
    {
      path: "/coworking-general-information",
      element: <CoworkingGeneralInformation />,
    },
    {
      path: "/add-new-coworking-general-information",
      element: <AddEditCoworkingGeneralInformation />,
    },
    {
      path: "/edit-coworking-general-information",
      element: <AddEditCoworkingGeneralInformation />,
    },
    {
      path: "/edit-coworking-general-information/:id",
      element: <AddEditCoworkingGeneralInformation />,
    },
    {
      path: "/coworking-photo-gallery",
      element: <CoworkingPhotoGallery />,
    },
    {
      path: "/add-new-coworking-photo-gallery",
      element: <AddEditCoworkingPhotoGallery />,
    },
    {
      path: "/edit-coworking-photo-gallery",
      element: <AddEditCoworkingPhotoGallery />,
    },
    {
      path: "/edit-coworking-photo-gallery/:id",
      element: <AddEditCoworkingPhotoGallery />,
    },
    {
      path: "/coworking-reviews",
      element: <CoworkingReviews />,
    },
    {
      path: "/add-new-coworking-reviews",
      element: <AddEditCoworkingReviews />,
    },
    {
      path: "/edit-coworking-reviews",
      element: <AddEditCoworkingReviews />,
    },
    {
      path: "/edit-coworking-reviews/:id",
      element: <AddEditCoworkingReviews />,
    },
    {
      path: "/coworking-social-media",
      element: <CoworkingSocialMedia />,
    },
    {
      path: "/add-new-coworking-social-media",
      element: <AddEditCoworkingSocialMedia />,
    },
    {
      path: "/edit-coworking-social-media",
      element: <AddEditCoworkingSocialMedia />,
    },
    {
      path: "/edit-coworking-social-media/:id",
      element: <AddEditCoworkingSocialMedia />,
    },
    {
      path: "/coworking-testimonials",
      element: <CoworkingTestimonials />,
    },
    {
      path: "/add-new-coworking-testimonials",
      element: <AddEditCoworkingTestimonials />,
    },
    {
      path: "/edit-coworking-testimonials",
      element: <AddEditCoworkingTestimonials />,
    },
    {
      path: "/edit-coworking-testimonials/:id",
      element: <AddEditCoworkingTestimonials />,
    },
    {
      path: "/coworking-type-of-event",
      element: <CoworkingTypeOfEvent />,
    },
    {
      path: "/add-new-coworking-type-of-event",
      element: <AddEditCoworkingTypeOfEvent />,
    },
    {
      path: "/edit-coworking-type-of-event",
      element: <AddEditCoworkingTypeOfEvent />,
    },
    {
      path: "/edit-coworking-type-of-event/:id",
      element: <AddEditCoworkingTypeOfEvent />,
    },
    {
      path: "/coworking-unit",
      element: <CoworkingUnits />,
    },
    {
      path: "/add-new-coworking-unit",
      element: <AddEditCoworkingUnits />,
    },
    {
      path: "/edit-coworking-unit",
      element: <AddEditCoworkingUnits />,
    },
    {
      path: "/edit-coworking-unit/:id",
      element: <AddEditCoworkingUnits />,
    },
    {
      path: "/coworking-video-gallery",
      element: <CoworkingVideoGallery />,
    },
    {
      path: "/add-new-coworking-video-gallery",
      element: <AddEditCoworkingVideoGallery />,
    },
    {
      path: "/edit-coworking-video-gallery",
      element: <AddEditCoworkingVideoGallery />,
    },
    {
      path: "/edit-coworking-video-gallery/:id",
      element: <AddEditCoworkingVideoGallery />,
    },
    {
      path: "/venue-category",
      element: <VenueCategory />,
    },
    {
      path: "/add-new-venue-category",
      element: <AddEditVenueCategory />,
    },
    {
      path: "/edit-venue-category",
      element: <AddEditVenueCategory />,
    },
    {
      path: "/edit-venue-category/:id",
      element: <AddEditVenueCategory />,
    },
    {
      path: "/venue-guest-room-gallery",
      element: <VenueGuestRoomGallery />,
    },
    {
      path: "/add-new-venue-guest-room-gallery",
      element: <AddEditVenueGuestRoomGallery />,
    },
    {
      path: "/edit-venue-guest-room-gallery",
      element: <AddEditVenueGuestRoomGallery />,
    },
    {
      path: "/edit-venue-guest-room-gallery/:id",
      element: <AddEditVenueGuestRoomGallery />,
    },
    {
      path: "/venue-distance-list",
      element: <VenueDistanceList />,
    },
    {
      path: "/add-new-venue-distance-list",
      element: <AddEditVenueDistanceList />,
    },
    {
      path: "/edit-venue-distance-list",
      element: <AddEditVenueDistanceList />,
    },
    {
      path: "/edit-venue-distance-list/:id",
      element: <AddEditVenueDistanceList />,
    },
    {
      path: "/venue-amenities-list",
      element: <VenueAmenitiesList />,
    },
    {
      path: "/add-new-venue-amenities-list",
      element: <AddEditVenueAmenitiesList />,
    },
    {
      path: "/edit-venue-amenities-list",
      element: <AddEditVenueAmenitiesList />,
    },
    {
      path: "/edit-venue-amenities-list/:id",
      element: <AddEditVenueAmenitiesList />,
    },
    {
      path: "/coworking-distance-list",
      element: <CoworkingDistanceList />,
    },
    {
      path: "/add-new-coworking-distance-list",
      element: <AddEditCoworkingDistanceList />,
    },
    {
      path: "/edit-coworking-distance-list",
      element: <AddEditCoworkingDistanceList />,
    },
    {
      path: "/edit-coworking-distance-list/:id",
      element: <AddEditCoworkingDistanceList />,
    },
    {
      path: "/coworking-amenities-list",
      element: <CoworkingAmenitiesList />,
    },
    {
      path: "/add-new-coworking-amenities-list",
      element: <AddEditCoworkingAmenitiesList />,
    },
    {
      path: "/edit-coworking-amenities-list",
      element: <AddEditCoworkingAmenitiesList />,
    },
    {
      path: "/edit-coworking-amenities-list/:id",
      element: <AddEditCoworkingAmenitiesList />,
    },
  ]);

  const [active, setActive] = useState("Dashboard");
  const [open, setOpen] = useState(true);

  const { palette } = createTheme();
  const theme = createTheme({
    palette: {
      primary: {
        main: "#49559d",
      },
      secondary: {
        main: "#ffd600",
        contrastText: "#ffffff",
      },
      terinary: palette.augmentColor({
        color: {
          main: "#00ff00",
          contrastText: "#ffffff",
        },
      }),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Context.Provider value={{ active, setActive, open, setOpen }}>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route exact path="/" element={<PrivateRoute />}>
              <Route exact path="/" element={<Dashboard />} />
            </Route>
            <Route exact path="/venues" element={<PrivateRoute />}>
              <Route exact path="/venues" element={<Venues />} />
            </Route>
            <Route exact path="/edit-venue" element={<PrivateRoute />}>
              <Route exact path="/edit-venue" element={<AddEditVenues />} />
            </Route>
            <Route exact path="/edit-venue/:id" element={<PrivateRoute />}>
              <Route exact path="/edit-venue/:id" element={<AddEditVenues />} />
            </Route>
            <Route exact path="/add-new-venue" element={<PrivateRoute />}>
              <Route exact path="/add-new-venue" element={<AddEditVenues />} />
            </Route>
            <Route exact path="/venues-slider-order" element={<PrivateRoute />}>
              <Route
                exact
                path="/venues-slider-order"
                element={<VenueSliderOrder />}
              />
            </Route>
            <Route exact path="/preview-slider" element={<PrivateRoute />}>
              <Route exact path="/preview-slider" element={<PreviewSlider />} />
            </Route>
            <Route exact path="/blog" element={<PrivateRoute />}>
              <Route exact path="/blog" element={<Blog />} />
            </Route>
            <Route exact path="/add-new-blog" element={<PrivateRoute />}>
              <Route exact path="/add-new-blog" element={<AddEditBlog />} />
            </Route>
            <Route exact path="/edit-blog" element={<PrivateRoute />}>
              <Route exact path="/edit-blog" element={<AddEditBlog />} />
            </Route>
            <Route exact path="/edit-blog/:id" element={<PrivateRoute />}>
              <Route exact path="/edit-blog/:id" element={<AddEditBlog />} />
            </Route>
            <Route exact path="/categories" element={<PrivateRoute />}>
              <Route exact path="/categories" element={<Categories />} />
            </Route>
            <Route exact path="/gallery" element={<PrivateRoute />}>
              <Route exact path="/gallery" element={<Gallery />} />
            </Route>
            <Route exact path="/gallery-venue" element={<PrivateRoute />}>
              <Route exact path="/gallery-venue" element={<GalleryVenue />} />
            </Route>
            <Route exact path="/forms" element={<PrivateRoute />}>
              <Route exact path="/forms" element={<Forms />} />
            </Route>
            <Route exact path="/add-new-category" element={<PrivateRoute />}>
              <Route
                exact
                path="/add-new-category"
                element={<AddEditCategory />}
              />
            </Route>
            <Route exact path="/edit-category" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-category"
                element={<AddEditCategory />}
              />
            </Route>
            <Route exact path="/edit-category/:id" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-category/:id"
                element={<AddEditCategory />}
              />
            </Route>
            <Route exact path="/type-of-guest-room" element={<PrivateRoute />}>
              <Route
                exact
                path="/type-of-guest-room"
                element={<TypeOfGuestRoom />}
              />
            </Route>
            <Route
              exact
              path="/edit-type-of-guest-room"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-type-of-guest-room"
                element={<AddEditTypeOfGuestRoom />}
              />
            </Route>
            <Route
              exact
              path="/edit-type-of-guest-room/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-type-of-guest-room/:id"
                element={<AddEditTypeOfGuestRoom />}
              />
            </Route>
            <Route
              exact
              path="/add-new-type-of-guest-room"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-type-of-guest-room"
                element={<AddEditTypeOfGuestRoom />}
              />
            </Route>
            <Route exact path="/distance" element={<PrivateRoute />}>
              <Route exact path="/distance" element={<Distance />} />
            </Route>
            <Route exact path="/edit-distance" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-distance"
                element={<AddEditDistance />}
              />
            </Route>
            <Route exact path="/edit-distance/:id" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-distance/:id"
                element={<AddEditDistance />}
              />
            </Route>
            <Route exact path="/add-new-distance" element={<PrivateRoute />}>
              <Route
                exact
                path="/add-new-distance"
                element={<AddEditDistance />}
              />
            </Route>
            <Route exact path="/documentation" element={<PrivateRoute />}>
              <Route exact path="/documentation" element={<Documentation />} />
            </Route>
            <Route exact path="/edit-documentation" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-documentation"
                element={<AddEditDocumentation />}
              />
            </Route>
            <Route
              exact
              path="/edit-documentation/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-documentation/:id"
                element={<AddEditDocumentation />}
              />
            </Route>
            <Route
              exact
              path="/add-new-documentation"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-documentation"
                element={<AddEditDocumentation />}
              />
            </Route>
            <Route exact path="/testimonials" element={<PrivateRoute />}>
              <Route exact path="/testimonials" element={<Testimonials />} />
            </Route>
            <Route exact path="/edit-testimonials" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-testimonials"
                element={<AddEditTestimonials />}
              />
            </Route>
            <Route
              exact
              path="/edit-testimonials/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-testimonials/:id"
                element={<AddEditTestimonials />}
              />
            </Route>
            <Route
              exact
              path="/add-new-testimonials"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-testimonials"
                element={<AddEditTestimonials />}
              />
            </Route>
            <Route exact path="/reviews" element={<PrivateRoute />}>
              <Route exact path="/reviews" element={<Review />} />
            </Route>
            <Route exact path="/edit-reviews" element={<PrivateRoute />}>
              <Route exact path="/edit-reviews" element={<AddEditReview />} />
            </Route>
            <Route exact path="/edit-reviews/:id" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-reviews/:id"
                element={<AddEditReview />}
              />
            </Route>
            <Route exact path="/add-new-reviews" element={<PrivateRoute />}>
              <Route
                exact
                path="/add-new-reviews"
                element={<AddEditReview />}
              />
            </Route>
            <Route exact path="/social-media" element={<PrivateRoute />}>
              <Route exact path="/social-media" element={<SocialMedia />} />
            </Route>
            <Route exact path="/edit-social-media" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-social-media"
                element={<AddEditSocialMedia />}
              />
            </Route>
            <Route
              exact
              path="/edit-social-media/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-social-media/:id"
                element={<AddEditSocialMedia />}
              />
            </Route>
            <Route
              exact
              path="/add-new-social-media"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-social-media"
                element={<AddEditSocialMedia />}
              />
            </Route>
            <Route exact path="/general-information" element={<PrivateRoute />}>
              <Route
                exact
                path="/general-information"
                element={<GeneralInformation />}
              />
            </Route>
            <Route
              exact
              path="/edit-general-information"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-general-information"
                element={<AddEditGeneralInformation />}
              />
            </Route>
            <Route
              exact
              path="/edit-general-information/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-general-information/:id"
                element={<AddEditGeneralInformation />}
              />
            </Route>
            <Route
              exact
              path="/add-new-general-information"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-general-information"
                element={<AddEditGeneralInformation />}
              />
            </Route>
            <Route exact path="/amenities" element={<PrivateRoute />}>
              <Route exact path="/amenities" element={<Amenities />} />
            </Route>
            <Route exact path="/edit-amenities" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-amenities"
                element={<AddEditAmenities />}
              />
            </Route>
            <Route exact path="/edit-amenities/:id" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-amenities/:id"
                element={<AddEditAmenities />}
              />
            </Route>
            <Route exact path="/add-new-amenities" element={<PrivateRoute />}>
              <Route
                exact
                path="/add-new-amenities"
                element={<AddEditAmenities />}
              />
            </Route>
            <Route exact path="/type-of-events" element={<PrivateRoute />}>
              <Route exact path="/type-of-events" element={<TypeOfEvent />} />
            </Route>
            <Route exact path="/edit-type-of-events" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-type-of-events"
                element={<AddEditTypeOfEvent />}
              />
            </Route>
            <Route
              exact
              path="/edit-type-of-events/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-type-of-events/:id"
                element={<AddEditTypeOfEvent />}
              />
            </Route>
            <Route
              exact
              path="/add-new-type-of-events"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-type-of-events"
                element={<AddEditTypeOfEvent />}
              />
            </Route>
            <Route exact path="/venue-photo-gallery" element={<PrivateRoute />}>
              <Route
                exact
                path="/venue-photo-gallery"
                element={<VenuePhotoGallery />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-photo-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-photo-gallery"
                element={<AddEditVenuePhotoGallery />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-photo-gallery/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-photo-gallery/:id"
                element={<AddEditVenuePhotoGallery />}
              />
            </Route>
            <Route
              exact
              path="/add-new-venue-photo-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-venue-photo-gallery"
                element={<AddEditVenuePhotoGallery />}
              />
            </Route>
            <Route exact path="/venue-video-gallery" element={<PrivateRoute />}>
              <Route
                exact
                path="/venue-video-gallery"
                element={<VenueVideoGallery />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-video-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-video-gallery"
                element={<AddEditVenueVideoGallery />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-video-gallery/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-video-gallery/:id"
                element={<AddEditVenueVideoGallery />}
              />
            </Route>
            <Route
              exact
              path="/add-new-venue-video-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-venue-video-gallery"
                element={<AddEditVenueVideoGallery />}
              />
            </Route>
            <Route exact path="/venue-units" element={<PrivateRoute />}>
              <Route exact path="/venue-units" element={<VenueUnit />} />
            </Route>
            <Route exact path="/edit-venue-units" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-venue-units"
                element={<AddEditVenueUnit />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-units/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-units/:id"
                element={<AddEditVenueUnit />}
              />
            </Route>
            <Route exact path="/add-new-venue-units" element={<PrivateRoute />}>
              <Route
                exact
                path="/add-new-venue-units"
                element={<AddEditVenueUnit />}
              />
            </Route>
            <Route exact path="/coworking" element={<PrivateRoute />}>
              <Route exact path="/coworking" element={<Coworking />} />
            </Route>
            <Route exact path="/edit-coworking" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-coworking"
                element={<AddEditCoworking />}
              />
            </Route>
            <Route exact path="/edit-coworking/:id" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-coworking/:id"
                element={<AddEditCoworking />}
              />
            </Route>
            <Route exact path="/add-new-coworking" element={<PrivateRoute />}>
              <Route
                exact
                path="/add-new-coworking"
                element={<AddEditCoworking />}
              />
            </Route>
            <Route
              exact
              path="/coworking-amenities-section"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-amenities-section"
                element={<CoworkingAmenities />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-amenities-section"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-amenities-section"
                element={<AddEditCoworkingAmenities />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-amenities-section/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-amenities-section/:id"
                element={<AddEditCoworkingAmenities />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-amenities-section"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-amenities-section"
                element={<AddEditCoworkingAmenities />}
              />
            </Route>
            <Route exact path="/coworking-category" element={<PrivateRoute />}>
              <Route
                exact
                path="/coworking-category"
                element={<CoworkingCategory />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-category"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-category"
                element={<AddEditCoworkingCategory />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-category/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-category/:id"
                element={<AddEditCoworkingCategory />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-category"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-category"
                element={<AddEditCoworkingCategory />}
              />
            </Route>
            <Route
              exact
              path="/coworking-distance-section"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-distance-section"
                element={<CoworkingDistanceSection />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-distance-section"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-distance-section"
                element={<AddEditCoworkingDistanceSection />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-distance-section/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-distance-section/:id"
                element={<AddEditCoworkingDistanceSection />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-distance-section"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-distance-section"
                element={<AddEditCoworkingDistanceSection />}
              />
            </Route>
            <Route
              exact
              path="/coworking-documentation"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-documentation"
                element={<CoworkingDocumentation />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-documentation"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-documentation"
                element={<AddEditCoworkingDocumentation />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-documentation/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-documentation/:id"
                element={<AddEditCoworkingDocumentation />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-documentation"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-documentation"
                element={<AddEditCoworkingDocumentation />}
              />
            </Route>
            <Route
              exact
              path="/coworking-general-information"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-general-information"
                element={<CoworkingGeneralInformation />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-general-information"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-general-information"
                element={<AddEditCoworkingGeneralInformation />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-general-information/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-general-information/:id"
                element={<AddEditCoworkingGeneralInformation />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-general-information"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-general-information"
                element={<AddEditCoworkingGeneralInformation />}
              />
            </Route>
            <Route
              exact
              path="/coworking-photo-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-photo-gallery"
                element={<CoworkingPhotoGallery />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-photo-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-photo-gallery"
                element={<AddEditCoworkingPhotoGallery />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-photo-gallery/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-photo-gallery/:id"
                element={<AddEditCoworkingPhotoGallery />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-photo-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-photo-gallery"
                element={<AddEditCoworkingPhotoGallery />}
              />
            </Route>
            <Route exact path="/coworking-reviews" element={<PrivateRoute />}>
              <Route
                exact
                path="/coworking-reviews"
                element={<CoworkingReviews />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-reviews"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-reviews"
                element={<AddEditCoworkingReviews />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-reviews/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-reviews/:id"
                element={<AddEditCoworkingReviews />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-reviews"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-reviews"
                element={<AddEditCoworkingReviews />}
              />
            </Route>
            <Route
              exact
              path="/coworking-social-media"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-social-media"
                element={<CoworkingSocialMedia />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-social-media"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-social-media"
                element={<AddEditCoworkingSocialMedia />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-social-media/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-social-media/:id"
                element={<AddEditCoworkingSocialMedia />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-social-media"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-social-media"
                element={<AddEditCoworkingSocialMedia />}
              />
            </Route>
            <Route
              exact
              path="/coworking-testimonials"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-testimonials"
                element={<CoworkingTestimonials />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-testimonials"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-testimonials"
                element={<AddEditCoworkingTestimonials />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-testimonials/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-testimonials/:id"
                element={<AddEditCoworkingTestimonials />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-testimonials"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-testimonials"
                element={<AddEditCoworkingTestimonials />}
              />
            </Route>
            <Route
              exact
              path="/coworking-type-of-event"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-type-of-event"
                element={<CoworkingTypeOfEvent />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-type-of-event"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-type-of-event"
                element={<AddEditCoworkingTypeOfEvent />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-type-of-event/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-type-of-event/:id"
                element={<AddEditCoworkingTypeOfEvent />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-type-of-event"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-type-of-event"
                element={<AddEditCoworkingTypeOfEvent />}
              />
            </Route>
            <Route exact path="/coworking-unit" element={<PrivateRoute />}>
              <Route
                exact
                path="/coworking-unit"
                element={<CoworkingUnits />}
              />
            </Route>
            <Route exact path="/edit-coworking-unit" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-coworking-unit"
                element={<AddEditCoworkingUnits />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-unit/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-unit/:id"
                element={<AddEditCoworkingUnits />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-unit"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-unit"
                element={<AddEditCoworkingUnits />}
              />
            </Route>
            <Route
              exact
              path="/coworking-video-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-video-gallery"
                element={<CoworkingVideoGallery />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-video-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-video-gallery"
                element={<AddEditCoworkingVideoGallery />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-video-gallery/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-video-gallery/:id"
                element={<AddEditCoworkingVideoGallery />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-video-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-video-gallery"
                element={<AddEditCoworkingVideoGallery />}
              />
            </Route>
            <Route exact path="/venue-category" element={<PrivateRoute />}>
              <Route exact path="/venue-category" element={<VenueCategory />} />
            </Route>
            <Route exact path="/edit-venue-category" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-venue-category"
                element={<AddEditVenueCategory />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-category/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-category/:id"
                element={<AddEditVenueCategory />}
              />
            </Route>
            <Route
              exact
              path="/add-new-venue-category"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-venue-category"
                element={<AddEditVenueCategory />}
              />
            </Route>
            <Route
              exact
              path="/venue-guest-room-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/venue-guest-room-gallery"
                element={<VenueGuestRoomGallery />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-guest-room-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-guest-room-gallery"
                element={<AddEditVenueGuestRoomGallery />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-guest-room-gallery/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-guest-room-gallery/:id"
                element={<AddEditVenueGuestRoomGallery />}
              />
            </Route>
            <Route
              exact
              path="/add-new-venue-guest-room-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-venue-guest-room-gallery"
                element={<AddEditVenueGuestRoomGallery />}
              />
            </Route>
            <Route exact path="/venue-distance-list" element={<PrivateRoute />}>
              <Route
                exact
                path="/venue-distance-list"
                element={<VenueDistanceList />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-distance-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-distance-list"
                element={<AddEditVenueDistanceList />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-distance-list/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-distance-list/:id"
                element={<AddEditVenueDistanceList />}
              />
            </Route>
            <Route
              exact
              path="/add-new-venue-distance-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-venue-distance-list"
                element={<AddEditVenueDistanceList />}
              />
            </Route>
            <Route
              exact
              path="/venue-amenities-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/venue-amenities-list"
                element={<VenueAmenitiesList />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-amenities-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-amenities-list"
                element={<AddEditVenueAmenitiesList />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-amenities-list/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-amenities-list/:id"
                element={<AddEditVenueAmenitiesList />}
              />
            </Route>
            <Route
              exact
              path="/add-new-venue-amenities-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-venue-amenities-list"
                element={<AddEditVenueAmenitiesList />}
              />
            </Route>
            <Route
              exact
              path="/coworking-distance-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-distance-list"
                element={<CoworkingDistanceList />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-distance-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-distance-list"
                element={<AddEditCoworkingDistanceList />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-distance-list/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-distance-list/:id"
                element={<AddEditCoworkingDistanceList />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-distance-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-distance-list"
                element={<AddEditCoworkingDistanceList />}
              />
            </Route>
            <Route
              exact
              path="/coworking-amenities-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-amenities-list"
                element={<CoworkingAmenitiesList />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-amenities-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-amenities-list"
                element={<AddEditCoworkingAmenitiesList />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-amenities-list/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-amenities-list/:id"
                element={<AddEditCoworkingAmenitiesList />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-amenities-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-amenities-list"
                element={<AddEditCoworkingAmenitiesList />}
              />
            </Route>

            <Route
              exact
              path="/coworking-meeting-room-capacities"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-meeting-room-capacities"
                element={<CoworkingMeetingRoomCapacities />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-meeting-room-capacities"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-meeting-room-capacities"
                element={<AddEditCoworkingMeetingRoomCapacities />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-meeting-room-capacities/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-meeting-room-capacities/:id"
                element={<AddEditCoworkingMeetingRoomCapacities />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-meeting-room-capacities"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-meeting-room-capacities"
                element={<AddEditCoworkingMeetingRoomCapacities />}
              />
            </Route>
            <Route
              exact
              path="/coworking-units-photo-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-units-photo-gallery"
                element={<CoworkingUnitesPhotoGallery />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-units-photo-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-units-photo-gallery"
                element={<AddEditCoworkingUnitesPhotoGallery />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-units-photo-gallery/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-units-photo-gallery/:id"
                element={<AddEditCoworkingUnitesPhotoGallery />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-units-photo-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-units-photo-gallery"
                element={<AddEditCoworkingUnitesPhotoGallery />}
              />
            </Route>
            <Route exact path="/coworking-exhibits" element={<PrivateRoute />}>
              <Route
                exact
                path="/coworking-exhibits"
                element={<CoworkingUnitsExhibits />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-exhibits"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-exhibits"
                element={<AddEditCoworkingUnitsExhibits />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-exhibits/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-exhibits/:id"
                element={<AddEditCoworkingUnitsExhibits />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-exhibits"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-exhibits"
                element={<AddEditCoworkingUnitsExhibits />}
              />
            </Route>
            <Route
              exact
              path="/coworking-units-amenities-section"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-units-amenities-section"
                element={<CoworkingUnitsAmenitiesSection />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-units-amenities-section"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-units-amenities-section"
                element={<AddEditCoworkingUnitsAmenitiesSection />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-units-amenities-section/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-units-amenities-section/:id"
                element={<AddEditCoworkingUnitsAmenitiesSection />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-units-amenities-section"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-units-amenities-section"
                element={<AddEditCoworkingUnitsAmenitiesSection />}
              />
            </Route>
            <Route
              exact
              path="/coworking-units-amenities-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/coworking-units-amenities-list"
                element={<CoworkingUnitsAmenitiesList />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-units-amenities-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-units-amenities-list"
                element={<AddEditCoworkingUnitsAmenitiesList />}
              />
            </Route>
            <Route
              exact
              path="/edit-coworking-units-amenities-list/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-coworking-units-amenities-list/:id"
                element={<AddEditCoworkingUnitsAmenitiesList />}
              />
            </Route>
            <Route
              exact
              path="/add-new-coworking-units-amenities-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-coworking-units-amenities-list"
                element={<AddEditCoworkingUnitsAmenitiesList />}
              />
            </Route>

            <Route
              exact
              path="/venue-meeting-room-capacities"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/venue-meeting-room-capacities"
                element={<VenueMeetingRoomCapacities />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-meeting-room-capacities"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-meeting-room-capacities"
                element={<AddEditVenueMeetingRoomCapacities />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-meeting-room-capacities/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-meeting-room-capacities/:id"
                element={<AddEditVenueMeetingRoomCapacities />}
              />
            </Route>
            <Route
              exact
              path="/add-new-venue-meeting-room-capacities"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-venue-meeting-room-capacities"
                element={<AddEditVenueMeetingRoomCapacities />}
              />
            </Route>
            <Route
              exact
              path="/venue-units-photo-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/venue-units-photo-gallery"
                element={<VenueUnitesPhotoGallery />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-units-photo-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-units-photo-gallery"
                element={<AddEditVenueUnitesPhotoGallery />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-units-photo-gallery/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-units-photo-gallery/:id"
                element={<AddEditVenueUnitesPhotoGallery />}
              />
            </Route>
            <Route
              exact
              path="/add-new-venue-units-photo-gallery"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-venue-units-photo-gallery"
                element={<AddEditVenueUnitesPhotoGallery />}
              />
            </Route>
            <Route exact path="/venue-exhibits" element={<PrivateRoute />}>
              <Route
                exact
                path="/venue-exhibits"
                element={<VenueUnitsExhibits />}
              />
            </Route>
            <Route exact path="/edit-venue-exhibits" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-venue-exhibits"
                element={<AddEditVenueUnitsExhibits />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-exhibits/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-exhibits/:id"
                element={<AddEditVenueUnitsExhibits />}
              />
            </Route>
            <Route
              exact
              path="/add-new-venue-exhibits"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-venue-exhibits"
                element={<AddEditVenueUnitsExhibits />}
              />
            </Route>
            <Route
              exact
              path="/venue-units-amenities-section"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/venue-units-amenities-section"
                element={<VenueUnitsAmenitiesSection />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-units-amenities-section"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-units-amenities-section"
                element={<AddEditVenueUnitsAmenitiesSection />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-units-amenities-section/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-units-amenities-section/:id"
                element={<AddEditVenueUnitsAmenitiesSection />}
              />
            </Route>
            <Route
              exact
              path="/add-new-venue-units-amenities-section"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-venue-units-amenities-section"
                element={<AddEditVenueUnitsAmenitiesSection />}
              />
            </Route>
            <Route
              exact
              path="/venue-units-amenities-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/venue-units-amenities-list"
                element={<VenueUnitsAmenitiesList />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-units-amenities-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-units-amenities-list"
                element={<AddEditVenueUnitsAmenitiesList />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-units-amenities-list/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-units-amenities-list/:id"
                element={<AddEditVenueUnitsAmenitiesList />}
              />
            </Route>
            <Route
              exact
              path="/add-new-venue-units-amenities-list"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-venue-units-amenities-list"
                element={<AddEditVenueUnitsAmenitiesList />}
              />
            </Route>

            <Route exact path="/venue-inquiries" element={<PrivateRoute />}>
              <Route exact path="/venue-inquiries" element={<VenueInquiry />} />
            </Route>
            <Route
              exact
              path="/edit-venue-inquiries"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-inquiries"
                element={<AddEditVenueInquiry />}
              />
            </Route>
            <Route
              exact
              path="/edit-venue-inquiries/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/edit-venue-inquiries/:id"
                element={<AddEditVenueInquiry />}
              />
            </Route>
            <Route
              exact
              path="/add-new-venue-inquiries"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/add-new-venue-inquiries"
                element={<AddEditVenueInquiry />}
              />
            </Route>

            <Route exact path="/unit-types" element={<PrivateRoute />}>
              <Route exact path="/unit-types" element={<VenueUnitsType />} />
            </Route>
            <Route exact path="/edit-unit-types" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-unit-types"
                element={<AddEditVenueUnitsType />}
              />
            </Route>
            <Route exact path="/edit-unit-types/:id" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-unit-types/:id"
                element={<AddEditVenueUnitsType />}
              />
            </Route>
            <Route exact path="/add-new-unit-types" element={<PrivateRoute />}>
              <Route
                exact
                path="/add-new-unit-types"
                element={<AddEditVenueUnitsType />}
              />
            </Route>

            <Route exact path="/cities" element={<PrivateRoute />}>
              <Route exact path="/cities" element={<Cities />} />
            </Route>
            <Route exact path="/edit-cities" element={<PrivateRoute />}>
              <Route exact path="/edit-cities" element={<AddEditCities />} />
            </Route>
            <Route exact path="/edit-cities/:id" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-cities/:id"
                element={<AddEditCities />}
              />
            </Route>
            <Route exact path="/add-new-cities" element={<PrivateRoute />}>
              <Route exact path="/add-new-cities" element={<AddEditCities />} />
            </Route>

            <Route exact path="/styles" element={<PrivateRoute />}>
              <Route exact path="/styles" element={<Styles />} />
            </Route>
            <Route exact path="/edit-styles" element={<PrivateRoute />}>
              <Route exact path="/edit-styles" element={<AddEditStyles />} />
            </Route>
            <Route exact path="/edit-styles/:id" element={<PrivateRoute />}>
              <Route
                exact
                path="/edit-styles/:id"
                element={<AddEditStyles />}
              />
            </Route>
            <Route exact path="/add-new-styles" element={<PrivateRoute />}>
              <Route exact path="/add-new-styles" element={<AddEditStyles />} />
            </Route>
          </Routes>
        </Router>
      </Context.Provider>
    </ThemeProvider>
  );
}

export default App;
