import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchAmenitiesSections = () => {
  return axios
    .get(`${API_URL}/amenities-sections`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteAmenitiesSection = (amenitiesSectionId) => {
  return axios
    .delete(`${API_URL}/amenities-sections/${amenitiesSectionId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju dokumenta: ${error.message}`);
    });
};

export const getAmenitiesSection = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/amenities-sections/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju dokumenta: ${error.message}`);
  }
};

export const editAmenitiesSection = async (id, amenitiesSectionData) => {
  try {
    const response = await axios.put(
      `${API_URL}/amenities-sections/${id}`,
      amenitiesSectionData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju dokumenta: ${error.message}`);
  }
};

export const addAmenitiesSection = (amenitiesSectionData) => {
  return axios
    .post(`${API_URL}/amenities-sections`, amenitiesSectionData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju dokumenta: ${error.message}`);
    });
};
