import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container, Box } from "@mui/material";
import Layout from "../../../layout/Layout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addTypeOfEvent,
  getTypeOfEvent,
  editTypeOfEvent,
} from "../../../services/coworking/typeOfEventAPI";

const AddEditCoworkingTypeOfEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode =
    location === "add-new-coworking-type-of-event" ? false : true;

  const [typeOfEventData, setTypeOfEventData] = useState({
    icon: "",
    iconAltText: "",
    title: "",
  });

  useEffect(() => {
    if (id) {
      getTypeOfEvent(id)
        .then((data) => {
          setTypeOfEventData({
            icon: data.icon,
            iconAltText: data.icon_alt_text,
            title: data.title,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTypeOfEventData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newTypeOfEventData = {
      icon: typeOfEventData.icon,
      icon_alt_text: typeOfEventData.iconAltText,
      title: typeOfEventData.title,
    };
    if (isEditMode) {
      editTypeOfEvent(id, newTypeOfEventData)
        .then((response) => {
          setTypeOfEventData({
            icon: "",
            iconAltText: "",
            title: "",
          });

          navigate("/coworking-type-of-event");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addTypeOfEvent(newTypeOfEventData)
        .then((response) => {
          setTypeOfEventData({
            ...typeOfEventData,
            icon: response.icon,
            iconAltText: response.icon_alt_text,
            title: response.title,
          });
          navigate("/coworking-type-of-event");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  const handleBack = () => {
    // Handle Back action here
  };

  return (
    <Layout>
      <div>
        <Box style={{ display: "flex" }}>
          <Link
            to="/coworking-type-of-event"
            style={{ textDecoration: "none" }}
          >
            <Button variant="outlined" onClick={handleBack}>
              <ArrowBackIcon />
              Back
            </Button>
          </Link>
        </Box>
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Coworking Type Of Event
        </Typography>

        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="icon"
              label="Icon"
              value={typeOfEventData.icon}
              fullWidth
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="iconAltText"
              label="Icon Alt Text"
              value={typeOfEventData.iconAltText}
              fullWidth
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="title"
              label="Title"
              value={typeOfEventData.title}
              fullWidth
              onChange={handleInputChange}
            />
          </div>

          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              {location === "add-new-coworking-type-of-event"
                ? "Publish"
                : "Save"}
            </Button>
          </Container>
        </form>
      </div>
    </Layout>
  );
};

export default AddEditCoworkingTypeOfEvent;
