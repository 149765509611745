import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = "https://montenegromice.com/mice-api";

export const fetchVenueUnits = () => {
  return axios
    .get(`${API_URL}/venue-units`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteVenueUnit = (venueUnitId) => {
  return axios
    .delete(`${API_URL}/venue-units/${venueUnitId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju dokumenta: ${error.message}`);
    });
};

export const getVenueUnit = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/venue-units/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju dokumenta: ${error.message}`);
  }
};

export const editVenueUnit = async (id, venueUnitData) => {
  try {
    const response = await axios.put(
      `${API_URL}/venue-units/${id}`,
      venueUnitData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju dokumenta: ${error.message}`);
  }
};

export const addVenueUnit = (venueUnitData) => {
  return axios
    .post(`${API_URL}/venue-units`, venueUnitData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju dokumenta: ${error.message}`);
    });
};

export const addImageToUnit = (unitId, imageId) => {
  return axios
    .post(`${API_URL}/add-image-to-unit`, {
      unit_id: unitId,
      image_id: imageId,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        `Greška pri dodavanju slike u galeriju: ${error.message}`
      );
    });
};

export const getUnitImages = (unitId) => {
  return axios
    .post(`${API_URL}/get-unit-images`, {
      unit_id: unitId,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        `Greška pri dobavljanju slika iz galerije: ${error.message}`
      );
    });
};

export const removeImageFromUnit = (unitId, imageId) => {
  return axios
    .post(`${API_URL}/remove-image-from-unit`, {
      unit_id: unitId,
      image_id: imageId,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        `Greška pri uklanjanju slike iz galerije: ${error.message}`
      );
    });
};

export const checkIfImageExistsUnit = async (unitId, imageId) => {
  try {
    const response = await axios.get(`${API_URL}/check-image-exists-unit`, {
      params: {
        unitId,
        imageId,
      },
    });

    return response.data.exists;
  } catch (error) {
    console.error(
      "Greška pri proveri postojanja slike u galeriji:",
      error.message
    );
    throw error;
  }
};
