import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../../../layout/Layout";
import VenueMenu from "../../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addUnitType,
  getUnitType,
  editUnitType,
} from "../../../../services/venues/unitType";
import ButtonBack from "../../../../components/ButtonBack";

const AddEditVenueUnitsExhibits = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode = location === "add-new-unit-types" ? false : true;

  const [unitTypeData, setUnitTypeData] = useState({
    title: "",
  });

  useEffect(() => {
    if (id) {
      getUnitType(id)
        .then((data) => {
          setUnitTypeData({
            title: data.title,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUnitTypeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEditMode) {
      editUnitType(id, unitTypeData)
        .then(() => {
          navigate("/unit-types");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addUnitType(unitTypeData)
        .then(() => {
          navigate("/unit-types");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Venue Units Exhibits
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="title"
                    label="Title"
                    required
                    value={unitTypeData.title}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-unit-types" ? "Publish" : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditVenueUnitsExhibits;
