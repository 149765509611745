import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  CollectionsOutlined,
  ContactMailOutlined,
  DashboardOutlined,
  MuseumOutlined,
  PostAddOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Avatar, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../services/userAPI";
import CategoryIcon from "@mui/icons-material/Category";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import VerifiedIcon from "@mui/icons-material/Verified";
import ReviewsIcon from "@mui/icons-material/Reviews";
import ShareIcon from "@mui/icons-material/Share";
import InfoIcon from "@mui/icons-material/Info";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import EventIcon from "@mui/icons-material/Event";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import StyleIcon from "@mui/icons-material/Style";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import BurstModeIcon from "@mui/icons-material/BurstMode";

import "./sidebar.css";

const drawerWidth = 240;
const pages = [
  { name: "Dashboard", icon: <DashboardOutlined />, link: "/" },
  {
    name: "Venues",
    icon: <MuseumOutlined />,
    link: "/venues",
  },
  {
    name: "Coworking",
    icon: <MuseumOutlined />,
    link: "/coworking",
  },
  {
    name: "Blog",
    icon: <PostAddOutlined />,
    link: "/blog",
  },

  { name: "Gallery", icon: <CollectionsOutlined />, link: "/gallery" },
  { name: "Forms", icon: <ContactMailOutlined />, link: "/forms" },
];

const subPages = [
  {
    name: "Venues List",
    icon: <MuseumOutlined fontSize="5px" />,
    link: "/venues",
  },
  {
    name: "Venues Slider Order",
    icon: <BurstModeIcon fontSize="5px" />,
    link: "/venues-slider-order",
  },
  // {
  //   name: "Guest Room",
  //   icon: <BedroomParentIcon fontSize="5px" />,
  //   link: "/type-of-guest-room",
  // },
  {
    name: "Category",
    icon: <CategoryIcon fontSize="5px" />,
    link: "/venue-category",
  },
  // {
  //   name: "Distance",
  //   icon: <SpaceBarIcon fontSize="5px" />,
  //   link: "/distance",
  // },
  {
    name: "Documentation",
    icon: <AttachFileIcon fontSize="5px" />,
    link: "/documentation",
  },
  {
    name: "Testimonials",
    icon: <VerifiedIcon fontSize="5px" />,
    link: "/testimonials",
  },
  {
    name: "Reviews",
    icon: <ReviewsIcon fontSize="5px" />,
    link: "/reviews",
  },
  {
    name: "Social Media",
    icon: <ShareIcon fontSize="5px" />,
    link: "/social-media",
  },
  {
    name: "General Information",
    icon: <InfoIcon fontSize="5px" />,
    link: "/general-information",
  },
  {
    name: "Amenities",
    icon: <RoomServiceIcon fontSize="5px" />,
    link: "/amenities",
  },
  {
    name: "Type Of Events",
    icon: <EventIcon fontSize="5px" />,
    link: "/type-of-events",
  },
  {
    name: "Cities",
    icon: <LocationCityIcon fontSize="5px" />,
    link: "/cities",
  },
  {
    name: "Style",
    icon: <StyleIcon fontSize="5px" />,
    link: "/styles",
  },
  {
    name: "Unit Meeting Room Capacities",
    icon: <MeetingRoomIcon />,
    link: "/venue-meeting-room-capacities",
  },
  // {
  //   name: "Units Photo Gallery",
  //   icon: <ImageIcon />,
  //   link: "/venue-units-photo-gallery",
  // },
  {
    name: "Unit Exhibits",
    icon: <FormatListBulletedIcon />,
    link: "/venue-exhibits",
  },
  {
    name: "Units Amenities Section",
    icon: <RoomServiceIcon />,
    link: "/venue-units-amenities-section",
  },
  {
    name: "Units Amenities List",
    icon: <FormatListBulletedIcon />,
    link: "/venue-units-amenities-list",
  },
  {
    name: "Units Type",
    icon: <MergeTypeIcon />,
    link: "/unit-types",
  },
];

const settings = ["My account", "Users", "Logout"];

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  marginLeft: 10,
}));

export default function PersistentDrawerLeft({ open, setOpen }) {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [active, setActive] = useState("Dashboard");
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);

  const handleItemClick = (name) => {
    setActive(name);
  };

  const onLogoutClick = () => {
    handleLogout(navigate);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMouseEnter = (pageName) => {
    setActive(pageName);
  };

  const handleMouseLeave = () => {
    setActive(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar color="primary" position="fixed" open={open}>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="center-header-container">
            <div className="montenegro">MONTENEGRO</div>
            <div className="mice-container">
              <span className="mice-span">M</span>{" "}
              <div
                className="logo-circle"
                style={{ backgroundColor: "#ffd600" }}
              ></div>
              <span className="mice-span">I</span>{" "}
              <div
                className="logo-circle"
                style={{ backgroundColor: "#e0006c" }}
              ></div>
              <span className="mice-span">C</span>{" "}
              <div
                className="logo-circle"
                style={{ backgroundColor: "white" }}
              ></div>
              <span className="mice-span">E</span>
            </div>
          </div>

          <List
            style={{
              width: "70%",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {pages.map((page) => (
              <ListItem key={page.name} disablePadding>
                <Box
                  onMouseEnter={() => handleMouseEnter(page.name)}
                  onMouseLeave={handleMouseLeave}
                  style={{ width: "100%" }}
                >
                  {page.name === "Venues" ? (
                    <div
                      onMouseEnter={() => setSubMenuOpen(true)}
                      onMouseLeave={() => setSubMenuOpen(false)}
                      style={{
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <Link
                        to={page.link}
                        onClick={() => handleItemClick(page.name)}
                        style={{
                          textDecoration: "none",
                          color: active === page.name ? "#e2e2e2" : "#5A5A5A",
                          backgroundColor:
                            active === page.name ? "#e2e2e2" : "#49559d",
                          width: "100%",
                        }}
                      >
                        <ListItemButton>
                          <ListItemIcon
                            style={{
                              color:
                                active === page.name ? "#e2e2e2" : "#e2e2e2",
                            }}
                          >
                            {page.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={page.name}
                            style={{
                              color:
                                active === page.name ? "#e2e2e2" : "#e2e2e2",
                              marginLeft: "-10px",
                            }}
                          />
                        </ListItemButton>
                      </Link>
                      <List
                        style={{
                          width: "120%",
                          height: "500px",
                          position: "absolute",
                          top: "45px",
                          left: "-10%",
                          backgroundColor: "#49559d",
                          overflowY: "auto",
                          boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.2)",
                          scrollbarWidth: "thin",
                          scrollbarColor: "white #49559d",
                          borderRadius: "5px",
                          paddingTop: "10px",
                          display: isSubMenuOpen ? "block" : "none",
                        }}
                      >
                        {subPages.map((subPage) => (
                          <ListItem key={subPage.name} disablePadding>
                            <Link
                              to={subPage.link}
                              style={{
                                textDecoration: "none",
                                color: "#e2e2e2",
                                borderBottom: "2px solid white",
                                width: "100%",
                              }}
                            >
                              <ListItemButton>
                                <ListItemIcon style={{ color: "#e2e2e2" }}>
                                  {subPage.icon}
                                </ListItemIcon>
                                <ListItemText
                                  primary={subPage.name}
                                  style={{
                                    color: "#e2e2e2",
                                    marginLeft: "-30px",
                                    fontSize: "1px",
                                  }}
                                />
                              </ListItemButton>
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  ) : (
                    <Link
                      to={page.link}
                      onClick={() => handleItemClick(page.name)}
                      style={{
                        textDecoration: "none",
                        color: active === page.name ? "#e2e2e2" : "#5A5A5A",
                        backgroundColor:
                          active === page.name ? "#e2e2e2" : "#49559d",
                        width: "100%",
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon
                          style={{
                            color: active === page.name ? "#e2e2e2" : "#e2e2e2",
                          }}
                        >
                          {page.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={page.name}
                          style={{
                            color: active === page.name ? "#e2e2e2" : "#e2e2e2",
                            marginLeft: "-10px",
                          }}
                        />
                      </ListItemButton>
                    </Link>
                  )}
                </Box>
              </ListItem>
            ))}
          </List>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
              <Button
                onClick={onLogoutClick}
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ mt: 2 }}
              >
                Logout
              </Button>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Main open={open}>
        <DrawerHeader />
      </Main>
    </Box>
  );
}
