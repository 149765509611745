import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchReviews = () => {
  return axios
    .get(`${API_URL}/review`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteReview = (reviewId) => {
  return axios
    .delete(`${API_URL}/review/${reviewId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju ${error.message}`);
    });
};

export const getReview = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/review/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju ${error.message}`);
  }
};

export const editReview = async (id, reviewData) => {
  try {
    const response = await axios.put(`${API_URL}/review/${id}`, reviewData);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju ${error.message}`);
  }
};

export const addReview = (reviewData) => {
  return axios
    .post(`${API_URL}/review`, reviewData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju ${error.message}`);
    });
};
