import React from "react";
import {
  Button,
  Container,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import Modal from "react-modal";

const UnitModal = ({
  isFormOpenUnit,
  closeFormModal,
  handleUnitTabChange,
  selectedTabUnit,
  handleUnitSubmit,
  venueUnitData,
  handleUnitInputChange,
  handleSelectMeetingRooms,
  meetingRooms,
  handleSelectExhibits,
  exhibits,
  handleSelectAmenitiesList,
  amenitiesList,
  handleUnitType,
  unitTypes,
  toggleImageForm,
}) => {
  return (
    <Modal
      isOpen={isFormOpenUnit}
      onRequestClose={closeFormModal}
      contentLabel="Add New Unit Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(73, 85, 157, 0.5)",
        },
        content: {
          backgroundColor: "white",
          top: "100px",
          left: "29%",
          right: "5%",
          bottom: "5%",
          zIndex: "10",
        },
      }}
    >
      <div>
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Venue Unit
        </Typography>
        <Container
          sx={{
            marginTop: "10px",
            marginBottom: "15px",
            display: "flex",
            justifyContent: "space-around",
            paddingBottom: "15px",
            paddingTop: "15px",
            borderTop: "2px solid #49559d",
            borderBottom: "2px solid #49559d",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => handleUnitTabChange("general")}
            sx={{
              backgroundColor:
                selectedTabUnit === "general" ? "#49559d" : "initial",
              color: selectedTabUnit === "general" ? "white" : "#49559d",
              "&:hover": {
                color: "#49559d",
              },
            }}
          >
            General
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleUnitTabChange("seo")}
            sx={{
              backgroundColor:
                selectedTabUnit === "seo" ? "#49559d" : "initial",
              color: selectedTabUnit === "seo" ? "white" : "#49559d",
              "&:hover": {
                color: "#49559d",
              },
            }}
          >
            SEO
          </Button>
        </Container>
        <form onSubmit={handleUnitSubmit}>
          {selectedTabUnit === "general" && (
            <>
              <Container
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    width: "45%",
                  }}
                >
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="name"
                    label="Name"
                    required
                    value={venueUnitData.name}
                    onChange={handleUnitInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="slug"
                    label="Slug"
                    required
                    value={venueUnitData.slug}
                    onChange={handleUnitInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="description"
                    label="Description"
                    multiline
                    rows={6}
                    value={venueUnitData.description}
                    onChange={handleUnitInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="size"
                    label="Size"
                    value={venueUnitData.size}
                    type="number"
                    onChange={handleUnitInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="total_number_of_people"
                    label="Total Number Of People"
                    value={venueUnitData.total_number_of_people}
                    type="number"
                    onChange={handleUnitInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="ceiling_height"
                    label="Ceiling Height"
                    value={venueUnitData.ceiling_height}
                    type="number"
                    onChange={handleUnitInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="dimensions"
                    label="Dimensions"
                    value={venueUnitData.dimensions}
                    onChange={handleUnitInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="floor_plan_image"
                    label="Floor Plan Image"
                    value={venueUnitData.floor_plan_image}
                    onChange={handleUnitInputChange}
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "45%",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <FormControl sx={{ marginTop: "30px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Meeting Room Capacities
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        value={venueUnitData.meeting_room}
                        label="VenueTypes"
                        onChange={handleSelectMeetingRooms}
                      >
                        {meetingRooms?.map((list) => (
                          <MenuItem key={list.id} value={list.id}>
                            {list.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ marginTop: "30px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Exhibits
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        value={venueUnitData.exhibits}
                        label="VenueTypes"
                        onChange={handleSelectExhibits}
                      >
                        {exhibits?.map((list) => (
                          <MenuItem key={list.id} value={list.id}>
                            {list.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ marginTop: "30px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Amenity List
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        value={venueUnitData.amenities_lists}
                        label="VenueTypes"
                        onChange={handleSelectAmenitiesList}
                      >
                        {amenitiesList?.map((list) => (
                          <MenuItem key={list.id} value={list.id}>
                            {list.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ marginTop: "30px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Unit Types
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        value={venueUnitData.unit_types}
                        label="VenueTypes"
                        onChange={handleUnitType}
                      >
                        {unitTypes?.map((list) => (
                          <MenuItem key={list.id} value={list.id}>
                            {list.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "30px",
                      backgroundColor: "#f0f0f0",
                      boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.3)",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{ marginBottom: "20px", margin: "20px auto" }}
                      onClick={() =>
                        toggleImageForm(venueUnitData.id, "featuredImageUnit")
                      }
                    >
                      Add Featured Image
                    </Button>
                    <TextField
                      sx={{ marginBottom: "20px" }}
                      name="featured_image"
                      label={"URL"}
                      value={venueUnitData.featured_image}
                      onChange={handleUnitInputChange}
                    />
                    <img
                      src={venueUnitData.featured_image}
                      alt=""
                      style={{ borderRadius: "10px" }}
                    />
                  </Box>
                </Box>
              </Container>
            </>
          )}

          {selectedTabUnit === "seo" && (
            <>
              <Container
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <TextField
                  sx={{ marginTop: "30px", width: "45%" }}
                  name="meta_keyword"
                  label="Meta Keyword"
                  value={venueUnitData.meta_keyword}
                  onChange={handleUnitInputChange}
                />
                <TextField
                  sx={{ marginTop: "30px", width: "45%" }}
                  name="meta_title"
                  label="Meta Title"
                  value={venueUnitData.meta_title}
                  onChange={handleUnitInputChange}
                />
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="meta_description"
                  label="Meta Description"
                  multiline
                  rows={3}
                  value={venueUnitData.meta_description}
                  fullWidth
                  onChange={handleUnitInputChange}
                />
              </Container>
            </>
          )}
          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              Publish
            </Button>
          </Container>
        </form>
      </div>
      <Button variant="outlined" onClick={closeFormModal}>
        Close
      </Button>
    </Modal>
  );
};

export default UnitModal;
