import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addCategory,
  getCategory,
  editCategory,
} from "../../../services/venues/venueCategoryAPI";
import ButtonBack from "../../../components/ButtonBack";

const AddEditCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode = location === "add-new-venue-category" ? false : true;

  const [categoryData, setCategoryData] = useState({
    name: "",
    description: "",
    meta_title: "",
    meta_description: "",
    slug: "",
  });

  useEffect(() => {
    if (id) {
      getCategory(id)
        .then((data) => {
          setCategoryData({
            name: data.name,
            description: data.description,
            meta_title: data.meta_title,
            meta_description: data.meta_description,
            slug: data.slug,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCategoryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isEditMode) {
      editCategory(id, categoryData)
        .then(() => {
          navigate("/venue-category");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addCategory(categoryData)
        .then(() => {
          navigate("/venue-category");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Venue Category
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="name"
                    label="Name"
                    required
                    value={categoryData.name}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="description"
                    label="Description"
                    multiline
                    rows={6}
                    value={categoryData.description}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="meta_title"
                    label="Meta Title"
                    required
                    value={categoryData.meta_title}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="meta_description"
                    label="Meta Description"
                    multiline
                    rows={6}
                    value={categoryData.meta_description}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="slug"
                    label="Slug"
                    value={categoryData.slug}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
              </Container>

              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-venue-category" ? "Publish" : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditCategory;
