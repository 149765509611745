import axios from "axios";
import { API_URL } from "./venues/apiUrl";

// export const API_URL = "https://montenegromice.com/blog-api";

export const fetchBlogs = () => {
  return axios
    .get(`${API_URL}/getAllBlogs`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteBlog = (blogId) => {
  return axios
    .delete(`${API_URL}/deleteBlog/${blogId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju bloga: ${error.message}`);
    });
};

export const addBlog = (blogData) => {
  return axios
    .post(`${API_URL}/storeBlog`, blogData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju bloga: ${error.message}`);
    });
};

export const getBlog = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/showBlog/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju bloga: ${error.message}`);
  }
};

export const editBlog = async (id, blogData) => {
  try {
    const response = await axios.put(`${API_URL}/updateBlog/${id}`, blogData);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju bloga: ${error.message}`);
  }
};
