import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addDocumentation,
  getDocumentation,
  editDocumentation,
} from "../../../services/venues/venueDocumentation";
import ButtonBack from "../../../components/ButtonBack";
import IconModal from "../../../components/Venue/Modals/IconModal";

const AddEditDocumentation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  const isEditMode = location === "add-new-documentation" ? false : true;

  const [documentationData, setDocumentationData] = useState({
    icon: "",
    iconAltText: "",
    title: "",
    documentLink: "",
  });

  useEffect(() => {
    if (id) {
      getDocumentation(id)
        .then((data) => {
          setDocumentationData({
            icon: data.icon,
            iconAltText: data.icon_alt_text,
            title: data.title,
            documentLink: data.document_link,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDocumentationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newDocumentData = {
      icon: documentationData.icon,
      icon_alt_text: documentationData.iconAltText,
      title: documentationData.title,
      document_link: documentationData.documentLink,
    };
    if (isEditMode) {
      editDocumentation(id, newDocumentData)
        .then((response) => {
          setDocumentationData({
            icon: "",
            iconAltText: "",
            title: "",
            documentLink: "",
          });

          navigate("/documentation");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju dokumenta:", error);
        });
    } else {
      addDocumentation(newDocumentData)
        .then((response) => {
          setDocumentationData({
            ...documentationData,
            icon: response.icon,
            iconAltText: response.icon_alt_text,
            title: response.title,
            documentLink: response.document_link,
          });
          navigate("/documentation");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju dokumenta:", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Documentation
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <IconModal
                  setData={setDocumentationData}
                  data={documentationData.icon}
                  handleInputChange={handleInputChange}
                  setImageModalOpen={setImageModalOpen}
                  isImageModalOpen={isImageModalOpen}
                />
                <Container style={{ marginTop: "30px" }}>
                  <TextField
                    name="iconAltText"
                    label={isImageModalOpen ? "" : "Icon Alt Text"}
                    value={documentationData.iconAltText}
                    fullWidth
                    onChange={handleInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="title"
                    label={isImageModalOpen ? "" : "Title"}
                    required
                    value={documentationData.title}
                    fullWidth
                    onChange={handleInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="documentLink"
                    label={isImageModalOpen ? "" : "Document Link"}
                    required
                    value={documentationData.documentLink}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </Container>
              </Container>

              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-documentation" ? "Publish" : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditDocumentation;
