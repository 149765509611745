import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import Layout from "../../../layout/Layout";
import { Link } from "react-router-dom";

import TableChartIcon from "@mui/icons-material/TableChart";
import Pagination from "@mui/material/Pagination";
import {
  deleteTestimonial,
  fetchTestimonials,
} from "../../../services/coworking/TestimonialAPI";

export default function CoworkingTestimonials() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("name");
  const [isTabularView, setIsTabularView] = useState(false);
  const [data, setData] = useState([]);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const itemsPerPage = 6;

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleDelete = () => {
    if (dataToDelete) {
      deleteTestimonial(dataToDelete)
        .then(() => {
          setDeleteDialogOpen(false);
          setData((prevData) =>
            prevData.filter((data) => data.id !== dataToDelete)
          );
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
    setDeleteDialogOpen(false); // Close the dialog after delete
  };

  const handleDeleteButtonClicked = (id) => {
    setDeleteDialogOpen(true);
    setDataToDelete(id);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleTabularViewToggle = () => {
    setIsTabularView(!isTabularView);
  };

  const filteredData = data.filter((item) => {
    if (searchValue === "") return true;
    if (searchBy === "name") {
      return item.name.toLowerCase().includes(searchValue.toLowerCase());
    }
    return true;
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  useEffect(() => {
    fetchTestimonials().then((data) => {
      setData(data);
    });
  }, []);

  return (
    <Layout>
      <div>
        <div>
          <TextField
            value={searchValue}
            onChange={handleSearchInputChange}
            label="Search"
            variant="outlined"
            fullWidth
          />

          <select value={searchBy} onChange={handleSearchByChange}>
            <option value="title">Title</option>
            <option value="category">Category</option>
          </select>
        </div>

        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Coworking Testimonials
        </Typography>

        <div>
          <Link
            to={{
              pathname: "/add-new-coworking-testimonials",
              state: { isEditMode: false },
            }}
          >
            <Button variant="outlined">Add New</Button>
          </Link>

          <Button
            startIcon={<TableChartIcon />}
            onClick={handleTabularViewToggle}
          ></Button>
        </div>

        {filteredData.length === 0 ? (
          <Typography
            variant="h6"
            style={{
              marginTop: "20px",
              color: "red",
              textAlign: "center",
            }}
          >
            There are no results
          </Typography>
        ) : (
          ""
        )}

        {!isTabularView ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Stars</TableCell>
                  <TableCell>Review Text</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.reverse().map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.document_link}</TableCell>
                    <TableCell>{item.stars}</TableCell>
                    <TableCell>{item.reviews_text}</TableCell>
                    <TableCell>
                      <img src={item.image} />
                    </TableCell>
                    <TableCell>{item.position}</TableCell>
                    <TableCell>
                      <div>
                        <Link
                          to={{
                            pathname: `/edit-coworking-testimonials/${item.id}`,
                            state: { isEditMode: true },
                          }}
                        >
                          <Button variant="outlined">Edit Item</Button>
                        </Link>
                        <Button
                          variant="outlined"
                          onClick={() => handleDeleteButtonClicked(item.id)}
                        >
                          Delete
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid container spacing={2}>
            {paginatedData.reverse().map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {item.name}
                    </Typography>
                    <Typography color="textSecondary">
                      {item.description}
                    </Typography>
                    <Typography color="textSecondary">
                      Category: {item.category}
                    </Typography>
                    <Link
                      to={{
                        pathname: `/edit-coworking-testimonials/${item.id}`,
                        state: { isEditMode: true },
                      }}
                    >
                      <Button variant="outlined">Edit item</Button>
                    </Link>
                    <Button
                      variant="outlined"
                      onClick={() => handleDeleteButtonClicked(item.id)}
                    >
                      Delete
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        <Pagination
          page={currentPage}
          onChange={handlePageChange}
          style={{ marginTop: "1rem", float: "right" }}
          count={Math.ceil(filteredData.length / itemsPerPage)}
        />

        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Delete Confirmation</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this blog?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              onClick={handleDelete}
              color="error"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Layout>
  );
}
