import React from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
} from "@mui/material";

const MoreInfo = ({
  venueData,
  handleInputChange,
  handleDocumentationsList,
  documentationsList,
  handleTestimonialsList,
  testimonialsList,
  handleReviewsList,
  reviewsList,
  handleVenueRight,
  venue,
  handleVenueLeft,
  handleSocialMedia,
  socialMedia,
}) => {
  return (
    <>
      <Container
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="altitude"
          label="Altitude"
          value={venueData.altitude}
          onChange={handleInputChange}
        />
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="longitude"
          label="Longitude"
          value={venueData.longitude}
          onChange={handleInputChange}
        />

        <FormControl sx={{ marginTop: "30px", width: "45%" }}>
          <InputLabel id="demo-simple-select-label">Documentation</InputLabel>
          <Select
            name="documentations"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            multiple
            value={venueData.documentations}
            label="VenueTypes"
            onChange={handleDocumentationsList}
          >
            {documentationsList?.map((list) => (
              <MenuItem key={list.id} value={list.id}>
                {list.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ marginTop: "30px", width: "45%" }}>
          <InputLabel id="demo-simple-select-label">Testimonials</InputLabel>
          <Select
            name="testimonials"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            multiple
            value={venueData.testimonials}
            label="VenueTypes"
            onChange={handleTestimonialsList}
          >
            {testimonialsList?.map((list) => (
              <MenuItem key={list.id} value={list.id}>
                {list.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ marginTop: "30px", width: "45%" }}>
          <InputLabel id="demo-simple-select-label">Left Venue</InputLabel>
          <Select
            name="compare_left_venue_id"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={venueData.compare_left_venue_id}
            label="VenueTypes"
            onChange={handleVenueLeft}
          >
            {venue?.map((list) => (
              <MenuItem key={list.id} value={list.id}>
                {list.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ marginTop: "30px", width: "45%" }}>
          <InputLabel id="demo-simple-select-label">Right Venue</InputLabel>
          <Select
            name="compare_right_venue_id"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={venueData.compare_right_venue_id}
            label="VenueTypes"
            onChange={handleVenueRight}
          >
            {venue?.map((list) => (
              <MenuItem key={list.id} value={list.id}>
                {list.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ marginTop: "30px", width: "45%" }}>
          <InputLabel id="demo-simple-select-label">Reviews</InputLabel>
          <Select
            name="reviews"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            multiple
            value={venueData.reviews}
            label="VenueTypes"
            onChange={handleReviewsList}
          >
            {reviewsList?.map((list) => (
              <MenuItem key={list.id} value={list.id}>
                {list.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ marginTop: "30px", width: "45%" }}>
          <InputLabel id="demo-simple-select-label">
            Social Media Links
          </InputLabel>
          <Select
            name="social_media"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            multiple
            value={venueData.social_media}
            label="VenueTypes"
            onChange={handleSocialMedia}
          >
            {socialMedia?.map((list) => (
              <MenuItem key={list.id} value={list.id}>
                {list.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Container>
    </>
  );
};

export default MoreInfo;
