import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import Layout from "../../layout/Layout";
import { Link } from "react-router-dom";

import TableChartIcon from "@mui/icons-material/TableChart";
import Pagination from "@mui/material/Pagination";
import { fetchBlogs, deleteBlog } from "../../services/blogAPI";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { CategoryOutlined } from "@mui/icons-material";

const pages = [
  { name: "Categories", icon: <CategoryOutlined />, link: "/categories" },
];

export default function Blog({ blog }) {
  const [blogs, setBlogs] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [blogIdToDelete, setBlogIdToDelete] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("name");
  const [isTabularView, setIsTabularView] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [active, setActive] = useState("");
  const itemsPerPage = 6;

  useEffect(() => {
    fetchBlogs()
      .then((data) => {
        setBlogs(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDelete = () => {
    if (blogIdToDelete) {
      deleteBlog(blogIdToDelete)
        .then(() => {
          setDeleteDialogOpen(false);
          setBlogs((prevBlogs) =>
            prevBlogs.filter((blog) => blog.id !== blogIdToDelete)
          );
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };

  const handleDeleteButtonClicked = (blogId) => {
    setDeleteDialogOpen(true);
    setBlogIdToDelete(blogId);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleTabularViewToggle = () => {
    setIsTabularView(!isTabularView);
  };
  const handleItemClick = (name) => {
    setActive(name);
  };

  const filteredData = blogs.filter((item) => {
    if (searchBy === "name") {
      return item.title_me.toLowerCase().includes(searchValue.toLowerCase());
    }
    return true;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginatedData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "20%" }}>
          <Typography variant="h5" gutterBottom sx={{ textAlign: "center" }}>
            Blog Menu
          </Typography>
          <div style={{ height: "75vh", overflowY: "auto" }}>
            <List>
              {pages.map((page) => (
                <ListItem key={page.name} disablePadding>
                  <Box style={{ width: "100%" }}>
                    <Link
                      to={page.link}
                      onClick={() => handleItemClick(page.name)}
                      style={{
                        textDecoration: "none",
                        color: active === page.name ? "#49559d" : "#5A5A5A",
                        backgroundColor:
                          active === page.name ? "#49559d" : "#e2e2e2",
                        width: "100%",
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon
                          style={{
                            color: active === page.name ? "#49559d" : "#49559d",
                          }}
                        >
                          {page.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={page.name}
                          style={{
                            color: active === page.name ? "#49559d" : "#49559d",
                          }}
                        />
                      </ListItemButton>
                    </Link>
                  </Box>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
        <div style={{ width: "79%" }}>
          <div>
            <div>
              <TextField
                value={searchValue}
                onChange={handleSearchInputChange}
                label="Search"
                variant="outlined"
                fullWidth
              />

              <select value={searchBy} onChange={handleSearchByChange}>
                <option value="name">Name</option>
                <option value="category">Category</option>
              </select>
            </div>

            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Blog
            </Typography>

            <div>
              <Link
                to={{ pathname: "/add-new-blog", state: { isEditMode: false } }}
              >
                <Button variant="outlined">Add New</Button>
              </Link>

              <Button
                startIcon={<TableChartIcon />}
                onClick={handleTabularViewToggle}
              ></Button>
            </div>

            {isTabularView ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  {blogs.reverse().map((blog, blogIndex) => (
                    <TableBody key={blog}>
                      {paginatedData.map((blog, index) => (
                        <TableRow key={index}>
                          {blogIndex === 0 && (
                            <>
                              <TableCell>{blog.title_me}</TableCell>
                              <TableCell>{blog.short_description_me}</TableCell>
                              <TableCell>Category:</TableCell>
                              <TableCell>
                                <div>
                                  <Link
                                    to={{
                                      pathname: `/edit-blog/${blog.id}`,
                                      state: { isEditMode: true },
                                    }}
                                  >
                                    <Button variant="outlined">
                                      Edit Blog
                                    </Button>
                                  </Link>
                                  <Button
                                    variant="outlined"
                                    onClick={() =>
                                      handleDeleteButtonClicked(blog.id)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
            ) : (
              blogs.map((blog, blogIndex) => (
                <Grid container spacing={2} key={blog.id}>
                  {paginatedData.map((blog, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      {blogIndex === 0 && (
                        <Card>
                          <CardContent>
                            <Typography variant="h5" component="h2">
                              {blog.title_me}
                            </Typography>
                            <Typography color="textSecondary">
                              {blog.short_description_me}
                            </Typography>
                            <Typography color="textSecondary">
                              Category:
                            </Typography>
                            <Link
                              to={{
                                pathname: `/edit-blog/${blog.id}`,
                                state: { isEditMode: true },
                              }}
                            >
                              <Button variant="outlined">Edit Blog</Button>
                            </Link>
                            <Button
                              variant="outlined"
                              onClick={() => handleDeleteButtonClicked(blog.id)}
                            >
                              Delete
                            </Button>
                          </CardContent>
                        </Card>
                      )}
                    </Grid>
                  ))}
                </Grid>
              ))
            )}

            <Pagination
              count={Math.ceil(filteredData.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              style={{ marginTop: "1rem", float: "right" }}
            />

            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
              <DialogTitle>Delete Confirmation</DialogTitle>
              <DialogContent>
                <Typography>
                  Are you sure you want to delete this blog?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeleteDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  open={deleteDialogOpen}
                  onClose={() => setDeleteDialogOpen(false)}
                  onClick={handleDelete}
                  color="error"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </Layout>
  );
}
