import React from "react";
import { Button, Container, Typography, TextField } from "@mui/material";
import Modal from "react-modal";

const GuestRoomModal = ({
  isFormOpenGuestRoom,
  closeFormModalGuestRoom,
  handleGuestRoomSubmit,
  typeOfGuestRoomData,
  handleInputChangeGuestRoom,
}) => {
  return (
    <Modal
      isOpen={isFormOpenGuestRoom}
      onRequestClose={closeFormModalGuestRoom}
      contentLabel="Add New Unit Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(73, 85, 157, 0.5)",
        },
        content: {
          backgroundColor: "white",
          top: "20%",
          left: "29%",
          right: "5%",
          bottom: "15%",
          zIndex: "10",
        },
      }}
    >
      <div>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: "center", marginTop: "30px" }}
        >
          Guest Room
        </Typography>
        <form onSubmit={handleGuestRoomSubmit}>
          <Container
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <TextField
              sx={{ marginTop: "30px", width: "45%" }}
              name="title"
              label="Title"
              required
              value={typeOfGuestRoomData.title}
              onChange={handleInputChangeGuestRoom}
            />
            <TextField
              sx={{ marginTop: "30px", width: "45%" }}
              name="icon"
              label="Icon"
              value={typeOfGuestRoomData.icon}
              onChange={handleInputChangeGuestRoom}
            />
            <TextField
              sx={{ marginTop: "30px", width: "45%" }}
              name="icon_alt_text"
              label="Icon Alt Text"
              value={typeOfGuestRoomData.icon_alt_text}
              onChange={handleInputChangeGuestRoom}
            />
            <TextField
              sx={{ marginTop: "30px", width: "45%" }}
              name="amount"
              label="Amount"
              required
              value={typeOfGuestRoomData.amount}
              type="number"
              onChange={handleInputChangeGuestRoom}
            />
          </Container>
          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              Publish
            </Button>
          </Container>
        </form>
      </div>
      <Button variant="outlined" onClick={closeFormModalGuestRoom}>
        Close
      </Button>
    </Modal>
  );
};

export default GuestRoomModal;
