import axios from 'axios';

export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchVideoGalleries = () => {
  return axios.get(`${API_URL}/coworking/video-gallery`)
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.message);
    });
};

export const deleteVideoGallery = (videoGalleryId) => {
    return axios.delete(`${API_URL}/coworking/video-gallery/${videoGalleryId}`)
      .then(response => response.data)
      .catch(error => {
        throw new Error(`Greška pri brisanju: ${error.message}`);
      });
  };

  export const getVideoGallery = async (id) => {
    try {
      const response = await axios.get(`${API_URL}/coworking/video-gallery/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Greška pri dobavljanju: ${error.message}`);
    }
  };

  export const editVideoGallery = async (id, videoGalleryData) => {
    try {
      const response = await axios.put(`${API_URL}/coworking/video-gallery/${id}`, videoGalleryData);
      return response.data;
    } catch (error) {
      throw new Error(`Greška pri editovanju: ${error.message}`);
    }
  };

  export const addVideoGallery = (videoGalleryData) => {
    return axios.post(`${API_URL}/coworking/video-gallery`, videoGalleryData)
      .then(response => response.data)
      .catch(error => {
        throw new Error(`Greška pri dodavanju: ${error.message}`);
      });
  };
