import axios from "axios";
import { API_URL } from "./venues/apiUrl";

export const fetchStyles = () => {
  return axios
    .get(`${API_URL}/styles`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteStyle = (stylesId) => {
  return axios
    .delete(`${API_URL}/styles/${stylesId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju ${error.message}`);
    });
};

export const getStyle = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/styles/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju ${error.message}`);
  }
};

export const editStyle = async (id, stylesData) => {
  try {
    const response = await axios.put(`${API_URL}/styles/${id}`, stylesData);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju ${error.message}`);
  }
};

export const addStyle = (stylesData) => {
  return axios
    .post(`${API_URL}/styles`, stylesData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju ${error.message}`);
    });
};
