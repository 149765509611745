import axios from "axios";
import { API_URL } from "./const";

export const fetchCoworkings = async () => {
  return axios
    .get(`${API_URL}/coworking/coworking`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteCoworking = async (coworkingsId) => {
  return axios
    .delete(`${API_URL}/coworking/coworking/${coworkingsId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const addCoworking = async (coworkingData) => {
  return axios
    .post(`${API_URL}/coworking/coworking`, coworkingData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getCoworking = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/coworking/coworking/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editCoworking = async (id, coworkingData) => {
  try {
    const response = await axios.put(`${API_URL}/coworking/coworking/${id}`, coworkingData);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
