import React, { useState, useEffect } from "react";
import Layout from "../layout/Layout";
import FileUpload from "../components/FileUploader";
import GalleryMenu from "../components/GalleryMenu";
import {
  fetchVenuePhotoGalleries,
  getGalleryImages,
  //   removeImageFromGallery,
} from "../services/venues/venuePhotoGalleryAPI";
import { fetchFiles } from "../services/fileAPI";
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  //   IconButton,
  //   Button,
  //   Dialog,
  //   DialogActions,
  //   DialogContent,
  //   DialogTitle,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";

const GalleryVenue = () => {
  const [galleries, setGalleries] = useState([]);
  const [filteredGalleries, setFilteredGalleries] = useState([]);
  const [galleryImages, setGalleryImages] = useState({});
  const [loadingImages, setLoadingImages] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [files, setFiles] = useState([]);
  //   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  //   const [selectedImage, setSelectedImage] = useState(null);
  //   const [selectedGallery, setSelectedGallery] = useState(null);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = galleries.filter((gallery) =>
      gallery.name.toLowerCase().includes(term)
    );
    setFilteredGalleries(filtered);
  };

  useEffect(() => {
    fetchFiles()
      .then((allFiles) => {
        setFiles(allFiles);
      })
      .catch((error) => {
        setError("Error fetching files.");
        console.error("Error fetching files:", error);
      });
  }, []);

  useEffect(() => {
    if (files.length > 0) {
      fetchVenuePhotoGalleries()
        .then((galleries) => {
          setGalleries(galleries);
          setFilteredGalleries(galleries);

          galleries.forEach((gallery) => {
            setLoadingImages((prevState) => ({
              ...prevState,
              [gallery.id]: true,
            }));

            getGalleryImages(gallery.id)
              .then((data) => {
                const imageIds = data.images.map((image) => {
                  const file = files.find((file) => file.id === image.id);
                  return {
                    id: image.id,
                    path: file ? file.path : null,
                  };
                });

                setGalleryImages((prevState) => ({
                  ...prevState,
                  [gallery.id]: imageIds,
                }));

                setLoadingImages((prevState) => ({
                  ...prevState,
                  [gallery.id]: false,
                }));
              })
              .catch((error) => {
                setLoadingImages((prevState) => ({
                  ...prevState,
                  [gallery.id]: false,
                }));
                setError(`Error fetching images for gallery ${gallery.id}`);
                console.error(
                  `Error fetching images for gallery ${gallery.id}:`,
                  error
                );
              });
          });
        })
        .catch((error) => {
          setError("Error fetching galleries.");
          console.error("Error fetching galleries:", error);
        });
    }
  }, [files]);

  //   const handleEditImage = (imageId) => {
  //     console.log(`Edit image with id ${imageId}`);
  //     // Add edit image logic here
  //   };

  //   const handleDeleteImage = (galleryId, imageId) => {
  //     setSelectedGallery(galleryId);
  //     setSelectedImage(imageId);
  //     setOpenDeleteDialog(true);
  //     console.log(`Delete image with id ${galleryId} and gallery ${imageId}`);
  //   };

  //   const confirmDeleteImage = () => {
  //     if (selectedGallery && selectedImage) {
  //       removeImageFromGallery(selectedGallery, selectedImage)
  //         .then(() => {
  //           // Update the galleryImages state to remove the deleted image
  //           setGalleryImages((prevState) => {
  //             const updatedImages = { ...prevState };
  //             updatedImages[selectedGallery] = updatedImages[
  //               selectedGallery
  //             ].filter((image) => image.id !== selectedImage);
  //             return updatedImages;
  //           });
  //           setOpenDeleteDialog(false);
  //         })
  //         .catch((error) => {
  //           setError("Error deleting image.");
  //           console.error("Error deleting image:", error);
  //         });
  //     }
  //   };

  //   const cancelDeleteImage = () => {
  //     setOpenDeleteDialog(false);
  //   };

  return (
    <Layout>
      <GalleryMenu />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          width: "73%",
          marginLeft: "26%",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: "center", paddingBottom: "30px" }}
        >
          Venue Gallery
        </Typography>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <FileUpload />
          <TextField
            fullWidth
            label="Search Galleries"
            value={searchTerm}
            onChange={handleSearch}
            variant="outlined"
            sx={{ marginBottom: "20px" }}
          />
        </Box>
        {error && <Typography color="error">{error}</Typography>}
        {filteredGalleries.map((gallery) => (
          <Box
            key={gallery.id}
            sx={{
              padding: "20px",
              backgroundColor: "#fafafa",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              marginBottom: "40px",
            }}
          >
            <Typography
              variant="h5"
              sx={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              {gallery.name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                justifyContent: "left",
              }}
            >
              {loadingImages[gallery.id] ? (
                <CircularProgress />
              ) : galleryImages[gallery.id] &&
                galleryImages[gallery.id].length > 0 ? (
                galleryImages[gallery.id].map((image) => (
                  <Box
                    sx={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <Box
                      key={image.id}
                      sx={{
                        width: "100px",
                        height: "100px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundImage: `url(${image.path})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        borderRadius: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                    {/* <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "5px",
                        boxSizing: "border-box",
                      }}
                    >
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteImage(image.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleEditImage(image.id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box> */}
                  </Box>
                ))
              ) : (
                <Typography>No images in this gallery.</Typography>
              )}
            </Box>
          </Box>
        ))}
      </Box>
      {/* <Dialog
        open={openDeleteDialog}
        onClose={cancelDeleteImage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography id="alert-dialog-description">
            Are you sure you want to delete this image from the gallery?
          </Typography>
          {selectedImage && <Typography>Image ID: {selectedImage}</Typography>}
          {selectedGallery && (
            <Typography>Gallery ID: {selectedGallery}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDeleteImage} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteImage} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog> */}
    </Layout>
  );
};

export default GalleryVenue;
