import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchTypeOfEvents = () => {
  return axios
    .get(`${API_URL}/events-that-can-be-organised`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteTypeOfEvent = (typeOfEventId) => {
  return axios
    .delete(`${API_URL}/events-that-can-be-organised/${typeOfEventId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju ${error.message}`);
    });
};

export const getTypeOfEvent = async (id) => {
  try {
    const response = await axios.get(
      `${API_URL}/events-that-can-be-organised/${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju ${error.message}`);
  }
};

export const editTypeOfEvent = async (id, typeOfEventData) => {
  try {
    const response = await axios.put(
      `${API_URL}/events-that-can-be-organised/${id}`,
      typeOfEventData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju ${error.message}`);
  }
};

export const addTypeOfEvent = (typeOfEventData) => {
  return axios
    .post(`${API_URL}/events-that-can-be-organised`, typeOfEventData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju ${error.message}`);
    });
};
