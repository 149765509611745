import axios from "axios";
import { API_URL } from "./venues/apiUrl";

// export const API_URL = "https://montenegromice.com/blog-api";

export const fetchFiles = () => {
  return axios
    .get(`${API_URL}/getAllFiles`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteFile = (fileId) => {
  return axios
    .delete(`${API_URL}/deleteFile/${fileId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju ${error.message}`);
    });
};

export const addFile = (fileData) => {
  return axios
    .post(`${API_URL}/storeFile`, fileData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju ${error.message}`);
    });
};

export const addFiles = (fileData) => {
  return axios
    .post(`${API_URL}/storeFile`, fileData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju: ${error.message}`);
    });
};

export const getFile = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/showFile/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju ${error.message}`);
  }
};

export const editFile = async (id, fileData) => {
  try {
    const response = await axios.put(`${API_URL}/updateFile/${id}`, fileData);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju ${error.message}`);
  }
};
