import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
} from "@mui/material";
import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addVenueUnit,
  getVenueUnit,
  editVenueUnit,
} from "../../../services/venues/venueUnitAPI";
import { fetchExhibits } from "../../../services/venues/unitExhibitsAPI";
import { fetchUnitAmenitiesSections } from "../../../services/venues/unitAmenitasSectionAPI";
import { fetchMeetingRoomCapacities } from "../../../services/venues/unitMeetingRoomCapacitiesAPI";
import { fetchUnitPhotoGalleries } from "../../../services/venues/unitPhotoGalleryAPI";
import { fetchUnitTypes } from "../../../services/venues/unitType";
import { fetchCities } from "../../../services/cityAPI";
import ButtonBack from "../../../components/ButtonBack";

const AddEditVenueUnit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode = location === "add-new-venue-units" ? false : true;

  const [selectedTab, setSelectedTab] = useState("general");

  const [venueUnitData, setVenueUnitData] = useState({
    name: "",
    cities: [],
    total_number_of_people: "",
    description: "",
    size: "",
    ceiling_height: "",
    dimensions: "",
    featured_image: "",
    floor_plan_image: "",
    slug: "",
    meta_keyword: "",
    meta_title: "",
    meta_description: "",
    photo_gallery: [],
    meeting_room: [],
    exhibits: [],
    amenities_sections: [],
    unit_types: [],
  });
  const [photoGalleries, setPhotoGalleries] = useState([]);
  const [meetingRooms, setMeetingRooms] = useState([]);
  const [exhibits, setExhibits] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [citiesUnitList, setCitiesUnitList] = useState([]);

  useEffect(() => {
    fetchUnitTypes()
      .then((data) => {
        setUnitTypes(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchUnitPhotoGalleries()
      .then((data) => {
        setPhotoGalleries(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchMeetingRoomCapacities()
      .then((data) => {
        setMeetingRooms(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchExhibits()
      .then((data) => {
        setExhibits(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchUnitAmenitiesSections()
      .then((data) => {
        setAmenities(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchCities()
      .then((data) => {
        setCitiesUnitList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (id) {
      getVenueUnit(id)
        .then((data) => {
          setVenueUnitData({
            name: data.name,
            total_number_of_people: data.total_number_of_people,
            description: data.description,
            size: data.size,
            ceiling_height: data.ceiling_height,
            dimensions: data.dimensions,
            featured_image: data.featured_image,
            floor_plan_image: data.floor_plan_image,
            slug: data.slug,
            meta_keyword: data.meta_keyword,
            meta_title: data.meta_title,
            meta_description: data.meta_description,
            photo_gallery: data.photo_gallery
              ? data.photo_gallery?.map((list) => list.id)
              : [],
            meeting_room: data.meeting_room
              ? data.meeting_room?.map((list) => list.id)
              : [],
            exhibits: data.exhibits
              ? data.exhibits?.map((list) => list.id)
              : [],
            amenities_sections: data.amenities_sections
              ? data.amenities_sections?.map((list) => list.id)
              : [],
            unit_types: data.unit_types
              ? data.unit_types?.map((list) => list.id)
              : [],
            cities: data.cities ? data.cities?.map((list) => list.id) : [],
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setVenueUnitData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isEditMode) {
      editVenueUnit(id, venueUnitData)
        .then(() => {
          navigate("/venue-units");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju ", error);
        });
    } else {
      addVenueUnit(venueUnitData)
        .then(() => {
          navigate("/venue-units");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  const handleUnitType = (event) => {
    setVenueUnitData((prevData) => ({
      ...prevData,
      unit_types: event.target.value,
    }));
  };

  const handleSelectAmenities = (event) => {
    setVenueUnitData((prevData) => ({
      ...prevData,
      amenities_sections: event.target.value,
    }));
  };

  const handleSelectExhibits = (event) => {
    setVenueUnitData((prevData) => ({
      ...prevData,
      exhibits: event.target.value,
    }));
  };

  const handleSelectMeetingRooms = (event) => {
    setVenueUnitData((prevData) => ({
      ...prevData,
      meeting_room: event.target.value,
    }));
  };

  const handleSelectPhotoGalleries = (event) => {
    setVenueUnitData((prevData) => ({
      ...prevData,
      photo_gallery: event.target.value,
    }));
  };

  const handleSelectCity = (event) => {
    setVenueUnitData((prevData) => ({
      ...prevData,
      cities: event.target.value,
    }));
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Venue Unit
            </Typography>

            <Container
              sx={{
                marginTop: "10px",
                marginBottom: "15px",
                display: "flex",
                justifyContent: "space-around",
                paddingBottom: "15px",
                paddingTop: "15px",
                borderTop: "2px solid #49559d",
                borderBottom: "2px solid #49559d",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => handleTabChange("general")}
              >
                General Info
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleTabChange("meta")}
              >
                Meta Tags
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleTabChange("other")}
              >
                Other
              </Button>
            </Container>
            <form onSubmit={handleSubmit}>
              {selectedTab === "general" && (
                <>
                  <div>
                    <TextField
                      sx={{ marginTop: "30px" }}
                      name="name"
                      label="Name"
                      required
                      value={venueUnitData.name}
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </div>

                  <div>
                    <TextField
                      sx={{ marginTop: "30px" }}
                      name="total_number_of_people"
                      label="Total Number Of People"
                      value={venueUnitData.total_number_of_people}
                      type="number"
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </div>

                  <div>
                    <TextField
                      sx={{ marginTop: "30px" }}
                      name="description"
                      label="Description"
                      multiline
                      rows={6}
                      value={venueUnitData.description}
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </div>

                  <div>
                    <TextField
                      sx={{ marginTop: "30px" }}
                      name="size"
                      label="Size"
                      value={venueUnitData.size}
                      type="number"
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <TextField
                      sx={{ marginTop: "30px" }}
                      name="ceiling_height"
                      label="Ceiling Height"
                      value={venueUnitData.ceiling_height}
                      type="number"
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <TextField
                      sx={{ marginTop: "30px" }}
                      name="dimensions"
                      label="Dimensions"
                      value={venueUnitData.dimensions}
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <TextField
                      sx={{ marginTop: "30px" }}
                      name="featured_image"
                      label="Featured Image"
                      value={venueUnitData.featured_image}
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <TextField
                      sx={{ marginTop: "30px" }}
                      name="floor_plan_image"
                      label="Floor Plan Image"
                      value={venueUnitData.floor_plan_image}
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <TextField
                      sx={{ marginTop: "30px" }}
                      name="slug"
                      label="Slug"
                      required
                      value={venueUnitData.slug}
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </div>
                </>
              )}

              {selectedTab === "meta" && (
                <>
                  <div>
                    <TextField
                      sx={{ marginTop: "30px" }}
                      name="meta_keyword"
                      label="Meta Keyword"
                      value={venueUnitData.meta_keyword}
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <TextField
                      sx={{ marginTop: "30px" }}
                      name="meta_title"
                      label="Meta Title"
                      value={venueUnitData.meta_title}
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <TextField
                      sx={{ marginTop: "30px" }}
                      name="meta_description"
                      label="Meta Description"
                      multiline
                      rows={3}
                      value={venueUnitData.meta_description}
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </div>
                </>
              )}

              {selectedTab === "other" && (
                <>
                  <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                    <InputLabel id="demo-simple-select-label">
                      Gallery
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiple
                      value={venueUnitData.photo_gallery}
                      label="VenueTypes"
                      onChange={handleSelectPhotoGalleries}
                    >
                      {photoGalleries?.map((list) => (
                        <MenuItem key={list.id} value={list.id}>
                          <img
                            src={list.image}
                            alt={list.alt_text}
                            style={{ width: "100px", height: "100px" }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                    <InputLabel id="demo-simple-select-label">
                      Meeting Room Capacities
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiple
                      value={venueUnitData.meeting_room}
                      label="VenueTypes"
                      onChange={handleSelectMeetingRooms}
                    >
                      {meetingRooms?.map((list) => (
                        <MenuItem key={list.id} value={list.id}>
                          {list.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                    <InputLabel id="demo-simple-select-label">
                      Exhibits
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiple
                      value={venueUnitData.exhibits}
                      label="VenueTypes"
                      onChange={handleSelectExhibits}
                    >
                      {exhibits?.map((list) => (
                        <MenuItem key={list.id} value={list.id}>
                          {list.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                    <InputLabel id="demo-simple-select-label">
                      Amenities
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiple
                      value={venueUnitData.amenities_sections}
                      label="VenueTypes"
                      onChange={handleSelectAmenities}
                    >
                      {amenities?.map((list) => (
                        <MenuItem key={list.id} value={list.id}>
                          {list.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                    <InputLabel id="demo-simple-select-label">
                      Unit Types
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiple
                      value={venueUnitData.unit_types}
                      label="VenueTypes"
                      onChange={handleUnitType}
                    >
                      {unitTypes?.map((list) => (
                        <MenuItem key={list.id} value={list.id}>
                          {list.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                    <InputLabel id="demo-simple-select-label">
                      City List
                    </InputLabel>
                    <Select
                      name="cities"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiple
                      value={venueUnitData.cities}
                      label="VenueTypes"
                      onChange={handleSelectCity}
                    >
                      {citiesUnitList?.map((list) => (
                        <MenuItem key={list.id} value={list.id}>
                          {list.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-venue-units" ? "Publish" : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditVenueUnit;
