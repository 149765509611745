import React from "react";
import { Button, Box } from "@mui/material";
import Modal from "react-modal";

const ImagesModal = ({
  isFormOpenImages,
  closeFormModalImages,
  addImagesToGallery,
  addImagesToGuestRoom,
  addImagesToUnit,
  addedImages,
  imagesToAdd,
  handleImageClick,
  handleImageSelection,
  image,
  path,
  handleFeaturedImageUnitSelection,
}) => {
  return (
    <Modal
      isOpen={isFormOpenImages}
      onRequestClose={closeFormModalImages}
      contentLabel="Add New Unit Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(73, 85, 157, 0.5)",
        },
        content: {
          backgroundColor: "white",
          top: "20%",
          left: "29%",
          right: "5%",
          bottom: "15%",
          zIndex: "10",
        },
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <h3
          style={{
            textAlign: "center",
            margin: "30px 0",
            color: "#49559d",
            fontWeight: "600",
            fontSize: "32px",
          }}
        >
          Images
        </h3>
        <Box>
          <Button
            variant="outlined"
            style={{ marginRight: "20px" }}
            onClick={() => {
              handleImageClick(image.id);
              if (path === "gallery") {
                addImagesToGallery();
              } else if (path === "guestRoom") {
                addImagesToGuestRoom();
              } else if (path === "featuredImage") {
                handleImageSelection(image);
              } else if (path === "unit") {
                addImagesToUnit();
              } else if (path === "featuredImageUnit") {
                handleFeaturedImageUnitSelection(image);
              }
            }}
          >
            {path === "featuredImage" ? (
              <span>Add Selected Image</span>
            ) : (
              <span> Add Selected Images</span>
            )}
          </Button>
          <Button variant="outlined" onClick={closeFormModalImages}>
            Close
          </Button>
        </Box>
      </Box>

      <Box style={{ display: "flex", flexWrap: "wrap" }}>
        {addedImages.map((image) => (
          <Box
            key={image.id}
            style={{
              padding: "20px",
              cursor: "pointer",
              border: imagesToAdd.includes(image.id)
                ? "2px solid #49559d"
                : "none",
            }}
            onClick={() => handleImageClick(image.id)}
          >
            <img
              src={image.path}
              alt={image.alt_image_me}
              style={{
                width: "200px",
                height: "200px",
                borderRadius: "10px",
              }}
            />
          </Box>
        ))}
      </Box>
    </Modal>
  );
};

export default ImagesModal;
