import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchSocialMedia = () => {
  return axios
    .get(`${API_URL}/social-media`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteSocialMedia = (socialMediaId) => {
  return axios
    .delete(`${API_URL}/social-media/${socialMediaId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju: ${error.message}`);
    });
};

export const getSocialMedia = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/social-media/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju: ${error.message}`);
  }
};

export const editSocialMedia = async (id, socialMediaData) => {
  try {
    const response = await axios.put(
      `${API_URL}/social-media/${id}`,
      socialMediaData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju: ${error.message}`);
  }
};

export const addSocialMedia = (socialMediaData) => {
  return axios
    .post(`${API_URL}/social-media`, socialMediaData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju: ${error.message}`);
    });
};
