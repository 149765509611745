import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchUnitAmenitiesSections = () => {
  return axios
    .get(`${API_URL}/venue-unit-amenity-section`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteUnitAmenitiesSection = (unitAmenitiesSectionId) => {
  return axios
    .delete(`${API_URL}/venue-unit-amenity-section/${unitAmenitiesSectionId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju dokumenta: ${error.message}`);
    });
};

export const getUnitAmenitiesSection = async (id) => {
  try {
    const response = await axios.get(
      `${API_URL}/venue-unit-amenity-section/${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju dokumenta: ${error.message}`);
  }
};

export const editUnitAmenitiesSection = async (
  id,
  unitAmenitiesSectionData
) => {
  try {
    const response = await axios.put(
      `${API_URL}/venue-unit-amenity-section/${id}`,
      unitAmenitiesSectionData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju dokumenta: ${error.message}`);
  }
};

export const addUnitAmenitiesSection = (unitAmenitiesSectionData) => {
  return axios
    .post(`${API_URL}/venue-unit-amenity-section`, unitAmenitiesSectionData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju dokumenta: ${error.message}`);
    });
};
