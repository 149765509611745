import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Layout from "../../../../layout/Layout";
import VenueMenu from "../../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addUnitAmenitiesSection,
  getUnitAmenitiesSection,
  editUnitAmenitiesSection,
} from "../../../../services/venues/unitAmenitasSectionAPI";
import { fetchUnitAmenitiesLists } from "../../../../services/venues/unitAmenitiesListAPI";
import ButtonBack from "../../../../components/ButtonBack";

const AddEditVenueUnitsAmenitiesSection = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode =
    location === "add-new-venue-units-amenities-section" ? false : true;

  const [unitAmenitiesSectionData, setUnitAmenitiesSectionData] = useState({
    title: "",
    amenity_ids: [],
  });
  const [amenitiesList, setAmenitiesList] = useState([]);

  useEffect(() => {
    fetchUnitAmenitiesLists()
      .then((data) => {
        setAmenitiesList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (id) {
      getUnitAmenitiesSection(id)
        .then((data) => {
          setUnitAmenitiesSectionData({
            title: data.title,
            amenity_ids: data.amenities
              ? data.amenities?.map((list) => list.id)
              : [],
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUnitAmenitiesSectionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEditMode) {
      editUnitAmenitiesSection(id, unitAmenitiesSectionData)
        .then(() => {
          navigate("/venue-units-amenities-section");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addUnitAmenitiesSection(unitAmenitiesSectionData)
        .then(() => {
          navigate("/venue-units-amenities-section");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  const handleUnitAmenities = (event) => {
    setUnitAmenitiesSectionData((prevData) => ({
      ...prevData,
      amenity_ids: event.target.value,
    }));
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Venue Unitas Amenities Section
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="title"
                    label="Title"
                    required
                    value={unitAmenitiesSectionData.title}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>

                <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Venue Units Amenities List
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={unitAmenitiesSectionData.amenity_ids}
                    label="VenueTypes"
                    onChange={handleUnitAmenities}
                  >
                    {amenitiesList?.map((list) => (
                      <MenuItem key={list.id} value={list.id}>
                        {list.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-venue-units-amenities-section"
                    ? "Publish"
                    : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditVenueUnitsAmenitiesSection;
