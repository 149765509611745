import React, { useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import Layout from "../../layout/Layout";
import "./Categories.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { deleteCategory, fetchCategories } from "../../services/categoryAPI";

export default function Categories() {
  const [data, setData] = useState([]);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDelete = () => {
    if (categoryToDelete) {
      deleteCategory(categoryToDelete)
        .then(() => {
          setDeleteDialogOpen(false);
          setData((prevCategories) =>
            prevCategories.filter(
              (category) => category.id !== categoryToDelete
            )
          );
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
    setDeleteDialogOpen(false); // Close the dialog after delete
  };

  const handleDeleteButtonClicked = (id) => {
    setDeleteDialogOpen(true);
    setCategoryToDelete(id);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    fetchCategories().then((data) => {
      setData(data);
    });
  }, []);

  return (
    <Layout>
      <div>
        <div>
          <TextField
            value={searchValue}
            onChange={handleSearchInputChange}
            label="Search"
            variant="outlined"
            fullWidth
          />
        </div>

        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Categories
        </Typography>
        <Link
          to={{ pathname: "/add-new-category", state: { isEditMode: false } }}
        >
          <Button variant="outlined">Add New</Button>
        </Link>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {data
              .filter((item) =>
                item.name_me.toLowerCase().includes(searchValue.toLowerCase())
              )
              .reverse()
              .map((item) => (
                <TableBody key={item.id}>
                  <TableRow key={item.id}>
                    <TableCell>{item.name_me}</TableCell>
                    <TableCell>{item.short_description_me}</TableCell>
                    <TableCell>
                      <div>
                        <Link
                          to={{
                            pathname: `/edit-category/${item.id}`,
                            state: { isEditMode: true },
                          }}
                        >
                          <Button variant="outlined">Edit Item</Button>
                        </Link>
                        <Button
                          variant="outlined"
                          onClick={() => handleDeleteButtonClicked(item.id)}
                        >
                          Delete
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
          </Table>
        </TableContainer>

        {/* <Grid container spacing={2}>
          {data
            .filter((item) =>
              item.name_me.toLowerCase().includes(searchValue.toLowerCase())
            )
            .map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {item.name_me}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      className={classes.description}
                    >
                      {item.short_description_me}
                    </Typography>
                    <Link
                      to={`/edit-category/${item.id}`}
                      state={{ isEditMode: true }}
                    >
                      <Button variant="outlined" className={classes.editButton}>
                        Edit Category
                      </Button>
                    </Link>
                    <Button
                      variant="outlined"
                      className={classes.deleteButton}
                      onClick={() => handleDeleteButtonClicked(item.id)}
                    >
                      Delete
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid> */}

        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Delete Category</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Are you sure you want to delete this category?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Layout>
  );
}
