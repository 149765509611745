import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import Layout from "../../../layout/Layout";
import SearchComponent from "../../../components/SearchComponent";
import { Link } from "react-router-dom";

import TableChartIcon from "@mui/icons-material/TableChart";
import Pagination from "@mui/material/Pagination";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  deleteVenueInquiry,
  fetchVenueInquiries,
} from "../../../services/venues/venueInquiryAPI";

export default function VenueInquiry() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("name");
  const [isTabularView, setIsTabularView] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dataIdToDelete, setDataIdToDelete] = useState([]);
  const itemsPerPage = 6;

  useEffect(() => {
    fetchVenueInquiries()
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDelete = () => {
    if (dataIdToDelete) {
      deleteVenueInquiry(dataIdToDelete)
        .then(() => {
          setDeleteDialogOpen(false);
          setData((prevData) =>
            prevData.filter((inquiry) => inquiry.id !== dataIdToDelete)
          );
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };

  const handleDeleteButtonClicked = (inquiryId) => {
    setDeleteDialogOpen(true);
    setDataIdToDelete(inquiryId);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleTabularViewToggle = () => {
    setIsTabularView(!isTabularView);
  };

  const filteredData = data.filter((item) => {
    if (searchBy === "name") {
      const searchWords = searchValue.toLowerCase().split(" ");
      return searchWords.every((word) => {
        const firstName = item.first_name ? item.first_name.toLowerCase() : "";
        const lastName = item.last_name ? item.last_name.toLowerCase() : "";
        return firstName.includes(word) || lastName.includes(word);
      });
    }
    return true;
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handleClick = () => {
    console.log("klik");
  };

  return (
    <Layout>
      <div>
        <div>
          <Link
            to="/forms"
            style={{ textDecoration: "none", marginRight: "20px" }}
          >
            <Button variant="outlined">
              <ArrowBackIcon />
              Back
            </Button>
          </Link>
          <SearchComponent
            searchValue={searchValue}
            searchBy={searchBy}
            onSearchInputChange={handleSearchInputChange}
            onSearchByChange={handleSearchByChange}
          />
        </div>

        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Venue Inquiry
        </Typography>

        <div>
          <Link
            to={{
              pathname: "/add-new-venue-inquiries",
              state: { isEditMode: false },
            }}
          >
            <Button variant="outlined">Add New</Button>
          </Link>

          <Button
            startIcon={<TableChartIcon />}
            onClick={handleTabularViewToggle}
          ></Button>
        </div>

        {filteredData.length === 0 ? (
          <Typography
            variant="h6"
            style={{
              marginTop: "20px",
              color: "red",
              textAlign: "center",
            }}
          >
            There are no results
          </Typography>
        ) : (
          ""
        )}

        {!isTabularView ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Inquiry no</TableCell>
                  <TableCell>Event Type</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Arrive On</TableCell>
                  <TableCell>Depart On</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {paginatedData.reverse().map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.event_type}</TableCell>
                    <TableCell>
                      {item.first_name} {item.last_name}
                    </TableCell>
                    <TableCell>
                      {item.arrive_on ? item.arrive_on.slice(0, 10) : ""}
                    </TableCell>
                    <TableCell>
                      {item.depart_on ? item.depart_on.slice(0, 10) : ""}
                    </TableCell>
                    <TableCell>
                      <div>
                        <Link
                          to={{
                            pathname: `/edit-venue-inquiries/${item.id}`,
                            state: { isEditMode: true },
                            search: "?mode=read",
                          }}
                        >
                          <Button variant="outlined">Read</Button>
                        </Link>
                        <Link
                          to={{
                            pathname: `/edit-venue-inquiries/${item.id}`,
                            state: { isEditMode: true },
                          }}
                        >
                          <Button variant="outlined">Edit Item</Button>
                        </Link>
                        <Button
                          variant="outlined"
                          onClick={() => handleDeleteButtonClicked(item.id)}
                        >
                          Delete
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid container spacing={2}>
            {paginatedData.reverse().map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      Inquiry no: {item.id}
                    </Typography>
                    <Typography color="textSecondary">
                      Type: {item.event_type}
                    </Typography>
                    <Typography color="textSecondary">
                      Name: {item.first_name} {item.last_name}
                    </Typography>
                    <Typography color="textSecondary">
                      Arrive On: {item.arrive_on.slice(0, 10)}
                    </Typography>
                    <Typography color="textSecondary">
                      Depart On: {item.depart_on.slice(0, 10)}
                    </Typography>
                    <Link
                      to={{
                        pathname: `/edit-venue-inquiries/${item.id}`,
                        state: { isEditMode: true },
                      }}
                    >
                      <Button variant="outlined">Edit item</Button>
                    </Link>
                    <Button
                      variant="outlined"
                      onClick={() => handleDeleteButtonClicked(item.id)}
                    >
                      Delete
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        <Pagination
          page={currentPage}
          onChange={handlePageChange}
          style={{ marginTop: "1rem", float: "right" }}
          count={Math.ceil(filteredData.length / itemsPerPage)}
        />

        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Delete Confirmation</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this venue inquiry?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              onClick={handleDelete}
              color="error"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Layout>
  );
}
