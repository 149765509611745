import React, { useRef } from "react";
import {
  Button,
  Typography,
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const MediaGalleryInfo = ({
  galleriesList,
  handleDeleteGallery,
  setSelectedGalleryId,
  selectedGalleryId,
  toggleImageForm,
  galleriesImagesPivot,
  filesList,
  handleDeleteImage,
  handleAddToGalleryImage,
  toggleGalleryForm,
  handleDeleteVideoGallery,
  toggleVideoForm,
  videoList,
}) => {
  const videoRef = useRef(null);

  return (
    <Container>
      <Container
        style={{
          border: "4px solid #49559d",
          borderRadius: "15px",
          marginBottom: "30px",
        }}
      >
        <Typography
          style={{
            textAlign: "center",
            margin: "30px 0",
            color: "#49559d",
            fontWeight: "600",
            fontSize: "32px",
          }}
        >
          Photo Gallery
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TableCell>Image</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Action Buttons</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {galleriesList?.map((gallery) => (
                <>
                  <TableRow
                    key={gallery.id}
                    style={{
                      outline: "2px solid #49559d",
                      marginBottom: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <TableCell>
                      <img
                        src={gallery.image}
                        alt={gallery.alt_text}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "5px",
                        }}
                      />
                    </TableCell>
                    <TableCell>{gallery.name}</TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: `/edit-venue-photo-gallery/${gallery.id}`,
                          state: { isEditMode: true },
                        }}
                      >
                        <Button variant="outlined">Edit Item</Button>
                      </Link>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDeleteGallery(gallery.id)}
                      >
                        Delete
                      </Button>
                      <IconButton
                        onClick={() =>
                          setSelectedGalleryId(
                            selectedGalleryId === gallery.id ? null : gallery.id
                          )
                        }
                      >
                        {selectedGalleryId === gallery.id ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {selectedGalleryId === gallery.id && (
                    <Box>
                      <Container
                        style={{
                          position: "relative",
                          top: "0",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#49559d",
                            fontWeight: "400",
                            fontSize: "24px",
                          }}
                        >
                          Images
                        </Typography>
                        <Box style={{ display: "flex", gap: "20px" }}>
                          <Button
                            variant="outlined"
                            onClick={() =>
                              toggleImageForm(gallery.id, "gallery")
                            }
                          >
                            Add image to gallery
                          </Button>
                        </Box>
                      </Container>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          padding: "10px",
                        }}
                      >
                        {galleriesImagesPivot.map((image) => {
                          const matchedFile = filesList.find(
                            (file) =>
                              file.id === image.image_id &&
                              gallery.id === image.gallery_id
                          );
                          if (matchedFile) {
                            return (
                              <div
                                key={matchedFile.id}
                                style={{
                                  padding: "10px",
                                }}
                              >
                                <Box
                                  style={{
                                    backgroundColor: "#F0F0F0",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    boxShadow: "rgba(0, 0, 0, 0.3) 1px 2px 4px",
                                  }}
                                >
                                  <img
                                    src={matchedFile.path}
                                    alt={matchedFile.alt_image_me}
                                    style={{
                                      width: "170px",
                                      height: "150px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <IconButton
                                    color="error"
                                    onClick={() =>
                                      handleDeleteImage(
                                        gallery.id,
                                        matchedFile.id
                                      )
                                    }
                                  >
                                    <DeleteForeverIcon />
                                  </IconButton>
                                  <Button
                                    variant="outlined"
                                    style={{ fontSize: "12px" }}
                                    onClick={() =>
                                      handleAddToGalleryImage(
                                        gallery.id,
                                        matchedFile.path
                                      )
                                    }
                                  >
                                    Set Gallery Image
                                  </Button>
                                </Box>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </Box>
                  )}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Container style={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant="outlined"
            style={{ margin: "30px auto" }}
            onClick={toggleGalleryForm}
          >
            Create New Gallery
          </Button>
        </Container>
      </Container>
      <Container
        style={{
          border: "4px solid #49559d",
          borderRadius: "15px",
        }}
      >
        <Typography
          style={{
            textAlign: "center",
            margin: "30px 0",
            color: "#49559d",
            fontWeight: "600",
            fontSize: "32px",
          }}
        >
          Video Gallery
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Thumbnail Image</TableCell>
                <TableCell>Video</TableCell>
                <TableCell>Action Buttons</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {videoList?.map((video) => (
                <>
                  <TableRow
                    key={video.id}
                    style={{
                      outline: "2px solid #49559d",
                      marginBottom: "10px",
                      alignItems: "center",
                    }}
                  >
                    <TableCell>
                      <img
                        src={video.thumbnail_image}
                        alt={video.alt_text}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "5px",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <video ref={videoRef} controls width="300" height="200">
                        <source src={video.video_url} type="video/mp4" />
                      </video>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: `/edit-venue-video-gallery/${video.id}`,
                          state: { isEditMode: true },
                        }}
                      >
                        <Button variant="outlined">Edit Item</Button>
                      </Link>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDeleteVideoGallery(video.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Container style={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant="outlined"
            style={{ margin: "30px auto" }}
            onClick={toggleVideoForm}
          >
            Create New Video
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

export default MediaGalleryInfo;
