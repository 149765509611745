import Sidebar from "../components/Sidebar";
import "./layout.css";
import { Context } from "../context/Context";
import { useContext } from "react";

export default function Layout({ children }) {
  const { active, setActive } = useContext(Context);

  return (
    <>
      <Sidebar active={active} setActive={setActive} />
      <div className={"container"}>{children}</div>
    </>
  );
}
