import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchTestimonials = () => {
  return axios
    .get(`${API_URL}/testimonial`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteTestimonials = (testimonialsId) => {
  return axios
    .delete(`${API_URL}/testimonial/${testimonialsId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju ${error.message}`);
    });
};

export const getTestimonials = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/testimonial/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju ${error.message}`);
  }
};

export const editTestimonials = async (id, testimonialData) => {
  try {
    const response = await axios.put(
      `${API_URL}/testimonial/${id}`,
      testimonialData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju ${error.message}`);
  }
};

export const addTestimonials = (testimonialData) => {
  return axios
    .post(`${API_URL}/testimonial`, testimonialData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju ${error.message}`);
    });
};
