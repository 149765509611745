import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Box,
} from "@mui/material";
import Layout from "../../../layout/Layout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addAmenitiesSection,
  getAmenitiesSection,
  editAmenitiesSection,
} from "../../../services/coworking/amenitiesSectionAPI";
import { fetchAmenitiesLists } from "../../../services/coworking/amenitiesListAPI";

const AddEditCoworkingAmenities = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode =
    location === "add-new-coworking-amenities-section" ? false : true;
  const [amenitiesSectionData, setAmenitiesSectionData] = useState({
    title: "",
    lists: [],
  });

  const [amenitiesList, setAmenitiesList] = useState([]);

  useEffect(() => {
    fetchAmenitiesLists()
      .then((data) => {
        setAmenitiesList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (id) {
      getAmenitiesSection(id)
        .then((data) => {
          setAmenitiesSectionData({
            title: data.title,
            lists: data.lists ? data.lists?.map((list) => list.id) : [],
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAmenitiesSectionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isEditMode) {
      editAmenitiesSection(id, amenitiesSectionData)
        .then(() => {
          navigate("/coworking-amenities-section");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addAmenitiesSection(amenitiesSectionData)
        .then(() => {
          navigate("/coworking-amenities-section");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  const handleAmenities = (event) => {
    setAmenitiesSectionData((prevData) => ({
      ...prevData,
      lists: event.target.value,
    }));
  };

  const handleBack = () => {
    // Handle Back action here
  };

  return (
    <Layout>
      <div>
        <Box style={{ display: "flex" }}>
          <Link
            to="/coworking-amenities-section"
            style={{ textDecoration: "none" }}
          >
            <Button variant="outlined" onClick={handleBack}>
              <ArrowBackIcon />
              Back
            </Button>
          </Link>
        </Box>
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Coworking Amenities Section
        </Typography>

        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="title"
              label="Title"
              value={amenitiesSectionData.title}
              fullWidth
              onChange={handleInputChange}
            />
          </div>

          <div>
            <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
              <InputLabel id="demo-simple-select-label">
                Amenities List
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple
                value={amenitiesSectionData.lists}
                label="VenueTypes"
                onChange={handleAmenities}
              >
                {amenitiesList?.map((list) => (
                  <MenuItem key={list.id} value={list.id}>
                    {list.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              {location === "add-new-coworking-amenities-section"
                ? "Publish"
                : "Save"}
            </Button>
          </Container>
        </form>
      </div>
    </Layout>
  );
};

export default AddEditCoworkingAmenities;
