import React from "react";
import {
  TextField,
  Skeleton,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
} from "@mui/material";

const OverviewInfo = ({
  loading,
  venueData,
  handleInputChange,
  handleCategories,
  categoriesList,
  handleGeneralInformations,
  generalInformations,
  handleAmenitiesLists,
  amenitiesLists,
  handleTypeOfEvent,
  typeOfEvent,
  handleStylesList,
  stylesList,
}) => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={168}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px" }}
        />
      ) : (
        <TextField
          sx={{ marginTop: "30px" }}
          name="description"
          label="Description"
          multiline
          rows={6}
          value={venueData.description}
          fullWidth
          onChange={handleInputChange}
        />
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={56}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px" }}
        />
      ) : (
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="chain"
          label="Chain"
          value={venueData.chain}
          onChange={handleInputChange}
        />
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={56}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px" }}
        />
      ) : (
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="built"
          label="Built"
          value={venueData.built}
          type="number"
          onChange={handleInputChange}
        />
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={56}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px" }}
        />
      ) : (
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="renovated"
          label="Renovated"
          value={venueData.renovated}
          type="number"
          onChange={handleInputChange}
        />
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={56}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px" }}
        />
      ) : (
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="brand"
          label="Brand"
          value={venueData.brand}
          onChange={handleInputChange}
        />
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={56}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px" }}
        />
      ) : (
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="order_venue_in_list"
          label="Order Venue InList"
          value={venueData.order_venue_in_list}
          type="number"
          onChange={handleInputChange}
        />
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={56}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px" }}
        />
      ) : (
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="general_review_score"
          label="General Review Score"
          value={venueData.general_review_score}
          type="number"
          onChange={handleInputChange}
        />
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={56}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px" }}
        />
      ) : (
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="total_guest_rooms"
          label="Guest Rooms"
          value={venueData.total_guest_rooms}
          type="number"
          onChange={handleInputChange}
        />
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={56}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px" }}
        />
      ) : (
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="total_conference_room_space"
          label="Total Conference Room Capacity"
          value={venueData.total_conference_room_space}
          type="number"
          onChange={handleInputChange}
        />
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={56}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px" }}
        />
      ) : (
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="total_number_of_conference_rooms"
          label="Total Number Of Conference Rooms"
          value={venueData.total_number_of_conference_rooms}
          type="number"
          onChange={handleInputChange}
        />
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={56}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px" }}
        />
      ) : (
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="largest_rooms_space"
          label="Largest Room Capacity"
          value={venueData.largest_rooms_space}
          type="number"
          onChange={handleInputChange}
        />
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={56}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px" }}
        />
      ) : (
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="total_number_of_people"
          label="Total Number Of People"
          value={venueData.total_number_of_people}
          type="number"
          onChange={handleInputChange}
        />
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={56}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px" }}
        />
      ) : (
        <FormControl
          sx={{
            width: "45%",
            marginTop: "30px",
          }}
        >
          <InputLabel id="demo-simple-select-label">Categories</InputLabel>
          <Select
            name="venue_categories"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            multiple
            value={venueData.venue_categories}
            label="VenueTypes"
            onChange={handleCategories}
          >
            {categoriesList?.map((list) => (
              <MenuItem key={list.id} value={list.id}>
                {list.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          marginTop: "30px",
          padding: "30px",
          width: "100%",
          border: "4px solid #49559d",
        }}
      >
        {loading ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            height={56}
            sx={{ bgcolor: "#49559d40" }}
            style={{ marginTop: "30px" }}
          />
        ) : (
          <FormControl sx={{ width: "45%" }}>
            <InputLabel id="demo-simple-select-label">
              General Information
            </InputLabel>
            <Select
              name="general_informations"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              multiple
              value={venueData.general_informations}
              label="VenueTypes"
              onChange={handleGeneralInformations}
            >
              {generalInformations?.map((list) => (
                <MenuItem key={list.id} value={list.id}>
                  {list.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {loading ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            height={56}
            sx={{ bgcolor: "#49559d40" }}
            style={{ marginTop: "30px" }}
          />
        ) : (
          <FormControl sx={{ width: "45%" }}>
            <InputLabel id="demo-simple-select-label">
              Amenities Lists
            </InputLabel>
            <Select
              name="amenities_lists"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              multiple
              value={venueData.amenities_lists}
              label="VenueTypes"
              onChange={handleAmenitiesLists}
            >
              {amenitiesLists?.map((list) => (
                <MenuItem key={list.id} value={list.id}>
                  {list.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {loading ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            height={56}
            sx={{ bgcolor: "#49559d40" }}
            style={{ marginTop: "30px" }}
          />
        ) : (
          <FormControl sx={{ marginTop: "30px", width: "45%" }}>
            <InputLabel id="demo-simple-select-label">Type Of Event</InputLabel>
            <Select
              name="events"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              multiple
              value={venueData.events}
              label="VenueTypes"
              onChange={handleTypeOfEvent}
            >
              {typeOfEvent?.map((list) => (
                <MenuItem key={list.id} value={list.id}>
                  {list.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {loading ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            height={56}
            sx={{ bgcolor: "#49559d40" }}
            style={{ marginTop: "30px" }}
          />
        ) : (
          <FormControl sx={{ marginTop: "30px", width: "45%" }}>
            <InputLabel id="demo-simple-select-label">Style list</InputLabel>
            <Select
              name="styles"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              multiple
              value={venueData.styles}
              label="styles"
              onChange={handleStylesList}
            >
              {stylesList?.map((list) => (
                <MenuItem key={list.id} value={list.id}>
                  {list.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
    </Container>
  );
};

export default OverviewInfo;
