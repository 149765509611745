import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../../../layout/Layout";
import VenueMenu from "../../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addAmenitiesList,
  getAmenitiesList,
  editAmenitiesList,
} from "../../../../services/venues/amenitiesListAPI";
import ButtonBack from "../../../../components/ButtonBack";
import IconModal from "../../../../components/Venue/Modals/IconModal";

const AddEditVenueAmenitiesList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  const isEditMode = location === "add-new-venue-amenities-list" ? false : true;

  const [amenitiesListData, setAmenitiesListData] = useState({
    icon: "",
    iconAltText: "",
    title: "",
  });

  useEffect(() => {
    if (id) {
      getAmenitiesList(id)
        .then((data) => {
          setAmenitiesListData({
            icon: data.icon,
            iconAltText: data.icon_alt_text,
            title: data.title,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAmenitiesListData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newAmenitiesListData = {
      icon: amenitiesListData.icon,
      icon_alt_text: amenitiesListData.iconAltText,
      title: amenitiesListData.title,
    };
    if (isEditMode) {
      editAmenitiesList(id, newAmenitiesListData)
        .then((response) => {
          setAmenitiesListData({
            icon: "",
            iconAltText: "",
            title: "",
          });

          navigate("/venue-amenities-list");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addAmenitiesList(newAmenitiesListData)
        .then((response) => {
          setAmenitiesListData({
            ...amenitiesListData,
            icon: response.icon,
            iconAltText: response.icon_alt_text,
            title: response.title,
          });
          navigate("/venue-amenities-list");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Venue Amenities List
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <IconModal
                  setData={setAmenitiesListData}
                  data={amenitiesListData.icon}
                  handleInputChange={handleInputChange}
                  setImageModalOpen={setImageModalOpen}
                  isImageModalOpen={isImageModalOpen}
                />
                <Container style={{ marginTop: "30px" }}>
                  <TextField
                    name="iconAltText"
                    label={isImageModalOpen ? "" : "Icon Alt Text"}
                    value={amenitiesListData.iconAltText}
                    fullWidth
                    onChange={handleInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="title"
                    label={isImageModalOpen ? "" : "Title"}
                    required
                    value={amenitiesListData.title}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </Container>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-venue-amenities-list"
                    ? "Publish"
                    : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditVenueAmenitiesList;
