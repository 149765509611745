import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
} from "@mui/material";
import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addDistanceSection,
  getDistanceSection,
  editDistanceSection,
} from "../../../services/venues/distanceSectionAPI";
import { fetchDistanceLists } from "../../../services/venues/distanceListAPI";
import ButtonBack from "../../../components/ButtonBack";

const AddEditDistance = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode = location === "add-new-distance" ? false : true;

  const [distanceData, setDistanceData] = useState({
    icon: "",
    icon_alt_text: "",
    distance_section_title: "",
    description: "",
    distanceLists: [],
  });
  const [distanceList, setDistanceList] = useState([]);

  useEffect(() => {
    fetchDistanceLists()
      .then((data) => {
        setDistanceList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (id) {
      getDistanceSection(id)
        .then((data) => {
          setDistanceData({
            icon: data.icon,
            icon_alt_text: data.icon_alt_text,
            distance_section_title: data.distance_section_title,
            description: data.description,
            distanceLists: data.distance_lists
              ? data.distance_lists?.map((list) => list.id)
              : [],
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDistanceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isEditMode) {
      editDistanceSection(id, distanceData)
        .then(() => {
          navigate("/distance");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addDistanceSection(distanceData)
        .then(() => {
          navigate("/distance");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  const handleList = (event) => {
    setDistanceData((prevData) => ({
      ...prevData,
      distanceLists: event.target.value,
    }));
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Distance
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="icon"
                    label="Icon"
                    value={distanceData.icon}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="icon_alt_text"
                    label="Icon Alt Text"
                    value={distanceData.icon_alt_text}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="distance_section_title"
                    label="Distance Section Title"
                    required
                    value={distanceData.distance_section_title}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="description"
                    label="Description"
                    required
                    multiline
                    rows={6}
                    value={distanceData.description}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                    <InputLabel id="demo-simple-select-label">
                      Distance List
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiple
                      value={distanceData.distanceLists}
                      label="VenueTypes"
                      onChange={handleList}
                    >
                      {distanceList?.map((list) => (
                        <MenuItem key={list.id} value={list.id}>
                          {list.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-distance" ? "Publish" : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditDistance;
