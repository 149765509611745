import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = "https://montenegromice.com/mice-api";

export const fetchTypeOfGuestRooms = () => {
  return axios
    .get(`${API_URL}/type-of-guest-room`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteTypeOfGuestRoom = (typeOfGuestRoomId) => {
  return axios
    .delete(`${API_URL}/type-of-guest-room/${typeOfGuestRoomId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju dokumenta: ${error.message}`);
    });
};

export const getTypeOfGuestRoom = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/type-of-guest-room/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju dokumenta: ${error.message}`);
  }
};

export const editTypeOfGuestRoom = async (id, typeOfGuestRoomData) => {
  try {
    const response = await axios.put(
      `${API_URL}/type-of-guest-room/${id}`,
      typeOfGuestRoomData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju dokumenta: ${error.message}`);
  }
};

export const addTypeOfGuestRoom = (typeOfGuestRoomData) => {
  return axios
    .post(`${API_URL}/type-of-guest-room`, typeOfGuestRoomData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju dokumenta: ${error.message}`);
    });
};

export const addImageToGuestRoom = (guestRoomId, imageId) => {
  return axios
    .post(`${API_URL}/add-image-to-guest-room`, {
      guest_room_id: guestRoomId,
      image_id: imageId,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        `Greška pri dodavanju slike u galeriju: ${error.message}`
      );
    });
};

export const getGuestRoomImages = (guestRoomId) => {
  return axios
    .post(`${API_URL}/get-guest-room-images`, {
      guest_room_id: guestRoomId,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        `Greška pri dobavljanju slika iz galerije: ${error.message}`
      );
    });
};

export const removeImageFromGuestRoom = (guestRoomId, imageId) => {
  return axios
    .post(`${API_URL}/remove-image-from-guest-room`, {
      guest_room_id: guestRoomId,
      image_id: imageId,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        `Greška pri uklanjanju slike iz galerije: ${error.message}`
      );
    });
};

export const checkIfImageExistsGuestRoom = async (guestRoomId, imageId) => {
  try {
    const response = await axios.get(
      `${API_URL}/check-image-exists-guest-room`,
      {
        params: {
          guestRoomId,
          imageId,
        },
      }
    );

    return response.data.exists;
  } catch (error) {
    console.error(
      "Greška pri proveri postojanja slike u galeriji:",
      error.message
    );
    throw error;
  }
};
