import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addTypeOfEvent,
  getTypeOfEvent,
  editTypeOfEvent,
} from "../../../services/venues/venueTypeOfEventAPI";
import ButtonBack from "../../../components/ButtonBack";
import IconModal from "../../../components/Venue/Modals/IconModal";

const AddEditTypeOfEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  const isEditMode = location === "add-new-type-of-events" ? false : true;

  const [typeOfEventData, setTypeOfEventData] = useState({
    icon: "",
    iconAltText: "",
    title: "",
  });

  useEffect(() => {
    if (id) {
      getTypeOfEvent(id)
        .then((data) => {
          setTypeOfEventData({
            icon: data.icon,
            iconAltText: data.icon_alt_text,
            title: data.title,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTypeOfEventData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newTypeOfEventData = {
      icon: typeOfEventData.icon,
      icon_alt_text: typeOfEventData.iconAltText,
      title: typeOfEventData.title,
    };
    if (isEditMode) {
      editTypeOfEvent(id, newTypeOfEventData)
        .then((response) => {
          setTypeOfEventData({
            icon: "",
            iconAltText: "",
            title: "",
          });

          navigate("/type-of-events");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju ", error);
        });
    } else {
      addTypeOfEvent(newTypeOfEventData)
        .then((response) => {
          setTypeOfEventData({
            ...typeOfEventData,
            icon: response.icon,
            iconAltText: response.icon_alt_text,
            title: response.title,
          });
          navigate("/type-of-events");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Type of event
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <IconModal
                  setData={setTypeOfEventData}
                  data={typeOfEventData.icon}
                  handleInputChange={handleInputChange}
                  setImageModalOpen={setImageModalOpen}
                  isImageModalOpen={isImageModalOpen}
                />
                <Container style={{ marginTop: "30px" }}>
                  <TextField
                    name="iconAltText"
                    label={isImageModalOpen ? "" : "Icon Alt Text"}
                    value={typeOfEventData.iconAltText}
                    fullWidth
                    onChange={handleInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="title"
                    label={isImageModalOpen ? "" : "Title"}
                    required
                    value={typeOfEventData.title}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </Container>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-type-of-events" ? "Publish" : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditTypeOfEvent;
