import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../../../layout/Layout";
import VenueMenu from "../../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addUnitPhotoGallery,
  getUnitPhotoGallery,
  editUnitPhotoGallery,
} from "../../../../services/venues/unitPhotoGalleryAPI";
import ButtonBack from "../../../../components/ButtonBack";

const AddEditVenueUnitPhotoGallery = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode =
    location === "add-new-venue-units-photo-gallery" ? false : true;

  const [unitPhotoGalleryData, setUnitPhotoGalleryData] = useState({
    image: "",
    altText: "",
  });

  useEffect(() => {
    if (id) {
      getUnitPhotoGallery(id)
        .then((data) => {
          setUnitPhotoGalleryData({
            image: data.image,
            altText: data.alt_text,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUnitPhotoGalleryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newUnitPhotoGalleryData = {
      image: unitPhotoGalleryData.image,
      alt_text: unitPhotoGalleryData.altText,
    };
    if (isEditMode) {
      editUnitPhotoGallery(id, newUnitPhotoGalleryData)
        .then((response) => {
          setUnitPhotoGalleryData({
            image: "",
            altText: "",
          });

          navigate("/venue-units-photo-gallery");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addUnitPhotoGallery(newUnitPhotoGalleryData)
        .then((response) => {
          setUnitPhotoGalleryData({
            ...unitPhotoGalleryData,
            image: response.image,
            altText: response.alt_text,
          });
          navigate("/venue-units-photo-gallery");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Venue Unit Photo Gallery
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="image"
                    label="Image"
                    required
                    value={unitPhotoGalleryData.image}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="altText"
                    label="Alt Text"
                    required
                    value={unitPhotoGalleryData.altText}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-venue-units-photo-gallery"
                    ? "Publish"
                    : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditVenueUnitPhotoGallery;
