import axios from "axios";
import { API_URL } from "./const";

export const fetchDistanceSections = async () => {
  return axios
    .get(`${API_URL}/coworking/distance-section`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteDistanceSection = async (distanceSectionId) => {
  return axios
    .delete(`${API_URL}/coworking/distance-section/${distanceSectionId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const addDistanceSection = async (distanceSectionData) => {
  return axios
    .post(`${API_URL}/coworking/distance-section`, distanceSectionData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getDistanceSection = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/coworking/distance-section/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editDistanceSection = async (id, distanceSectionData) => {
  try {
    const response = await axios.put(`${API_URL}/coworking/distance-section/${id}`, distanceSectionData);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
