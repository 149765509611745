import axios from 'axios';

export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchGeneralInformation = () => {
  return axios.get(`${API_URL}/coworking/general-information`)
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.message);
    });
};

export const deleteGeneralInformation = (generalInformationId) => {
    return axios.delete(`${API_URL}/coworking/general-information/${generalInformationId}`)
      .then(response => response.data)
      .catch(error => {
        throw new Error(`Greška pri brisanju: ${error.message}`);
      });
  };

  export const getGeneralInformation = async (id) => {
    try {
      const response = await axios.get(`${API_URL}/coworking/general-information/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Greška pri dobavljanju: ${error.message}`);
    }
  };

  export const editGeneralInformation = async (id, generalInformationData) => {
    try {
      const response = await axios.put(`${API_URL}/coworking/general-information/${id}`, generalInformationData);
      return response.data;
    } catch (error) {
      throw new Error(`Greška pri editovanju: ${error.message}`);
    }
  };

  export const addGeneralInformation = (generalInformationData) => {
    return axios.post(`${API_URL}/coworking/general-information`, generalInformationData)
      .then(response => response.data)
      .catch(error => {
        throw new Error(`Greška pri dodavanju: ${error.message}`);
      });
  };
