import React from "react";
import { Button, Container, Typography, TextField } from "@mui/material";
import Modal from "react-modal";

const VideoModal = ({
  isFormOpenVideo,
  closeFormModalVideo,
  handleVideoSubmit,
  handleInputChangeVideo,
  videoListData,
}) => {
  return (
    <Modal
      isOpen={isFormOpenVideo}
      onRequestClose={closeFormModalVideo}
      contentLabel="Add New Video Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(73, 85, 157, 0.5)",
        },
        content: {
          backgroundColor: "white",
          top: "20%",
          left: "29%",
          right: "5%",
          bottom: "15%",
          zIndex: "10",
        },
      }}
    >
      <div>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: "center", marginTop: "30px" }}
        >
          Video
        </Typography>
        <form onSubmit={handleVideoSubmit}>
          <Container
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <TextField
              sx={{ marginTop: "30px", width: "45%" }}
              name="video_url"
              label="Video URL"
              required
              value={videoListData.video_url}
              onChange={handleInputChangeVideo}
            />
            <TextField
              sx={{ marginTop: "30px", width: "45%" }}
              name="thumbnail_image"
              label="Thumbnail Image"
              required
              value={videoListData.thumbnail_image}
              onChange={handleInputChangeVideo}
            />
            <TextField
              sx={{ marginTop: "30px", width: "45%" }}
              name="description"
              label="Description"
              value={videoListData.description}
              onChange={handleInputChangeVideo}
              inputProps={{ step: "0.01", min: "0" }}
            />
          </Container>
          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              Publish
            </Button>
          </Container>
        </form>
      </div>
      <Button variant="outlined" onClick={closeFormModalVideo}>
        Close
      </Button>
    </Modal>
  );
};

export default VideoModal;
