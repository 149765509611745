import Layout from "../layout/Layout";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography, Button } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { PostAddOutlined } from "@mui/icons-material";

const pages = [
  {
    name: "Venue Inquiries",
    icon: <PostAddOutlined />,
    link: "/venue-inquiries",
  },
];
export default function Forms() {
  const [active, setActive] = useState("");

  const handleItemClick = (name) => {
    setActive(name);
  };

  return (
    <Layout>
      <div
        style={{
          width: "25%",
          height: "100%",
          borderRight: "1px solid #ccc",
          boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.1)",
          marginTop: "-50px",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{ textAlign: "center", paddingTop: "20px" }}
        >
          Forms Menu
        </Typography>
        <div
          style={{
            height: "82vh",
            overflowY: "auto",
            borderTop: "1px solid #ccc",
          }}
        >
          <List>
            {pages.map((page) => (
              <ListItem key={page.name} disablePadding>
                <Box style={{ width: "100%" }}>
                  <Link
                    to={page.link}
                    onClick={() => handleItemClick(page.name)}
                    style={{
                      textDecoration: "none",
                      color: active === page.name ? "#49559d" : "#5A5A5A",
                      backgroundColor:
                        active === page.name ? "#49559d" : "#e2e2e2",
                      width: "100%",
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon
                        style={{
                          color: active === page.name ? "#49559d" : "#49559d",
                        }}
                      >
                        {page.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={page.name}
                        style={{
                          color: active === page.name ? "#49559d" : "#49559d",
                        }}
                      />
                    </ListItemButton>
                  </Link>
                </Box>
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </Layout>
  );
}
