import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addVenueVideoGallery,
  getVenueVideoGallery,
  editVenueVideoGallery,
} from "../../../services/venues/venueVideoGalleryAPI";
import ButtonBack from "../../../components/ButtonBack";

const AddEditVenueVideoGallery = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode = location === "add-new-venue-video-gallery" ? false : true;

  const [venueVideoGalleryData, setVenueVideoGalleryData] = useState({
    videoUrl: "",
    description: "",
    thumbnailImage: "",
  });

  useEffect(() => {
    if (id) {
      getVenueVideoGallery(id)
        .then((data) => {
          setVenueVideoGalleryData({
            videoUrl: data.video_url,
            description: data.description,
            thumbnailImage: data.thumbnail_image,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setVenueVideoGalleryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newVenueVideoGalleryData = {
      video_url: venueVideoGalleryData.videoUrl,
      description: venueVideoGalleryData.description,
      thumbnail_image: venueVideoGalleryData.thumbnailImage,
    };
    if (isEditMode) {
      editVenueVideoGallery(id, newVenueVideoGalleryData)
        .then((response) => {
          setVenueVideoGalleryData({
            videoUrl: "",
            description: "",
            thumbnailImage: "",
          });

          navigate("/venue-video-gallery");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addVenueVideoGallery(newVenueVideoGalleryData)
        .then((response) => {
          setVenueVideoGalleryData({
            ...venueVideoGalleryData,
            videoUrl: response.video_url,
            description: response.description,
            thumbnailImage: response.thumbnail_image,
          });
          navigate("/venue-video-gallery");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju dokumenta:", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Venue Video Gallery
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="videoUrl"
                    label="Video URL"
                    required
                    value={venueVideoGalleryData.videoUrl}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="description"
                    label="description"
                    value={venueVideoGalleryData.description}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="thumbnailImage"
                    label="Thumbnail Image"
                    value={venueVideoGalleryData.thumbnailImage}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-venue-video-gallery"
                    ? "Publish"
                    : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditVenueVideoGallery;
