import React from "react";
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";

const DistanceInfo = ({
  toggleDistanceForm,
  distanceSectionList,
  handleDeleteDistance,
  handleDragEnd,
  handleDragStart,
  isDraggingDistance,
}) => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        margin: "30px auto",
      }}
    >
      {isDraggingDistance}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Move</TableCell>
              <TableCell>Order</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Distance amount</TableCell>
              <TableCell>Action Buttons</TableCell>
            </TableRow>
          </TableHead>
          <Droppable droppableId="droppable-1">
            {(provided, snapshot) => (
              <>
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {distanceSectionList
                    ?.sort((a, b) => a.order - b.order)
                    .map((distanceList, index) => (
                      <Draggable
                        key={distanceList.title}
                        draggableId={distanceList.title}
                        index={index}
                        onStart={handleDragStart}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            key={distanceList.title}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TableCell component="th" scope="row">
                              <ControlCameraIcon />
                            </TableCell>
                            <TableCell>{distanceList.order}</TableCell>
                            <TableCell>{distanceList.title}</TableCell>
                            <TableCell>
                              {distanceList.distance_amount}
                            </TableCell>
                            <TableCell>
                              <Link
                                to={{
                                  pathname: `/edit-venue-distance-list/${distanceList.id}`,
                                  state: { isEditMode: true },
                                }}
                              >
                                <Button variant="outlined">Edit Item</Button>
                              </Link>
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() =>
                                  handleDeleteDistance(distanceList.id)
                                }
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </TableBody>
              </>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
      <Container style={{ display: "flex", flexDirection: "column" }}>
        <Button
          variant="outlined"
          style={{ margin: "30px auto" }}
          onClick={toggleDistanceForm}
        >
          Create New Distance
        </Button>
      </Container>
    </Container>
  );
};

export default DistanceInfo;
