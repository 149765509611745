import axios from "axios";
import { API_URL } from "../const";

export const fetchPhotoGalleries = async () => {
  return axios
    .get(`${API_URL}/coworking/units-photo-gallery`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deletePhotoGallery = async (id) => {
  return axios
    .delete(`${API_URL}/coworking/units-photo-gallery/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const addPhotoGallery = async (data) => {
  return axios
    .post(`${API_URL}/coworking/units-photo-gallery`, data)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getPhotoGallery = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/coworking/units-photo-gallery/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editPhotoGallery = async (id, data) => {
  try {
    const response = await axios.put(`${API_URL}/coworking/units-photo-gallery/${id}`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
