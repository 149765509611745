import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Switch,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import Layout from "../../../layout/Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addVenueInquiry,
  getVenueInquiry,
  editVenueInquiry,
} from "../../../services/venues/venueInquiryAPI";
import { fetchVenues } from "../../../services/venues/venueAPI";
import { fetchVenueUnits } from "../../../services/venues/venueUnitAPI";
import ButtonBack from "../../../components/ButtonBack";

const AddEditVenueInquiry = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  let location = useLocation();
  location = location.pathname.slice(1);
  let locationSearch = useLocation();

  const isEditMode = location === "add-new-venue-inquiries" ? false : true;

  const [data, setData] = useState({
    event_type: "",
    arrive_on: "",
    depart_on: "",
    are_your_event_dates_flex: "",
    rooms_min: "",
    rooms_max: "",
    do_you_need_meeting_rooms: "",
    attendees_per_day: "",
    tell_us_more: "",
    first_name: "",
    last_name: "",
    gender: "",
    country: "",
    email: "",
    phone: "",
    industry: "",
    venues: [],
    unit_requested_id: "",
  });

  const [venueRequested, setVenueRequested] = useState([]);
  const [unitRequested, setUnitRequested] = useState([]);

  useEffect(() => {
    fetchVenues()
      .then((data) => {
        setVenueRequested(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchVenueUnits()
      .then((data) => {
        setUnitRequested(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (id) {
      getVenueInquiry(id)
        .then((data) => {
          setData({
            id: data.id,
            event_type: data.event_type,
            arrive_on: formatDate(data.arrive_on),
            depart_on: formatDate(data.depart_on),
            are_your_event_dates_flex: data.are_your_event_dates_flex,
            rooms_min: data.rooms_min,
            rooms_max: data.rooms_max,
            do_you_need_meeting_rooms: data.do_you_need_meeting_rooms,
            attendees_per_day: data.attendees_per_day,
            tell_us_more: data.tell_us_more,
            first_name: data.first_name,
            last_name: data.last_name,
            gender: data.gender,
            country: data.country,
            email: data.email,
            phone: data.phone,
            industry: data.industry,
            venues: data.venues ? data.venues?.map((list) => list.id) : [],
            unit_requested_id: data.unit_requested_id,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isEditMode) {
      editVenueInquiry(id, data)
        .then(() => {
          navigate("/venue-inquiries");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addVenueInquiry(data)
        .then(() => {
          navigate("/venue-inquiries");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  const handleVenue = (event) => {
    setData((prevData) => ({
      ...prevData,
      venues: event.target.value,
    }));
  };

  const handleVenueUnit = (event) => {
    setData((prevData) => ({
      ...prevData,
      unit_requested_id: event.target.value,
    }));
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  // const searchParams = new URLSearchParams(location.search);
  // const isReadMode = searchParams.get("isReadMode") === "true";
  // const locationSearch = location.search;
  console.log(locationSearch.search);
  if (locationSearch.search === "?mode=read") {
    console.log("nesto");
  }
  const read = locationSearch.search;

  return (
    <Layout>
      <div>
        <ButtonBack />
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          {isEditMode ? `Inquiry no: ${data.id}` : "Inquiry"}
        </Typography>

        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="event_type"
              label="Event Type"
              required
              value={data.event_type}
              fullWidth
              onChange={handleInputChange}
              disabled={read === "?mode=read"}
            />
          </div>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginBottom: "15px",
            }}
          >
            <div>
              <InputLabel
                htmlFor="arrive-on"
                style={{
                  marginBottom: "-15px",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "600",
                  color: "#49559d",
                }}
              >
                Arrive On
              </InputLabel>
              <TextField
                sx={{ marginTop: "30px" }}
                id="arrive-on"
                name="arrive_on"
                value={data.arrive_on}
                type="date"
                onChange={handleInputChange}
                disabled={read === "?mode=read"}
              />
            </div>

            <div>
              <InputLabel
                htmlFor="depart-on"
                style={{
                  marginBottom: "-15px",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "600",
                  color: "#49559d",
                }}
              >
                Depart On
              </InputLabel>
              <TextField
                sx={{ marginTop: "30px" }}
                id="depart-on"
                name="depart_on"
                value={data.depart_on}
                type="date"
                onChange={handleInputChange}
                disabled={read === "?mode=read"}
              />
            </div>
          </Box>

          <Box style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <label style={{ fontWeight: "600", color: "#49559d" }}>
                Are Your Event dates Flex
              </label>
              <Switch
                name="are_your_event_dates_flex"
                checked={data.are_your_event_dates_flex}
                onChange={(event) => {
                  const newValue = event.target.checked;
                  handleInputChange({
                    target: {
                      name: "are_your_event_dates_flex",
                      value: newValue,
                    },
                  });
                }}
                disabled={read === "?mode=read"}
              />
            </div>
          </Box>
          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="rooms_min"
              label="Rooms Min"
              value={data.rooms_min}
              type="number"
              fullWidth
              onChange={handleInputChange}
              disabled={read === "?mode=read"}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px", marginBottom: "20px" }}
              name="rooms_max"
              label="Rooms Max"
              value={data.rooms_max}
              type="number"
              fullWidth
              onChange={handleInputChange}
              disabled={read === "?mode=read"}
            />
          </div>

          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <label style={{ fontWeight: "600", color: "#49559d" }}>
                Do You Need Meeting Rooms
              </label>
              <Switch
                name="do_you_need_meeting_rooms"
                checked={data.do_you_need_meeting_rooms}
                onChange={(event) => {
                  const newValue = event.target.checked;
                  handleInputChange({
                    target: {
                      name: "do_you_need_meeting_rooms",
                      value: newValue,
                    },
                  });
                }}
                disabled={read === "?mode=read"}
              />
            </div>
          </Box>
          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="attendees_per_day"
              label="Attendees Per Day"
              value={data.attendees_per_day}
              type="number"
              fullWidth
              onChange={handleInputChange}
              disabled={read === "?mode=read"}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="tell_us_more"
              label="Tell Us More"
              value={data.tell_us_more}
              fullWidth
              onChange={handleInputChange}
              disabled={read === "?mode=read"}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="first_name"
              label="First Name"
              value={data.first_name}
              fullWidth
              onChange={handleInputChange}
              disabled={read === "?mode=read"}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="last_name"
              label="Last Name"
              value={data.last_name}
              fullWidth
              onChange={handleInputChange}
              disabled={read === "?mode=read"}
            />
          </div>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <div>
              <label
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "600",
                  color: "#49559d",
                }}
              >
                Gender
              </label>
              <RadioGroup
                name="gender"
                value={data.gender}
                onChange={handleInputChange}
                row
              >
                <FormControlLabel
                  value="Male"
                  control={<Radio />}
                  label="Male"
                  disabled={read === "?mode=read"}
                />
                <FormControlLabel
                  value="Female"
                  control={<Radio />}
                  label="Female"
                  disabled={read === "?mode=read"}
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio />}
                  label="Other"
                  disabled={read === "?mode=read"}
                />
              </RadioGroup>
            </div>
          </Box>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="country"
              label="Country"
              value={data.country}
              fullWidth
              onChange={handleInputChange}
              disabled={read === "?mode=read"}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="email"
              label="Email"
              value={data.email}
              type="email"
              fullWidth
              onChange={handleInputChange}
              disabled={read === "?mode=read"}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="industry"
              label="Industry"
              value={data.industry}
              fullWidth
              onChange={handleInputChange}
              disabled={read === "?mode=read"}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px", marginBottom: "15px" }}
              name="phone"
              label="Phone"
              value={data.phone}
              fullWidth
              onChange={handleInputChange}
              disabled={read === "?mode=read"}
            />
          </div>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
              <InputLabel id="demo-simple-select-label">Venues</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.venues}
                label="VenueTypes"
                multiple
                fullWidth
                onChange={handleVenue}
                disabled={read === "?mode=read"}
              >
                {venueRequested?.map((list) => (
                  <MenuItem key={list.id} value={list.id}>
                    {list.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
              <InputLabel id="demo-simple-select-label">Venue Units</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.unit_requested_id}
                label="VenueTypes"
                fullWidth
                onChange={handleVenueUnit}
                disabled={read === "?mode=read"}
              >
                {unitRequested?.map((list) => (
                  <MenuItem key={list.id} value={list.id}>
                    {list.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
              disabled={read === "?mode=read"}
            >
              {location === "add-new-venue-inquiries" ? "Publish" : "Save"}
            </Button>
          </Container>
        </form>
      </div>
    </Layout>
  );
};

export default AddEditVenueInquiry;
