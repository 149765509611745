import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container, Box } from "@mui/material";
import Layout from "../../../layout/Layout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addSocialMedia,
  editSocialMedia,
  getSocialMedia,
} from "../../../services/coworking/SocialMedia";

const AddEditCoworkingSocialMedia = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);
  const [data, setData] = useState({
    title: "",
    icon: "",
    icon_alt_text: "",
    link: "",
  });

  const isEditMode =
    location === "add-new-coworking-social-media" ? false : true;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEditMode) {
      editSocialMedia(id, data)
        .then(() => {
          navigate("/coworking-social-media");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju kategorije:", error);
        });
    } else {
      addSocialMedia(data)
        .then(() => {
          navigate("/coworking-social-media");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju kategorije:", error);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      getSocialMedia(id)
        .then((data) => {
          setData({
            title: data.title,
            icon: data.icon,
            icon_alt_text: data.icon_alt_text,
            link: data.link,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  return (
    <Layout>
      <div>
        <Box style={{ display: "flex" }}>
          <Link to="/coworking-social-media" style={{ textDecoration: "none" }}>
            <Button variant="outlined" onClick={handleBack}>
              <ArrowBackIcon />
              Back
            </Button>
          </Link>
        </Box>
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Coworking Social Media
        </Typography>

        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="title"
              label="Title"
              fullWidth
              value={data.title}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="icon"
              label="Icon"
              fullWidth
              value={data.icon}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="icon_alt_text"
              label="Icon Alt Text"
              fullWidth
              value={data.icon_alt_text}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="link"
              label="Link"
              fullWidth
              value={data.link}
              onChange={handleInputChange}
            />
          </div>

          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              {location === "add-new-coworking-social-media"
                ? "Publish"
                : "Save"}
            </Button>
          </Container>
        </form>
      </div>
    </Layout>
  );
};

export default AddEditCoworkingSocialMedia;
