import axios from "axios";
import { API_URL } from "../const";

export const fetchMeetingRooms = async () => {
  return axios
    .get(`${API_URL}/coworking/meeting-room-capacities`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteMeetingRoom = async (id) => {
  return axios
    .delete(`${API_URL}/coworking/meeting-room-capacities/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const addMeetingRoom = async (data) => {
  return axios
    .post(`${API_URL}/coworking/meeting-room-capacities`, data)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getMeetingRoom = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/coworking/meeting-room-capacities/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editMeetingRoom = async (id, data) => {
  try {
    const response = await axios.put(`${API_URL}/coworking/meeting-room-capacities/${id}`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
