import React from "react";
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { Link } from "react-router-dom";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const GuestRoomInfo = ({
  toggleGuestRoomForm,
  guestRoomList,
  handleDeleteGuestRoom,
  isDraggingGuestRoom,
  handleDragEndGuestRoom,
  handleDragStartGuestRoom,
  toggleImageForm,
  guestRoomImagesPivot,
  selectedGuestRoomId,
  setSelectedGuestRoomId,
  filesList,
  handleDeleteImageGuestRoom,
}) => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        margin: "30px auto",
      }}
    >
      {isDraggingGuestRoom}
      <DragDropContext onDragEnd={handleDragEndGuestRoom}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Move</TableCell>
              <TableCell>Order</TableCell>
              <TableCell>Icon</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Action Buttons</TableCell>
            </TableRow>
          </TableHead>
          <Droppable droppableId="droppable-1">
            {(provided, snapshot) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {guestRoomList
                  ?.sort((a, b) => a.order - b.order)
                  .map((room, index) => (
                    <Draggable
                      key={room.title}
                      draggableId={room.title}
                      index={index}
                      onStart={handleDragStartGuestRoom}
                    >
                      {(provided, snapshot) => (
                        <>
                          <TableRow
                            key={room.title}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TableCell component="th" scope="row">
                              <ControlCameraIcon />
                            </TableCell>
                            <TableCell>{room.order}</TableCell>
                            <TableCell>
                              <img
                                src={room.icon}
                                alt="icon"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </TableCell>
                            <TableCell>{room.title}</TableCell>
                            <TableCell>{room.amount}</TableCell>
                            <TableCell>
                              <Link
                                to={{
                                  pathname: `/edit-type-of-guest-room/${room.id}`,
                                  state: { isEditMode: true },
                                }}
                              >
                                <Button variant="outlined">Edit Item</Button>
                              </Link>
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleDeleteGuestRoom(room.id)}
                              >
                                Delete
                              </Button>

                              <IconButton
                                onClick={() =>
                                  setSelectedGuestRoomId(
                                    selectedGuestRoomId === room.id
                                      ? null
                                      : room.id
                                  )
                                }
                              >
                                {selectedGuestRoomId === room.id ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={6}>
                              {selectedGuestRoomId === room.id && (
                                <Box>
                                  <Container
                                    style={{
                                      position: "relative",
                                      top: "0",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        color: "#49559d",
                                        fontWeight: "400",
                                        fontSize: "24px",
                                      }}
                                    >
                                      Images
                                    </Typography>
                                    <Button
                                      variant="outlined"
                                      style={{
                                        marginBottom: "20px",
                                        margin: "20px auto",
                                      }}
                                      onClick={() =>
                                        toggleImageForm(room.id, "guestRoom")
                                      }
                                    >
                                      Add images
                                    </Button>
                                  </Container>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      padding: "10px",
                                    }}
                                  >
                                    {guestRoomImagesPivot.map((image) => {
                                      const matchedFile = filesList.find(
                                        (file) =>
                                          file.id === image.image_id &&
                                          room.id === image.guest_room_id
                                      );
                                      if (matchedFile) {
                                        return (
                                          <div
                                            key={matchedFile.id}
                                            style={{
                                              padding: "10px",
                                            }}
                                          >
                                            <Box
                                              style={{
                                                backgroundColor: "#F0F0F0",
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: "10px",
                                                borderRadius: "10px",
                                                boxShadow:
                                                  "rgba(0, 0, 0, 0.3) 1px 2px 4px",
                                              }}
                                            >
                                              <img
                                                src={matchedFile.path}
                                                alt={matchedFile.alt_image_me}
                                                style={{
                                                  width: "170px",
                                                  height: "150px",
                                                  borderRadius: "10px",
                                                }}
                                              />
                                              <IconButton
                                                color="error"
                                                onClick={() =>
                                                  handleDeleteImageGuestRoom(
                                                    room.id,
                                                    matchedFile.id
                                                  )
                                                }
                                              >
                                                <DeleteForeverIcon />
                                              </IconButton>
                                            </Box>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                </Box>
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "30px",
        }}
      >
        <Button
          variant="outlined"
          style={{ margin: "30px auto" }}
          onClick={toggleGuestRoomForm}
        >
          Create New Guest Room
        </Button>
      </Container>
    </Container>
  );
};

export default GuestRoomInfo;
