import axios from "axios";
import { API_URL } from "../const";

export const fetchAmenitiesSections = async () => {
  return axios
    .get(`${API_URL}/coworking/unit-amenity-section`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteAmenitiesSection = async (id) => {
  return axios
    .delete(`${API_URL}/coworking/unit-amenity-section/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const addAmenitiesSection = async (data) => {
  return axios
    .post(`${API_URL}/coworking/unit-amenity-section`, data)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getAmenitiesSection = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/coworking/unit-amenity-section/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editAmenitiesSection = async (id, data) => {
  try {
    const response = await axios.put(`${API_URL}/coworking/unit-amenity-section/${id}`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
