import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../../../layout/Layout";
import VenueMenu from "../../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addMeetingRoomCapacity,
  getMeetingRoomCapacity,
  editMeetingRoomCapacity,
} from "../../../../services/venues/unitMeetingRoomCapacitiesAPI";
import ButtonBack from "../../../../components/ButtonBack";
import IconModal from "../../../../components/Venue/Modals/IconModal";

const AddEditVenueUnitMeetingRoomCapacities = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  const isEditMode =
    location === "add-new-venue-meeting-room-capacities" ? false : true;

  const [meetingRoomCapacityData, setMeetingRoomCapacityData] = useState({
    icon: "",
    iconAltText: "",
    title: "",
    size: "",
  });

  useEffect(() => {
    if (id) {
      getMeetingRoomCapacity(id)
        .then((data) => {
          setMeetingRoomCapacityData({
            icon: data.icon,
            iconAltText: data.icon_alt_text,
            title: data.title,
            size: data.size,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setMeetingRoomCapacityData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newUnitPhotoGalleryData = {
      icon: meetingRoomCapacityData.icon,
      icon_alt_text: meetingRoomCapacityData.iconAltText,
      title: meetingRoomCapacityData.title,
      size: meetingRoomCapacityData.size,
    };
    if (isEditMode) {
      editMeetingRoomCapacity(id, newUnitPhotoGalleryData)
        .then((response) => {
          setMeetingRoomCapacityData({
            icon: "",
            iconAltText: "",
            title: "",
            size: "",
          });

          navigate("/venue-meeting-room-capacities");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addMeetingRoomCapacity(newUnitPhotoGalleryData)
        .then((response) => {
          setMeetingRoomCapacityData({
            ...meetingRoomCapacityData,
            icon: response.icon,
            iconAltText: response.icon_alt_text,
            title: response.title,
            size: response.size,
          });
          navigate("/venue-meeting-room-capacities");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Venue Unit Meeting Room Capacities
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <IconModal
                  setData={setMeetingRoomCapacityData}
                  data={meetingRoomCapacityData.icon}
                  handleInputChange={handleInputChange}
                  setImageModalOpen={setImageModalOpen}
                  isImageModalOpen={isImageModalOpen}
                />
                <Container style={{ marginTop: "30px" }}>
                  <TextField
                    name="iconAltText"
                    label={isImageModalOpen ? "" : "Alt Text"}
                    value={meetingRoomCapacityData.iconAltText}
                    fullWidth
                    onChange={handleInputChange}
                  />

                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="title"
                    label={isImageModalOpen ? "" : "Title"}
                    required
                    value={meetingRoomCapacityData.title}
                    fullWidth
                    onChange={handleInputChange}
                  />

                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="size"
                    label={isImageModalOpen ? "" : "Size"}
                    required
                    value={meetingRoomCapacityData.size}
                    type="number"
                    fullWidth
                    onChange={handleInputChange}
                  />
                </Container>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-venue-meeting-room-capacities"
                    ? "Publish"
                    : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditVenueUnitMeetingRoomCapacities;
