import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import SearchComponent from "../../../components/SearchComponent";
import { Link } from "react-router-dom";

import TableChartIcon from "@mui/icons-material/TableChart";
import Pagination from "@mui/material/Pagination";

import {
  fetchAmenitiesSections,
  deleteAmenitiesSection,
} from "../../../services/venues/amenitasSectionAPI";

export default function Amenities() {
  const [amenitiesSections, setAmenitiesSections] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("title");
  const [isTabularView, setIsTabularView] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [amenitiesSectionIdToDelete, setAmenitiesSectionIdToDelete] =
    useState(null);
  const itemsPerPage = 6;

  useEffect(() => {
    fetchAmenitiesSections()
      .then((data) => {
        setAmenitiesSections(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDelete = () => {
    if (amenitiesSectionIdToDelete) {
      deleteAmenitiesSection(amenitiesSectionIdToDelete)
        .then(() => {
          setDeleteDialogOpen(false);
          setAmenitiesSections((prevAmenitiesSections) =>
            prevAmenitiesSections.filter(
              (amenitiesSection) =>
                amenitiesSection.id !== amenitiesSectionIdToDelete
            )
          );
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };

  const handleDeleteButtonClicked = (amenitiesSectionId) => {
    setDeleteDialogOpen(true);
    setAmenitiesSectionIdToDelete(amenitiesSectionId);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleTabularViewToggle = () => {
    setIsTabularView(!isTabularView);
  };

  const filteredData = amenitiesSections.filter((item) => {
    if (searchBy === "title") {
      return item.title.toLowerCase().includes(searchValue.toLowerCase());
    }
    return true;
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div style={{ width: "73%", marginLeft: "26%" }}>
          <div>
            <div>
              <SearchComponent
                searchValue={searchValue}
                searchBy={searchBy}
                onSearchInputChange={handleSearchInputChange}
                onSearchByChange={handleSearchByChange}
              />
            </div>

            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Amenities
            </Typography>

            <div>
              <Link
                to={{
                  pathname: "/add-new-amenities",
                  state: { isEditMode: false },
                }}
              >
                <Button variant="outlined">Add New</Button>
              </Link>

              <Button
                startIcon={<TableChartIcon />}
                onClick={handleTabularViewToggle}
              ></Button>
            </div>

            {filteredData.length === 0 ? (
              <Typography
                variant="h6"
                style={{
                  marginTop: "20px",
                  color: "red",
                  textAlign: "center",
                }}
              >
                There are no results
              </Typography>
            ) : (
              ""
            )}

            {!isTabularView ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.reverse().map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>
                          <div>
                            <Link
                              to={{
                                pathname: `/edit-amenities/${item.id}`,
                                state: { isEditMode: true },
                              }}
                            >
                              <Button variant="outlined">Edit Item</Button>
                            </Link>
                            <Button
                              variant="outlined"
                              onClick={() => handleDeleteButtonClicked(item.id)}
                            >
                              Delete
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Grid container spacing={2}>
                {paginatedData.reverse().map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" component="h2">
                          {item.title}
                        </Typography>
                        <Link
                          to={{
                            pathname: `/edit-amenities/${item.id}`,
                            state: { isEditMode: true },
                          }}
                        >
                          <Button variant="outlined">Edit item</Button>
                        </Link>
                        <Button
                          variant="outlined"
                          onClick={() => handleDeleteButtonClicked(item.id)}
                        >
                          Delete
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}

            <Pagination
              page={currentPage}
              onChange={handlePageChange}
              style={{ marginTop: "1rem", float: "left" }}
              count={Math.ceil(filteredData.length / itemsPerPage)}
            />

            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
              <DialogTitle>Delete Confirmation</DialogTitle>
              <DialogContent>
                <Typography>
                  Are you sure you want to delete this amenities section?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeleteDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  open={deleteDialogOpen}
                  onClose={() => setDeleteDialogOpen(false)}
                  onClick={handleDelete}
                  color="error"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </Layout>
  );
}
