import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../layout/Layout";
import VenueMenu from "../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addStyle, getStyle, editStyle } from "../../services/styleAPI";
import ButtonBack from "../../components/ButtonBack";

const AddEditStyles = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode = location === "add-new-styles" ? false : true;

  const [citiesData, setCitiesData] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    if (id) {
      getStyle(id)
        .then((data) => {
          setCitiesData({
            name: data.name,
            description: data.description,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCitiesData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isEditMode) {
      editStyle(id, citiesData)
        .then(() => {
          navigate("/styles");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addStyle(citiesData)
        .then(() => {
          navigate("/styles");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <ButtonBack />
        <div style={{ marginLeft: "26%" }}>
          <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
            Style
          </Typography>

          <form onSubmit={handleSubmit}>
            <Container>
              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="name"
                  label="Name"
                  required
                  value={citiesData.name}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="description"
                  label="Description"
                  value={citiesData.description}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>
            </Container>
            <Container
              sx={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                sx={{ m: 1, width: "25ch" }}
                type="submit"
                size="medium"
                variant="outlined"
              >
                {location === "add-new-styles" ? "Publish" : "Save"}
              </Button>
            </Container>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditStyles;
