import React, { useState, useEffect } from "react";
import {
  Skeleton,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Layout from "../../layout/Layout";
import { Link } from "react-router-dom";
import TableChartIcon from "@mui/icons-material/TableChart";
import Pagination from "@mui/material/Pagination";
import {
  fetchVenues,
  deleteVenue,
  editVenue,
} from "../../services/venues/venueAPI";

import VenueMenu from "../../components/VenueMenu";
import SearchComponent from "../../components/SearchComponent";

import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

const Venues = () => {
  const [venues, setVenues] = useState([]);
  const [VenueIdToDelete, setVenueIdToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("name");
  const [isTabularView, setIsTabularView] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const [icon, setIcon] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const [loading, setLoading] = useState(true);

  const fetchAndSetVenues = () => {
    fetchVenues()
      .then((data) => {
        setVenues(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // useEffect to fetch venues initially
  useEffect(() => {
    fetchAndSetVenues();
  }, []);

  const handleDelete = () => {
    if (VenueIdToDelete) {
      deleteVenue(VenueIdToDelete)
        .then(() => {
          setDeleteDialogOpen(false);
          setVenues((prevVenues) =>
            prevVenues.filter((venue) => venue.id !== VenueIdToDelete)
          );
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };

  const handleDeleteButtonClicked = (venueId) => {
    setDeleteDialogOpen(true);
    setVenueIdToDelete(venueId);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleTabularViewToggle = () => {
    setIsTabularView(!isTabularView);
  };

  const sortedData = venues
    .slice()
    .sort((a, b) => a.order_venue_in_list - b.order_venue_in_list);

  const filteredData = sortedData.filter((item) => {
    if (searchBy === "name") {
      return item.name.toLowerCase().includes(searchValue.toLowerCase());
    }
    if (searchBy === "city") {
      return item.city.toLowerCase().includes(searchValue.toLowerCase());
    }
    return true;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginatedData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = async (e) => {
    if (!e.destination) return;
    setIsDragging(false);

    const arrowRight = (
      <ArrowForwardIosIcon
        style={{
          position: "absolute",
          top: "50%",
          right: "20px",
          zIndex: "100",
        }}
      />
    );

    setIcon(arrowRight);

    const tempData = Array.from(filteredData);
    const [orderVenueInList] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, orderVenueInList);

    try {
      setLoading(true);
      for (let i = 0; i < tempData.length; i++) {
        const venue = tempData[i];
        const updatedVenueData = {
          order_venue_in_list: i + 1,
          name: venue.name,
          city: venue.city,
          address: venue.address,
          slug: venue.slug,
        };
        await editVenue(venue.id, updatedVenueData);
      }
      fetchAndSetVenues();
      console.log("Baza podataka je ažurirana sa novim redosledom venue-a.");
    } catch (error) {
      console.error("Greška prilikom ažuriranja baze podataka:", error.message);
    }
  };

  const toggleVisibility = async (venueId) => {
    try {
      const venue = venues.find((v) => v.id === venueId);
      const updatedVenueData = {
        ...venue,
        is_public: !venue.is_public,
      };
      await editVenue(venueId, updatedVenueData);
      console.log(venues);
      fetchAndSetVenues();
    } catch (error) {
      console.error("Greška prilikom ažuriranja venue-a:", error.message);
      fetchAndSetVenues();
    }
  };

  return (
    <Layout>
      {isDragging && icon}
      <div>
        <VenueMenu />
        <div style={{ width: "73%", marginLeft: "26%" }}>
          <div>
            <div>
              <SearchComponent
                searchValue={searchValue}
                searchBy={searchBy}
                onSearchInputChange={handleSearchInputChange}
                onSearchByChange={handleSearchByChange}
              />
            </div>

            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Venue
            </Typography>

            <div style={{ marginBottom: "30px" }}>
              <Link
                to={{
                  pathname: "/add-new-venue",
                  state: { isEditMode: false },
                }}
              >
                <Button variant="outlined">Add New</Button>
              </Link>

              <Button
                startIcon={<TableChartIcon />}
                onClick={handleTabularViewToggle}
              ></Button>
            </div>
            {loading ? (
              <>
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height={70}
                  sx={{ bgcolor: "#49559d40" }}
                  style={{ padding: "0 10px", marginBottom: "5px" }}
                />
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height={70}
                  sx={{ bgcolor: "#49559d40" }}
                  style={{ padding: "0 10px", marginBottom: "5px" }}
                />
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height={70}
                  sx={{ bgcolor: "#49559d40" }}
                  style={{ padding: "0 10px", marginBottom: "5px" }}
                />
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height={70}
                  sx={{ bgcolor: "#49559d40" }}
                  style={{ padding: "0 10px", marginBottom: "5px" }}
                />
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height={70}
                  sx={{ bgcolor: "#49559d40" }}
                  style={{ padding: "0 10px", marginBottom: "5px" }}
                />
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height={70}
                  sx={{ bgcolor: "#49559d40" }}
                  style={{ padding: "0 10px" }}
                />
              </>
            ) : (
              <>
                {filteredData.length === 0 ? (
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: "20px",
                      color: "red",
                      textAlign: "center",
                    }}
                  >
                    There are no results
                  </Typography>
                ) : (
                  ""
                )}

                {isTabularView ? (
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Move</TableCell>
                          <TableCell>Order</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>City</TableCell>
                          <TableCell>Actions</TableCell>
                          <TableCell>Visibility</TableCell>
                        </TableRow>
                      </TableHead>
                      <Droppable droppableId="droppable-1">
                        {(provider) => (
                          <TableBody
                            ref={provider.innerRef}
                            {...provider.droppableProps}
                          >
                            {paginatedData.map((row, index) => (
                              <Draggable
                                key={row.name}
                                draggableId={row.name}
                                index={index}
                                onStart={handleDragStart}
                              >
                                {(provided, snapshot) => (
                                  <TableRow
                                    key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <TableCell component="th" scope="row">
                                      <ControlCameraIcon />
                                    </TableCell>
                                    <TableCell>
                                      {row.order_venue_in_list}
                                    </TableCell>
                                    <TableCell>
                                      {row.is_public ? (
                                        <span>{row.name}</span>
                                      ) : (
                                        <span
                                          style={{
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          {row.name}
                                        </span>
                                      )}
                                    </TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell>
                                      {row.cities && row.cities.length > 0
                                        ? row.cities[0].name
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                      <div>
                                        <Link
                                          to={{
                                            pathname: `/edit-venue/${row.id}`,
                                            state: { isEditMode: true },
                                          }}
                                        >
                                          <Button variant="outlined">
                                            Edit Venue
                                          </Button>
                                        </Link>
                                        <Button
                                          variant="outlined"
                                          onClick={() =>
                                            handleDeleteButtonClicked(row.id)
                                          }
                                        >
                                          Delete
                                        </Button>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <IconButton
                                        onClick={() => toggleVisibility(row.id)}
                                      >
                                        {row.is_public ? (
                                          <VisibilityIcon color="primary" />
                                        ) : (
                                          <VisibilityOffIcon color="error" />
                                        )}
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            ))}
                            {provider.placeholder}
                          </TableBody>
                        )}
                      </Droppable>
                    </Table>
                  </DragDropContext>
                ) : (
                  <Grid container spacing={2}>
                    {paginatedData.reverse().map((item) => (
                      <Grid item xs={12} sm={6} md={4} key={item.id}>
                        <Card>
                          <CardContent>
                            <Typography variant="h5" component="h2">
                              {item.name}
                            </Typography>
                            <Typography color="textSecondary">
                              {item.description}
                            </Typography>
                            <Typography color="textSecondary">
                              City: {item.city}
                            </Typography>
                            <Link
                              to={{
                                pathname: `/edit-venue/${item.id}`,
                                state: { isEditMode: true },
                              }}
                            >
                              <Button variant="outlined">Edit Venue</Button>
                            </Link>
                            <Button
                              variant="outlined"
                              onClick={() => handleDeleteButtonClicked(item.id)}
                            >
                              Delete
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </>
            )}
            <Pagination
              count={Math.ceil(filteredData.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              style={{ margin: "1rem", float: "left" }}
            />
            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
              <DialogTitle>Delete Confirmation</DialogTitle>
              <DialogContent>
                <Typography>
                  Are you sure you want to delete this Venue?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeleteDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  open={deleteDialogOpen}
                  onClose={() => setDeleteDialogOpen(false)}
                  onClick={handleDelete}
                  color="error"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Venues;
