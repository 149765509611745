import axios from "axios";
import { API_URL } from "./venues/apiUrl";

export const fetchCategories = () => {
  return axios
    .get(`${API_URL}/getAllCategories`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteCategory = (id) => {
  return axios
    .delete(`${API_URL}/deleteCategorie/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju kategorije: ${error.message}`);
    });
};

export const addCategory = (data) => {
  return axios
    .post(`${API_URL}/storeCategorie`, data)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju kategorije: ${error.message}`);
    });
};

export const getCategory = async (id) => {
  return axios
    .get(`${API_URL}/showCategorie/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const editCategory = async (id, data) => {
  try {
    const response = await axios.put(`${API_URL}/updateCategorie/${id}`, data);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju kategorije: ${error.message}`);
  }
};
