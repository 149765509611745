import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Container,
} from "@mui/material";
import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addAmenitiesSection,
  getAmenitiesSection,
  editAmenitiesSection,
} from "../../../services/venues/amenitasSectionAPI";
import { fetchAmenitiesLists } from "../../../services/venues/amenitiesListAPI";
import ButtonBack from "../../../components/ButtonBack";

const AddEditAmenities = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode = location === "add-new-amenities" ? false : true;

  const [amenitiesSectionData, setAmenitiesSectionData] = useState({
    title: "",
    amenities_list_ids: [],
  });

  const [amenitiesList, setAmenitiesList] = useState([]);

  useEffect(() => {
    fetchAmenitiesLists()
      .then((data) => {
        setAmenitiesList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (id) {
      getAmenitiesSection(id)
        .then((data) => {
          setAmenitiesSectionData({
            title: data.title,
            amenities_list_ids: data.amenities
              ? data.amenities?.map((list) => list.id)
              : [],
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAmenitiesSectionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isEditMode) {
      editAmenitiesSection(id, amenitiesSectionData)
        .then(() => {
          navigate("/amenities");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addAmenitiesSection(amenitiesSectionData)
        .then(() => {
          navigate("/amenities");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  const handleAmenities = (event) => {
    setAmenitiesSectionData((prevData) => ({
      ...prevData,
      amenities_list_ids: event.target.value,
    }));
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Amenities
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="title"
                    label="Title"
                    required
                    value={amenitiesSectionData.title}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                    <InputLabel id="demo-simple-select-label">
                      Amenities List
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiple
                      value={amenitiesSectionData.amenities_list_ids}
                      label="VenueTypes"
                      onChange={handleAmenities}
                    >
                      {amenitiesList?.map((list) => (
                        <MenuItem key={list.id} value={list.id}>
                          {list.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-amenities" ? "Publish" : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditAmenities;
