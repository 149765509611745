import Cookies from "js-cookie";
import { API_URL } from "./venues/apiUrl";

//export const API_URL = "https://127.0.0.1:8000/api";

// export const API_URL = "https://montenegromice.com/auth-api";

export const handleLogout = async (navigate) => {
  try {
    const token = Cookies.get("token");
    const response = await fetch(`${API_URL}/logout`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      Cookies.remove("token");
      navigate("/login");
    } else {
      console.error("Failed to log out");
    }
  } catch (error) {
    console.error("An error occurred while logging out:", error);
  }
};
