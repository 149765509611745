import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import cookie from "js-cookie";

const PrivateRoute = () => {
  const token = cookie.get("token"); // Retrieve the token from session storage

  // If the token exists and is valid, return an outlet that will render child elements
  // If not, return an element that will navigate to the login page
  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
