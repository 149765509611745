import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Box,
} from "@mui/material";
import Layout from "../../../layout/Layout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addDistanceSection,
  editDistanceSection,
  getDistanceSection,
} from "../../../services/coworking/distanceSectionAPI";
import { fetchDistanceLists } from "../../../services/coworking/distanceListAPI";
import OutlinedInput from "@mui/material/OutlinedInput";

const AddEditCoworkingDistanceSection = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);
  const [data, setData] = useState({
    icon: "",
    icon_alt_text: "",
    distance_section_title: "",
    description: "",
    lists: [],
  });
  const [distanceLists, setDistanceLists] = useState([]);

  const isEditMode =
    location === "add-new-coworking-distance-section" ? false : true;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEditMode) {
      editDistanceSection(id, data)
        .then(() => {
          navigate("/coworking-distance-section");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju kategorije:", error);
        });
    } else {
      addDistanceSection(data)
        .then(() => {
          navigate("/coworking-distance-section");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju kategorije:", error);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchDistanceLists().then((data) => {
      setDistanceLists(data);
    });
  }, []);

  useEffect(() => {
    if (id) {
      getDistanceSection(id)
        .then((data) => {
          setData({
            icon: data.icon,
            icon_alt_text: data.icon_alt_text,
            distance_section_title: data.distance_section_title,
            description: data.description,
            lists: data.lists ? data.lists?.map((list) => list.id) : [],
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleSelectDistanceLists = (event) => {
    setData((prevData) => ({
      ...prevData,
      lists: event.target.value,
    }));
  };

  return (
    <Layout>
      <div>
        <Box style={{ display: "flex" }}>
          <Link
            to="/coworking-distance-section"
            style={{ textDecoration: "none" }}
          >
            <Button variant="outlined" onClick={handleBack}>
              <ArrowBackIcon />
              Back
            </Button>
          </Link>
        </Box>
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Coworking Distance Section
        </Typography>

        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="icon"
              label="Icon"
              fullWidth
              value={data.icon}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="icon_alt_text"
              label="Icon Alt Text"
              fullWidth
              value={data.icon_alt_text}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="distance_section_title"
              label="Title"
              fullWidth
              value={data.distance_section_title}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="description"
              label="Description"
              multiline
              rows={6}
              fullWidth
              value={data.description}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <FormControl sx={{ marginTop: "30px" }} fullWidth>
              <InputLabel id="demo-multiple-name-label">
                Coworking Distance List
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={data.lists}
                onChange={handleSelectDistanceLists}
                input={<OutlinedInput label="Name" />}
              >
                {distanceLists.map((list) => (
                  <MenuItem key={list.id} value={list.id}>
                    {list.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              {location === "add-new-coworking-distance-section"
                ? "Publish"
                : "Save"}
            </Button>
          </Container>
        </form>
      </div>
    </Layout>
  );
};

export default AddEditCoworkingDistanceSection;
