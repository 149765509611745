import React from "react";
import { TextField, Container, Box } from "@mui/material";

const SeoInfo = ({ venueData, handleInputChange }) => {
  return (
    <Container>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="meta_title"
          id="outlined-number"
          label="Meta Title"
          type="text"
          value={venueData.meta_title}
          onChange={handleInputChange}
        />
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="meta_keyword"
          id="outlined-number"
          label="Meta Keyword"
          type="text"
          fullWidth
          value={venueData.meta_keyword}
          onChange={handleInputChange}
        />
      </Box>
      <TextField
        sx={{ marginTop: "30px" }}
        name="meta_description"
        id="outlined-number"
        label="Meta Description"
        type="text"
        multiline
        rows={3}
        fullWidth
        value={venueData.meta_description}
        onChange={handleInputChange}
      />
    </Container>
  );
};

export default SeoInfo;
