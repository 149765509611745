import axios from "axios";
import { API_URL } from "./const";

export const fetchDistanceLists = async () => {
  return axios
    .get(`${API_URL}/coworking/distance-list`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteDistanceList = async (distanceListId) => {
  return axios
    .delete(`${API_URL}/coworking/distance-list/${distanceListId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const addDistanceList = async (distanceListData) => {
  return axios
    .post(`${API_URL}/coworking/distance-list`, distanceListData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getDistanceList = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/coworking/distance-list/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editDistanceList = async (id, distanceListData) => {
  try {
    const response = await axios.put(`${API_URL}/coworking/distance-list/${id}`, distanceListData);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
