import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../../../layout/Layout";
import VenueMenu from "../../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addDistanceList,
  getDistanceList,
  editDistanceList,
} from "../../../../services/venues/distanceListAPI";
import ButtonBack from "../../../../components/ButtonBack";

const AddEditVenueDistanceList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);
  console.log(location);

  const isEditMode = location === "add-new-venue-distance-list" ? false : true;

  const [distanceListData, setDistanceListData] = useState({
    title: "",
    distanceAmount: "",
  });

  useEffect(() => {
    if (id) {
      getDistanceList(id)
        .then((data) => {
          setDistanceListData({
            title: data.title,
            distanceAmount: data.distance_amount,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDistanceListData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newDistanceListData = {
      title: distanceListData.title,
      distance_amount: distanceListData.distanceAmount,
    };
    if (isEditMode) {
      editDistanceList(id, newDistanceListData)
        .then((response) => {
          setDistanceListData({
            title: "",
            distanceAmount: "",
          });

          navigate("/venue-distance-list");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addDistanceList(newDistanceListData)
        .then((response) => {
          setDistanceListData({
            ...distanceListData,
            title: response.title,
            distanceAmount: response.distance_amount,
          });
          navigate("/venue-distance-list");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Distance
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="title"
                    label="Title"
                    required
                    value={distanceListData.title}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="distanceAmount"
                    label="Distance Amount"
                    value={distanceListData.distanceAmount}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-venue-distance-list"
                    ? "Publish"
                    : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditVenueDistanceList;
