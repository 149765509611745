import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = "https://montenegromice.com/mice-api";

export const fetchVenues = () => {
  return axios
    .get(`${API_URL}/venues`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteVenue = (venuesId) => {
  return axios
    .delete(`${API_URL}/venues/${venuesId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const addVenue = (venueData) => {
  return axios
    .post(`${API_URL}/venues`, venueData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getVenue = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/venues/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editVenue = async (id, venueData) => {
  try {
    const response = await axios.put(`${API_URL}/venues/${id}`, venueData);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
