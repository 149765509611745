import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchUnitPhotoGalleries = () => {
  return axios
    .get(`${API_URL}/venue-units-photo-gallery`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteUnitPhotoGallery = (unitsPhotoGalleryId) => {
  return axios
    .delete(`${API_URL}/venue-units-photo-gallery/${unitsPhotoGalleryId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju dokumenta: ${error.message}`);
    });
};

export const getUnitPhotoGallery = async (id) => {
  try {
    const response = await axios.get(
      `${API_URL}/venue-units-photo-gallery/${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju dokumenta: ${error.message}`);
  }
};

export const editUnitPhotoGallery = async (id, unitsPhotoGalleryData) => {
  try {
    const response = await axios.put(
      `${API_URL}/venue-units-photo-gallery/${id}`,
      unitsPhotoGalleryData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju dokumenta: ${error.message}`);
  }
};

export const addUnitPhotoGallery = (unitsPhotoGalleryData) => {
  return axios
    .post(`${API_URL}/venue-units-photo-gallery`, unitsPhotoGalleryData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju dokumenta: ${error.message}`);
    });
};
