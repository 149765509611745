import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchVenueVideoGalleries = () => {
  return axios
    .get(`${API_URL}/venue-video-gallery`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteVenueVideoGallery = (venueVideoGalleryId) => {
  return axios
    .delete(`${API_URL}/venue-video-gallery/${venueVideoGalleryId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju ${error.message}`);
    });
};

export const getVenueVideoGallery = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/venue-video-gallery/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju ${error.message}`);
  }
};

export const editVenueVideoGallery = async (id, venueVideoGalleryData) => {
  try {
    const response = await axios.put(
      `${API_URL}/venue-video-gallery/${id}`,
      venueVideoGalleryData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju ${error.message}`);
  }
};

export const addVenueVideoGallery = (venueVideoGalleryData) => {
  return axios
    .post(`${API_URL}/venue-video-gallery`, venueVideoGalleryData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju ${error.message}`);
    });
};
