import React, { useState, useEffect } from "react";

import { Typography, Box } from "@mui/material";

import { Link, useLocation } from "react-router-dom";

import { MuseumOutlined } from "@mui/icons-material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import CategoryIcon from "@mui/icons-material/Category";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import VerifiedIcon from "@mui/icons-material/Verified";
import ReviewsIcon from "@mui/icons-material/Reviews";
import ShareIcon from "@mui/icons-material/Share";
import InfoIcon from "@mui/icons-material/Info";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import EventIcon from "@mui/icons-material/Event";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import StyleIcon from "@mui/icons-material/Style";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import BurstModeIcon from "@mui/icons-material/BurstMode";

const pages = [
  {
    name: "Venues List",
    icon: <MuseumOutlined />,
    link: "/venues",
  },
  {
    name: "Venues Slider Order",
    icon: <BurstModeIcon />,
    link: "/venues-slider-order",
  },
  // {
  //   name: "Type Of Guest Room",
  //   icon: <BedroomParentIcon />,
  //   link: "/type-of-guest-room",
  //   subPages: [
  //     {
  //       name: "Guest Room Gallery",
  //       icon: <ImageIcon />,
  //       link: "/venue-guest-room-gallery",
  //     },
  //   ],
  // },
  {
    name: "Category",
    icon: <CategoryIcon />,
    link: "/venue-category",
  },
  // {
  //   name: "Distance",
  //   icon: <SpaceBarIcon />,
  //   link: "/distance",
  //   subPages: [
  //     {
  //       name: "Distance List",
  //       icon: <FormatListBulletedIcon />,
  //       link: "/venue-distance-list",
  //     },
  //   ],
  // },
  {
    name: "Documentation",
    icon: <AttachFileIcon />,
    link: "/documentation",
  },
  {
    name: "Testimonials",
    icon: <VerifiedIcon />,
    link: "/testimonials",
  },
  {
    name: "Reviews",
    icon: <ReviewsIcon />,
    link: "/reviews",
  },
  {
    name: "Social Media",
    icon: <ShareIcon />,
    link: "/social-media",
  },
  {
    name: "General Information",
    icon: <InfoIcon />,
    link: "/general-information",
  },
  {
    name: "Amenities",
    icon: <RoomServiceIcon />,
    link: "/amenities",
    subPages: [
      {
        name: "Amenities List",
        icon: <FormatListBulletedIcon />,
        link: "/venue-amenities-list",
      },
    ],
  },
  {
    name: "Type Of Events",
    icon: <EventIcon />,
    link: "/type-of-events",
  },
  {
    name: "Cities",
    icon: <LocationCityIcon />,
    link: "/cities",
  },
  {
    name: "Style",
    icon: <StyleIcon />,
    link: "/styles",
  },
  {
    name: "Unit Meeting Room Capacities",
    icon: <MeetingRoomIcon />,
    link: "/venue-meeting-room-capacities",
  },
  // {
  //   name: "Units Photo Gallery",
  //   icon: <ImageIcon />,
  //   link: "/venue-units-photo-gallery",
  // },
  {
    name: "Unit Exhibits",
    icon: <FormatListBulletedIcon />,
    link: "/venue-exhibits",
  },
  {
    name: "Units Amenities Section",
    icon: <RoomServiceIcon />,
    link: "/venue-units-amenities-section",
  },
  {
    name: "Units Amenities List",
    icon: <FormatListBulletedIcon />,
    link: "/venue-units-amenities-list",
  },
  {
    name: "Units Type",
    icon: <MergeTypeIcon />,
    link: "/unit-types",
  },
];

const VenueMenu = () => {
  const [active, setActive] = useState("");
  const [showSubLinks, setShowSubLinks] = useState("");
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const activePage = pages.find((page) => currentPath.startsWith(page.link));
    if (activePage) {
      setShowSubLinks(activePage.name);
      setActive(activePage.name);
    } else {
      setActive("");
    }
  }, [location]);

  const handleItemClick = (name) => {
    setActive(name);
    setShowSubLinks(name);
  };

  return (
    <div
      style={{
        width: "25%",
        height: "100%",
        borderRight: "1px solid #ccc",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
        marginTop: "-50px",
        position: "fixed",
        backgroundColor: "#49559d",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{ textAlign: "center", paddingTop: "20px", color: "white" }}
      >
        Venue Menu
      </Typography>
      <div
        style={{
          height: "82%",
          overflowY: "auto",
          borderTop: "1px solid #ccc",
        }}
      >
        <List>
          {pages.map((page) => (
            <div key={page.name}>
              <ListItem
                disablePadding
                onClick={() => handleItemClick(page.name)}
                style={{
                  backgroundColor: active === page.name ? "#e2e2e2" : "#49559d",
                  boxShadow:
                    active === page.name
                      ? "2px 2px 4px rgba(0, 0, 0, 0.3)"
                      : "",
                  borderRadius: "4px",
                }}
              >
                <Box style={{ width: "100%" }}>
                  <Link
                    to={page.link}
                    style={{
                      textDecoration: "none",
                      backgroundColor:
                        active === page.name ? "#e2e2e2" : "#49559d",
                      width: "100%",
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon
                        style={{
                          color: active === page.name ? "#49559d" : "#e2e2e2",
                        }}
                      >
                        {page.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={page.name}
                        style={{
                          color: active === page.name ? "#49559d" : "#e2e2e2",
                        }}
                      />
                      {page.subPages && showSubLinks === page.name && (
                        <MenuOpenIcon
                          style={{
                            color: active === page.name ? "#49559d" : "#e2e2e2",
                          }}
                        />
                      )}
                      {page.subPages && showSubLinks !== page.name && (
                        <MenuIcon
                          style={{
                            color: active === page.name ? "#49559d" : "#e2e2e2",
                          }}
                        />
                      )}
                    </ListItemButton>
                  </Link>
                </Box>
              </ListItem>
              {page.subPages && showSubLinks === page.name && (
                <div
                  style={{
                    border: "2px solid #e2e2e2",
                    marginTop: "2px",
                    marginLeft: "10%",
                    width: "80%",
                    backgroundColor: "#e2e2e2",
                    borderRadius: "5px",
                  }}
                >
                  {page.subPages.map((subPage) => (
                    <Link
                      to={subPage.link}
                      key={subPage.name}
                      style={{
                        textDecoration: "none",
                        width: "100%",
                        display: "block",
                        borderBottom: "2px solid #49559d",
                        borderRadius: "5px",
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon
                          style={{
                            color:
                              active === subPage.name ? "white" : "#49559d",
                          }}
                        >
                          {subPage.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={subPage.name}
                          style={{
                            color:
                              active === subPage.name ? "white" : "#49559d",
                          }}
                        />
                      </ListItemButton>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </List>
      </div>
    </div>
  );
};

export default VenueMenu;
