import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchCategories = () => {
  return axios
    .get(`${API_URL}/venue_categories`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteCategory = (categoryId) => {
  return axios
    .delete(`${API_URL}/venue_categories/${categoryId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju ${error.message}`);
    });
};

export const getCategory = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/venue_categories/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju ${error.message}`);
  }
};

export const editCategory = async (id, categoryData) => {
  try {
    const response = await axios.put(
      `${API_URL}/venue_categories/${id}`,
      categoryData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju ${error.message}`);
  }
};

export const addCategory = (categoryData) => {
  return axios
    .post(`${API_URL}/venue_categories`, categoryData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju ${error.message}`);
    });
};
