import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../../../layout/Layout";
import VenueMenu from "../../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addExhibit,
  getExhibit,
  editExhibit,
} from "../../../../services/venues/unitExhibitsAPI";
import ButtonBack from "../../../../components/ButtonBack";
import IconModal from "../../../../components/Venue/Modals/IconModal";

const AddEditVenueUnitsExhibits = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  const isEditMode = location === "add-new-venue-exhibits" ? false : true;

  const [exhibitData, setExhibitData] = useState({
    icon: "",
    iconAltText: "",
    title: "",
    size: "",
  });

  useEffect(() => {
    if (id) {
      getExhibit(id)
        .then((data) => {
          setExhibitData({
            icon: data.icon,
            iconAltText: data.icon_alt_text,
            title: data.title,
            size: data.size,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setExhibitData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newExhibitData = {
      icon: exhibitData.icon,
      icon_alt_text: exhibitData.iconAltText,
      title: exhibitData.title,
      size: exhibitData.size,
    };
    if (isEditMode) {
      editExhibit(id, newExhibitData)
        .then((response) => {
          setExhibitData({
            icon: "",
            iconAltText: "",
            title: "",
            size: "",
          });

          navigate("/venue-exhibits");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addExhibit(newExhibitData)
        .then((response) => {
          setExhibitData({
            ...exhibitData,
            icon: response.image,
            iconAltText: response.icon_alt_text,
            title: response.title,
            size: response.size,
          });
          navigate("/venue-exhibits");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Venue Units Exhibits
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <IconModal
                  setData={setExhibitData}
                  data={exhibitData.icon}
                  handleInputChange={handleInputChange}
                  setImageModalOpen={setImageModalOpen}
                  isImageModalOpen={isImageModalOpen}
                />
                <Container>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="iconAltText"
                    label={isImageModalOpen ? "" : "Icon Alt Text"}
                    value={exhibitData.iconAltText}
                    fullWidth
                    onChange={handleInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="title"
                    label={isImageModalOpen ? "" : "Title"}
                    required
                    value={exhibitData.title}
                    fullWidth
                    onChange={handleInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="size"
                    label={isImageModalOpen ? "" : "Size"}
                    value={exhibitData.size}
                    type="number"
                    fullWidth
                    onChange={handleInputChange}
                  />
                </Container>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-venue-exhibits" ? "Publish" : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditVenueUnitsExhibits;
