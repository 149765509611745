import axios from 'axios';

export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchAmenitiesLists = () => {
  return axios.get(`${API_URL}/coworking/amenities-list`)
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.message);
    });
};

export const deleteAmenitiesList = (amenitiesListId) => {
    return axios.delete(`${API_URL}/coworking/amenities-list/${amenitiesListId}`)
      .then(response => response.data)
      .catch(error => {
        throw new Error(`Greška pri brisanju: ${error.message}`);
      });
  };

  export const getAmenitiesList= async (id) => {
    try {
      const response = await axios.get(`${API_URL}/coworking/amenities-list/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Greška pri dobavljanju: ${error.message}`);
    }
  };

  export const editAmenitiesList = async (id, amenitiesListData) => {
    try {
      const response = await axios.put(`${API_URL}/coworking/amenities-list/${id}`, amenitiesListData);
      return response.data;
    } catch (error) {
      throw new Error(`Greška pri editovanju: ${error.message}`);
    }
  };

  export const addAmenitiesList = (amenitiesListData) => {
    return axios.post(`${API_URL}/coworking/amenities-list`, amenitiesListData)
      .then(response => response.data)
      .catch(error => {
        throw new Error(`Greška pri dodavanju: ${error.message}`);
      });
  };
