import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import Layout from "../../layout/Layout";
import VenueMenu from "../../components/VenueMenu";
import { Link } from "react-router-dom";

import TableChartIcon from "@mui/icons-material/TableChart";
import Pagination from "@mui/material/Pagination";
import { fetchCities, deleteCity } from "../../services/cityAPI";

export default function Cities() {
  const [cities, setCities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("title");
  const [isTabularView, setIsTabularView] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [cityIdToDelete, setCityIdToDelete] = useState(null);
  const itemsPerPage = 6;

  useEffect(() => {
    fetchCities()
      .then((data) => {
        setCities(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDelete = () => {
    if (cityIdToDelete) {
      deleteCity(cityIdToDelete)
        .then(() => {
          setDeleteDialogOpen(false);
          setCities((prevCities) =>
            prevCities.filter((cities) => cities.id !== cityIdToDelete)
          );
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };

  const handleDeleteButtonClicked = (cityId) => {
    setDeleteDialogOpen(true);
    setCityIdToDelete(cityId);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleTabularViewToggle = () => {
    setIsTabularView(!isTabularView);
  };

  const filteredData = cities.filter((item) => {
    if (searchBy === "name") {
      return item.name.toLowerCase().includes(searchValue.toLowerCase());
    }
    return true;
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div style={{ width: "73%", marginLeft: "26%" }}>
          <div>
            <div>
              <TextField
                value={searchValue}
                onChange={handleSearchInputChange}
                label="Search"
                variant="outlined"
                fullWidth
              />

              <select value={searchBy} onChange={handleSearchByChange}>
                <option value="name">Name</option>
              </select>
            </div>

            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              City
            </Typography>

            <div>
              <Link
                to={{
                  pathname: "/add-new-cities",
                  state: { isEditMode: false },
                }}
              >
                <Button variant="outlined">Add New</Button>
              </Link>

              <Button
                startIcon={<TableChartIcon />}
                onClick={handleTabularViewToggle}
              ></Button>
            </div>

            {filteredData.length === 0 ? (
              <Typography
                variant="h6"
                style={{
                  marginTop: "20px",
                  color: "red",
                  textAlign: "center",
                }}
              >
                There are no results
              </Typography>
            ) : (
              ""
            )}

            {!isTabularView ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.reverse().map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>
                          <div>
                            <Link
                              to={{
                                pathname: `/edit-cities/${item.id}`,
                                state: { isEditMode: true },
                              }}
                            >
                              <Button variant="outlined">Edit Item</Button>
                            </Link>
                            <Button
                              variant="outlined"
                              onClick={() => handleDeleteButtonClicked(item.id)}
                            >
                              Delete
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Grid container spacing={2}>
                {paginatedData.reverse().map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" component="h2">
                          {item.name}
                        </Typography>
                        <Typography color="textSecondary">
                          {item.description}
                        </Typography>
                        <Link
                          to={{
                            pathname: `/edit-cities/${item.id}`,
                            state: { isEditMode: true },
                          }}
                        >
                          <Button variant="outlined">Edit item</Button>
                        </Link>
                        <Button
                          variant="outlined"
                          onClick={() => handleDeleteButtonClicked(item.id)}
                        >
                          Delete
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}

            <Pagination
              page={currentPage}
              onChange={handlePageChange}
              style={{ marginTop: "1rem", float: "left" }}
              count={Math.ceil(filteredData.length / itemsPerPage)}
            />

            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
              <DialogTitle>Delete Confirmation</DialogTitle>
              <DialogContent>
                <Typography>
                  Are you sure you want to delete this city?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeleteDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  open={deleteDialogOpen}
                  onClose={() => setDeleteDialogOpen(false)}
                  onClick={handleDelete}
                  color="error"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </Layout>
  );
}
