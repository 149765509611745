import axios from "axios";
import { API_URL } from "../const";

export const fetchUnits = async () => {
  return axios
    .get(`${API_URL}/coworking/units`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteUnit = async (id) => {
  return axios
    .delete(`${API_URL}/coworking/units/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const addUnit = async (data) => {
  console.log(data);
  return axios
    .post(`${API_URL}/coworking/units`, data)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getUnit = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/coworking/units/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editUnit = async (id, data) => {
  try {
    const response = await axios.put(`${API_URL}/coworking/units/${id}`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
