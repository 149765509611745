import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container, Box } from "@mui/material";
import Modal from "react-modal";
import { fetchFiles } from "../../../services/fileAPI";

const IconModal = ({
  setData,
  data,
  handleInputChange,
  isImageModalOpen,
  setImageModalOpen,
}) => {
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const files = await fetchFiles();
        setImageList(files);
      } catch (error) {
        console.error("Greška pri dohvatanju slika:", error.message);
      }
    };

    fetchData();
  }, []);

  const openImageModal = () => {
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  const handleImageSelect = (selectedImage) => {
    setData((prevData) => ({
      ...prevData,
      icon: selectedImage,
    }));
    closeImageModal();
  };

  return (
    <>
      <Container
        style={{
          width: "45%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "30px",
          backgroundColor: "#f0f0f0",
          boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.3)",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <Button
          variant="outlined"
          style={{ marginBottom: "30px" }}
          onClick={openImageModal}
        >
          Add Icon
        </Button>
        <TextField
          sx={{ marginBottom: "30px" }}
          name="icon"
          fullWidth
          label={isImageModalOpen ? "" : "Icon"}
          value={data}
          onChange={handleInputChange}
        />
        <img src={data} alt="" style={{ width: "50px", borderRadius: "5px" }} />
      </Container>

      <Modal
        isOpen={isImageModalOpen}
        onRequestClose={closeImageModal}
        contentLabel="Choose Image"
        style={{
          overlay: {
            backgroundColor: "rgba(73, 85, 157, 0.5)",
          },
          content: {
            backgroundColor: "white",
            top: "20%",
            left: "29%",
            right: "5%",
            bottom: "15%",
            zIndex: "10",
          },
        }}
      >
        <div>
          <Typography
            style={{
              textAlign: "center",
              margin: "30px 0",
              color: "#49559d",
              fontWeight: "600",
              fontSize: "32px",
            }}
          >
            Choose an Icon
          </Typography>
          <Box
            style={{
              listStyle: "none",
              padding: 0,
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {imageList.map((image) => (
              <img
                src={image.path}
                alt=""
                style={{
                  width: "200px",
                  padding: "20px",
                  cursor: "pointer",
                  borderRadius: "30px",
                }}
                onClick={() => handleImageSelect(image.path)}
              />
            ))}
          </Box>
          <Button
            variant="outlined"
            onClick={closeImageModal}
            style={{ marginTop: "20px" }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default IconModal;
