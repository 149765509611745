import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import SearchComponent from "../../../components/SearchComponent";
import { Link } from "react-router-dom";

import TableChartIcon from "@mui/icons-material/TableChart";
import Pagination from "@mui/material/Pagination";
import {
  fetchVenueUnits,
  deleteVenueUnit,
} from "../../../services/venues/venueUnitAPI";

export default function VenueUnit() {
  const [venueUnits, setVenueUnits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("name");
  const [isTabularView, setIsTabularView] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [venueUnitsIdToDelete, setVenueUnitsIdToDelete] = useState(null);
  const itemsPerPage = 6;

  useEffect(() => {
    fetchVenueUnits()
      .then((data) => {
        setVenueUnits(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDelete = () => {
    if (venueUnitsIdToDelete) {
      deleteVenueUnit(venueUnitsIdToDelete)
        .then(() => {
          setDeleteDialogOpen(false);
          setVenueUnits((prevVenueUnit) =>
            prevVenueUnit.filter(
              (venueUnit) => venueUnit.id !== venueUnitsIdToDelete
            )
          );
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };

  const handleDeleteButtonClicked = (venueUnitId) => {
    setDeleteDialogOpen(true);
    setVenueUnitsIdToDelete(venueUnitId);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleTabularViewToggle = () => {
    setIsTabularView(!isTabularView);
  };

  const filteredData = venueUnits.filter((item) => {
    if (searchBy === "name") {
      return item.name.toLowerCase().includes(searchValue.toLowerCase());
    }
    return true;
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div style={{ width: "73%", marginLeft: "26%" }}>
          <div>
            <div>
              <SearchComponent
                searchValue={searchValue}
                searchBy={searchBy}
                onSearchInputChange={handleSearchInputChange}
                onSearchByChange={handleSearchByChange}
              />
            </div>

            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Venue Units
            </Typography>

            <div>
              <Link
                to={{
                  pathname: "/add-new-venue-units",
                  state: { isEditMode: false },
                }}
              >
                <Button variant="outlined">Add New</Button>
              </Link>

              <Button
                startIcon={<TableChartIcon />}
                onClick={handleTabularViewToggle}
              ></Button>
            </div>

            {filteredData.length === 0 ? (
              <Typography
                variant="h6"
                style={{
                  marginTop: "20px",
                  color: "red",
                  textAlign: "center",
                }}
              >
                There are no results
              </Typography>
            ) : (
              ""
            )}

            {!isTabularView ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  {venueUnits.reverse().map((venueUnit, venueUnitIndex) => (
                    <TableBody key={venueUnit}>
                      {paginatedData.map((venueUnit, index) => (
                        <TableRow key={index}>
                          {venueUnitIndex === 0 && (
                            <>
                              <TableCell>{venueUnit.name}</TableCell>
                              <TableCell>{venueUnit.description}</TableCell>
                              <TableCell>
                                <div>
                                  <Link
                                    to={{
                                      pathname: `/edit-venue-units/${venueUnit.id}`,
                                      state: { isEditMode: true },
                                    }}
                                  >
                                    <Button variant="outlined">
                                      Edit Item
                                    </Button>
                                  </Link>
                                  <Button
                                    variant="outlined"
                                    onClick={() =>
                                      handleDeleteButtonClicked(venueUnit.id)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
            ) : (
              venueUnits.reverse().map((venueUnit, venueUnitIndex) => (
                <Grid container spacing={2} key={venueUnit.id}>
                  {paginatedData.map((venueUnit, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      {venueUnitIndex === 0 && (
                        <Card>
                          <CardContent>
                            <Typography variant="h5" component="h2">
                              {venueUnit.name}
                            </Typography>
                            <Typography color="textSecondary">
                              {venueUnit.description}
                            </Typography>
                            <Link
                              to={{
                                pathname: `/edit-venue-units/${venueUnit.id}`,
                                state: { isEditMode: true },
                              }}
                            >
                              <Button variant="outlined">Edit item</Button>
                            </Link>
                            <Button
                              variant="outlined"
                              onClick={() =>
                                handleDeleteButtonClicked(venueUnit.id)
                              }
                            >
                              Delete
                            </Button>
                          </CardContent>
                        </Card>
                      )}
                    </Grid>
                  ))}
                </Grid>
              ))
            )}

            <Pagination
              page={currentPage}
              onChange={handlePageChange}
              style={{ marginTop: "1rem", float: "right" }}
              count={Math.ceil(filteredData.length / itemsPerPage)}
            />

            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
              <DialogTitle>Delete Confirmation</DialogTitle>
              <DialogContent>
                <Typography>
                  Are you sure you want to delete this venue unit?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeleteDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  open={deleteDialogOpen}
                  onClose={() => setDeleteDialogOpen(false)}
                  onClick={handleDelete}
                  color="error"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </Layout>
  );
}
