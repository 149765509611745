import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../../../../layout/Layout";
import VenueMenu from "../../../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addUnitAmenitiesList,
  getUnitAmenitiesList,
  editUnitAmenitiesList,
} from "../../../../../services/venues/unitAmenitiesListAPI";
import ButtonBack from "../../../../../components/ButtonBack";
import IconModal from "../../../../../components/Venue/Modals/IconModal";

const AddEditVenueUnitsAmenitiesList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  const isEditMode =
    location === "add-new-venue-units-amenities-list" ? false : true;

  const [unitAmenitiesListData, setUnitAmenitiesListData] = useState({
    icon: "",
    iconAltText: "",
    title: "",
  });

  useEffect(() => {
    if (id) {
      getUnitAmenitiesList(id)
        .then((data) => {
          setUnitAmenitiesListData({
            icon: data.icon,
            iconAltText: data.icon_alt_text,
            title: data.title,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUnitAmenitiesListData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newUnitAmenitiesListData = {
      icon: unitAmenitiesListData.icon,
      icon_alt_text: unitAmenitiesListData.iconAltText,
      title: unitAmenitiesListData.title,
    };
    if (isEditMode) {
      editUnitAmenitiesList(id, newUnitAmenitiesListData)
        .then((response) => {
          setUnitAmenitiesListData({
            icon: "",
            iconAltText: "",
            title: "",
          });

          navigate("/venue-units-amenities-list");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addUnitAmenitiesList(newUnitAmenitiesListData)
        .then((response) => {
          setUnitAmenitiesListData({
            ...unitAmenitiesListData,
            icon: response.icon,
            iconAltText: response.icon_alt_text,
            title: response.title,
          });
          navigate("/venue-units-amenities-list");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Venue Units Amenities List
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <IconModal
                  setData={setUnitAmenitiesListData}
                  data={unitAmenitiesListData.icon}
                  handleInputChange={handleInputChange}
                  setImageModalOpen={setImageModalOpen}
                  isImageModalOpen={isImageModalOpen}
                />
                <Container>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="iconAltText"
                    label={isImageModalOpen ? "" : "Icon Alt Text"}
                    value={unitAmenitiesListData.iconAltText}
                    fullWidth
                    onChange={handleInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="title"
                    label={isImageModalOpen ? "" : "Title"}
                    required
                    value={unitAmenitiesListData.title}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </Container>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-venue-units-amenities-list"
                    ? "Publish"
                    : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditVenueUnitsAmenitiesList;
