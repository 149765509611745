import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";

import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

import { useState, useEffect } from "react";
import { fetchVenues, editVenue } from "../../../services/venues/venueAPI";
import { Link } from "react-router-dom";

function VenueSliderOrder() {
  const [venues, setVenues] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  const fetchAndSetVenues = () => {
    fetchVenues()
      .then((data) => {
        setVenues(data);
      })
      .catch((error) => {
        console.error("Error fetching venues:", error);
      });
  };

  useEffect(() => {
    fetchAndSetVenues();
  }, []);

  const filteredAndSortedData = venues
    .filter((venue) => venue.slider === 1)
    .slice()
    .sort((a, b) => a.slider_order - b.slider_order);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = async (e) => {
    if (!e.destination) return;
    setIsDragging(false);

    const tempData = Array.from(filteredAndSortedData);
    const [orderVenueInList] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, orderVenueInList);

    try {
      for (let i = 0; i < tempData.length; i++) {
        const venue = tempData[i];
        const updatedVenueData = {
          slider_order: i + 1,
          name: venue.name,
          city: venue.city,
          address: venue.address,
          slug: venue.slug,
        };
        await editVenue(venue.id, updatedVenueData);
      }
      fetchAndSetVenues();
      console.log("Baza podataka je ažurirana sa novim redosledom venue-a.");
    } catch (error) {
      console.error("Greška prilikom ažuriranja baze podataka:", error.message);
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div style={{ width: "73%", marginLeft: "26%" }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ textAlign: "center", marginBottom: "30px" }}
          >
            Slider Order
          </Typography>
          <Link
            to={{
              pathname: "/preview-slider",
            }}
          >
            <Button variant="outlined">Preview</Button>
          </Link>
          <DragDropContext onDragEnd={handleDragEnd}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Move</TableCell>
                    <TableCell>Info</TableCell>
                    <TableCell align="center">Featured Image</TableCell>
                  </TableRow>
                </TableHead>
                <Droppable droppableId="droppable-1">
                  {(provider) => (
                    <TableBody
                      ref={provider.innerRef}
                      {...provider.droppableProps}
                    >
                      {filteredAndSortedData.map((venue, index) => (
                        <Draggable
                          key={venue.name}
                          draggableId={venue.name}
                          index={index}
                          onStart={handleDragStart}
                        >
                          {(provided) => (
                            <TableRow
                              key={venue.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <TableCell component="th" scope="row">
                                <ControlCameraIcon />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Typography variant="body1">
                                  Slider Order: {venue.slider_order}
                                </Typography>
                                <Typography variant="h6">
                                  Name: {venue.name}
                                </Typography>
                                <Typography variant="body2">
                                  Brand: {venue.brand}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <img
                                  src={venue.featured_image}
                                  alt={venue.name}
                                  style={{
                                    width: "100px",
                                    height: "auto",
                                    borderRadius: "8px",
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provider.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </Table>
            </TableContainer>
          </DragDropContext>
        </div>
      </div>
    </Layout>
  );
}

export default VenueSliderOrder;
