import React from "react";
import { TextField, Container } from "@mui/material";

const SearchComponent = ({
  searchValue,
  searchBy,
  onSearchInputChange,
  onSearchByChange,
}) => {
  return (
    <Container style={{ display: "flex", marginBottom: "30px" }}>
      <TextField
        value={searchValue}
        onChange={onSearchInputChange}
        label="Search"
        variant="outlined"
        fullWidth
      />
      <select
        value={searchBy}
        onChange={onSearchByChange}
        style={{
          width: "20%",
          textAlign: "center",
          borderRadius: "5px",
          marginLeft: "20px",
        }}
      >
        <option value={searchBy}>{searchBy.toUpperCase()}</option>
      </select>
    </Container>
  );
};

export default SearchComponent;
