import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Box,
} from "@mui/material";
import Layout from "../../layout/Layout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addCoworking,
  getCoworking,
  editCoworking,
} from "../../services/coworking/coworkingAPI";
import { fetchCategories } from "../../services/coworking/categoryApi";
import { fetchDocumentations } from "../../services/coworking/DocumentationAPI";
import { fetchTypeOfEvents } from "../../services/coworking/typeOfEventAPI";
import { fetchGeneralInformation } from "../../services/coworking/generalInformationAPI";
import { fetchPhotoGalleries } from "../../services/coworking/photoGalleryAPI";
import { fetchReviews } from "../../services/coworking/ReviewsAPI";
import { fetchSocialMedias } from "../../services/coworking/SocialMedia";
import { fetchTestimonials } from "../../services/coworking/TestimonialAPI";
import { fetchUnits } from "../../services/coworking/unit/Unit";
import { fetchAmenitiesSections } from "../../services/coworking/amenitiesSectionAPI";
import { fetchDistanceSections } from "../../services/coworking/distanceSectionAPI";

const AddEditCoworking = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode = location === "add-new-coworking" ? false : true;

  const [selectedTab, setSelectedTab] = useState("general");

  const [coworkingData, setCoworkingData] = useState({
    name: "",
    featured_image: "",
    city: "",
    address: "",
    description: "",
    largest_rooms_space: "",
    altitude: "",
    longitude: "",
    slug: "",
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    general_review_score: "",
    total_number_of_people: "",
    style: "",
    total_coworking_space: "",
    order_coworking_in_list: "",
    categories: [],
    documentations: [],
    events: [],
    general_informations: [],
    photo_galleries: [],
    reviews: [],
    social_media: [],
    testimonials: [],
    units: [],
    amenities_sections: [],
    distance_sections: [],
  });
  const [generalInformations, setGeneralInformations] = useState([]);
  const [photoGalleries, setPhotoGalleries] = useState([]);
  const [reviewsList, setReviewsList] = useState([]);
  const [socialMedia, setSocialMedia] = useState([]);
  const [typeOfEvent, setTypeOfEvent] = useState([]);
  const [testimonialsList, setTestimonialsList] = useState([]);
  const [unitsList, setUnitsList] = useState([]);
  const [amenitiesSections, setAmenitiesSections] = useState([]);
  const [distanceSections, setDistanceSections] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [documentationsList, setDocumentationsList] = useState([]);

  useEffect(() => {
    fetchGeneralInformation()
      .then((data) => {
        setGeneralInformations(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchPhotoGalleries()
      .then((data) => {
        setPhotoGalleries(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchReviews()
      .then((data) => {
        setReviewsList(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchSocialMedias()
      .then((data) => {
        setSocialMedia(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchTypeOfEvents()
      .then((data) => {
        setTypeOfEvent(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchTestimonials()
      .then((data) => {
        setTestimonialsList(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchUnits()
      .then((data) => {
        setUnitsList(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchAmenitiesSections()
      .then((data) => {
        setAmenitiesSections(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchDistanceSections()
      .then((data) => {
        setDistanceSections(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchCategories()
      .then((data) => {
        setCategoriesList(data);
      })
      .catch((error) => {
        console.error(error);
      });
    fetchDocumentations()
      .then((data) => {
        setDocumentationsList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (id) {
      getCoworking(id)
        .then((data) => {
          setCoworkingData({
            name: data.name,
            featured_image: data.featured_image,
            city: data.city,
            address: data.address,
            description: data.description,
            largest_rooms_space: data.largest_rooms_space,
            altitude: data.altitude,
            longitude: data.longitude,
            slug: data.slug,
            meta_title: data.meta_title,
            meta_description: data.meta_description,
            meta_keyword: data.meta_keyword,
            general_review_score: data.general_review_score,
            total_number_of_people: data.total_number_of_people,
            style: data.style,
            total_coworking_space: data.total_coworking_space,
            order_coworking_in_list: data.order_coworking_in_list,
            categories: data.categories
              ? data.categories?.map((list) => list.id)
              : [],
            documentations: data.documentations
              ? data.documentations?.map((list) => list.id)
              : [],
            events: data.events ? data.events?.map((list) => list.id) : [],
            general_informations: data.general_informations
              ? data.general_informations?.map((list) => list.id)
              : [],
            photo_galleries: data.photo_galleries
              ? data.photo_galleries?.map((list) => list.id)
              : [],
            reviews: data.reviews ? data.reviews?.map((list) => list.id) : [],
            social_media: data.social_media
              ? data.social_media?.map((list) => list.id)
              : [],
            testimonials: data.testimonials
              ? data.testimonials?.map((list) => list.id)
              : [],
            units: data.units ? data.units?.map((list) => list.id) : [],
            amenities_sections: data.amenities_sections
              ? data.amenities_sections?.map((list) => list.id)
              : [],
            distance_sections: data.distance_sections
              ? data.distance_sections?.map((list) => list.id)
              : [],
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCoworkingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEditMode) {
      editCoworking(id, coworkingData)
        .then(() => {
          navigate("/coworking");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addCoworking(coworkingData)
        .then(() => {
          navigate("/coworking");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  const handleCategories = (event) => {
    setCoworkingData((prevData) => ({
      ...prevData,
      categories: event.target.value,
    }));
  };

  const handleDocumentationsList = (event) => {
    setCoworkingData((prevData) => ({
      ...prevData,
      documentations: event.target.value,
    }));
  };

  const handleTypeOfEvent = (event) => {
    setCoworkingData((prevData) => ({
      ...prevData,
      events: event.target.value,
    }));
  };

  const handleGeneralInformations = (event) => {
    setCoworkingData((prevData) => ({
      ...prevData,
      general_informations: event.target.value,
    }));
  };

  const handlePhotoGalleries = (event) => {
    setCoworkingData((prevData) => ({
      ...prevData,
      photo_galleries: event.target.value,
    }));
  };

  const handleReviewsList = (event) => {
    setCoworkingData((prevData) => ({
      ...prevData,
      reviews: event.target.value,
    }));
  };

  const handleSocialMedia = (event) => {
    setCoworkingData((prevData) => ({
      ...prevData,
      social_media: event.target.value,
    }));
  };

  const handleTestimonialsList = (event) => {
    setCoworkingData((prevData) => ({
      ...prevData,
      testimonials: event.target.value,
    }));
  };

  const handleUnitsList = (event) => {
    setCoworkingData((prevData) => ({
      ...prevData,
      units: event.target.value,
    }));
  };

  const handleAmenitiesSections = (event) => {
    setCoworkingData((prevData) => ({
      ...prevData,
      amenities_sections: event.target.value,
    }));
  };

  const handleDistanceSections = (event) => {
    setCoworkingData((prevData) => ({
      ...prevData,
      distance_sections: event.target.value,
    }));
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleBack = () => {
    // Handle Back action here
  };

  return (
    <Layout>
      <div>
        <Box style={{ display: "flex" }}>
          <Link to="/coworking" style={{ textDecoration: "none" }}>
            <Button variant="outlined" onClick={handleBack}>
              <ArrowBackIcon />
              Back
            </Button>
          </Link>
        </Box>
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Coworking
        </Typography>
        <Container
          sx={{
            marginTop: "10px",
            marginBottom: "15px",
            display: "flex",
            justifyContent: "space-around",
            paddingBottom: "15px",
            paddingTop: "15px",
            borderTop: "2px solid #49559d",
            borderBottom: "2px solid #49559d",
          }}
        >
          <Button variant="outlined" onClick={() => handleTabChange("general")}>
            General Info
          </Button>
          <Button variant="outlined" onClick={() => handleTabChange("meta")}>
            Meta Tags
          </Button>
          <Button variant="outlined" onClick={() => handleTabChange("other")}>
            Other
          </Button>
        </Container>
        <form onSubmit={handleSubmit}>
          {selectedTab === "general" && (
            <>
              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="name"
                  label="Name"
                  value={coworkingData.name}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="featured_image"
                  label="Featured Image"
                  value={coworkingData.featured_image}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="city"
                  label="City"
                  value={coworkingData.city}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="address"
                  label="Address"
                  value={coworkingData.address}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="description"
                  label="Description"
                  value={coworkingData.description}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="largest_rooms_space"
                  label="Largest Room"
                  value={coworkingData.largest_rooms_space}
                  type="number"
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="altitude"
                  label="Altitude"
                  value={coworkingData.altitude}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="longitude"
                  label="Longitude"
                  value={coworkingData.longitude}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="slug"
                  label="Slag"
                  value={coworkingData.slug}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="general_review_score"
                  label="General Review Score"
                  value={coworkingData.general_review_score}
                  type="number"
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="total_coworking_space"
                  label="Total Coworking Space"
                  value={coworkingData.total_coworking_space}
                  type="number"
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="total_number_of_people"
                  label="Total Number Of People"
                  value={coworkingData.total_number_of_people}
                  type="number"
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="style"
                  label="Style"
                  value={coworkingData.style}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="order_coworking_in_list"
                  label="Total Number Of Conference Rooms"
                  value={coworkingData.order_coworking_in_list}
                  type="number"
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>
            </>
          )}

          {selectedTab === "meta" && (
            <>
              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="meta_title"
                  label="Meta Title"
                  value={coworkingData.meta_title}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="meta_description"
                  label="Meta Description"
                  value={coworkingData.meta_description}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="meta_keyword"
                  label="Meta Keyword"
                  value={coworkingData.meta_keyword}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>
            </>
          )}

          {selectedTab === "other" && (
            <>
              <Container>
                <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    General Information
                  </InputLabel>
                  <Select
                    name="general_informations"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={coworkingData.general_informations}
                    label="VenueTypes"
                    onChange={handleGeneralInformations}
                  >
                    {generalInformations?.map((list) => (
                      <MenuItem key={list.id} value={list.id}>
                        {list.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Amenities Section
                  </InputLabel>
                  <Select
                    name="amenities_sections"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={coworkingData.amenities_sections}
                    label="VenueTypes"
                    onChange={handleAmenitiesSections}
                  >
                    {amenitiesSections?.map((list) => (
                      <MenuItem key={list.id} value={list.id}>
                        {list.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Type Of Event
                  </InputLabel>
                  <Select
                    name="events"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={coworkingData.events}
                    label="VenueTypes"
                    onChange={handleTypeOfEvent}
                  >
                    {typeOfEvent?.map((list) => (
                      <MenuItem key={list.id} value={list.id}>
                        {list.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Photo Gallery
                  </InputLabel>
                  <Select
                    name="photo_galleries"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={coworkingData.photo_galleries}
                    label="VenueTypes"
                    onChange={handlePhotoGalleries}
                  >
                    {photoGalleries?.map((list) => (
                      <MenuItem key={list.id} value={list.id}>
                        {list.image}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                  <InputLabel id="demo-simple-select-label">Units</InputLabel>
                  <Select
                    name="units"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={coworkingData.units}
                    label="VenueTypes"
                    onChange={handleUnitsList}
                  >
                    {unitsList?.map((list) => (
                      <MenuItem key={list.id} value={list.id}>
                        {list.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Distance
                  </InputLabel>
                  <Select
                    name="distance_sections"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={coworkingData.distance_sections}
                    label="VenueTypes"
                    onChange={handleDistanceSections}
                  >
                    {distanceSections?.map((list) => (
                      <MenuItem key={list.id} value={list.id}>
                        {list.distance_section_title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Documentation
                  </InputLabel>
                  <Select
                    name="documentations"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={coworkingData.documentations}
                    label="VenueTypes"
                    onChange={handleDocumentationsList}
                  >
                    {documentationsList?.map((list) => (
                      <MenuItem key={list.id} value={list.id}>
                        {list.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Testimonials
                  </InputLabel>
                  <Select
                    name="testimonials"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={coworkingData.testimonials}
                    label="VenueTypes"
                    onChange={handleTestimonialsList}
                  >
                    {testimonialsList?.map((list) => (
                      <MenuItem key={list.id} value={list.id}>
                        {list.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                  <InputLabel id="demo-simple-select-label">Reviews</InputLabel>
                  <Select
                    name="reviews"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={coworkingData.reviews}
                    label="VenueTypes"
                    onChange={handleReviewsList}
                  >
                    {reviewsList?.map((list) => (
                      <MenuItem key={list.id} value={list.id}>
                        {list.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Social Media Links
                  </InputLabel>
                  <Select
                    name="social_media"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={coworkingData.social_media}
                    label="VenueTypes"
                    onChange={handleSocialMedia}
                  >
                    {socialMedia?.map((list) => (
                      <MenuItem key={list.id} value={list.id}>
                        {list.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    name="categories"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={coworkingData.categories}
                    label="VenueTypes"
                    onChange={handleCategories}
                  >
                    {categoriesList?.map((list) => (
                      <MenuItem key={list.id} value={list.id}>
                        {list.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Container>
            </>
          )}
          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              {location === "add-new-coworking" ? "Publish" : "Save"}
            </Button>
          </Container>
        </form>
      </div>
    </Layout>
  );
};

export default AddEditCoworking;
