import axios from "axios";
import { API_URL } from "./venues/apiUrl";

// export const API_URL = 'https://montenegromice.com/blog-api';

export const fetchBlogsCategories = () => {
  return axios
    .get(`${API_URL}/getAllBlogsCategoriesBlog`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

// export const deleteBlogCategory = (blogId) => {
//   return axios.delete(`${API_URL}/deleteBlog/${blogId}`)
//     .then(response => response.data)
//     .catch(error => {
//       throw new Error(`Greška pri brisanju bloga: ${error.message}`);
//     });
// };

// export const addBlogCategory = (blogData) => {
//   return axios.post(`${API_URL}/storeBlog`, blogData)
//     .then(response => response.data)
//     .catch(error => {
//       throw new Error(`Greška pri dodavanju bloga: ${error.message}`);
//     });
// };

// export const getBlogCategory = async (id) => {
//   try {
//     const response = await axios.get(`${API_URL}/showBlog/${id}`);
//     return response.data;
//   } catch (error) {
//     throw new Error(`Greška pri dobavljanju bloga: ${error.message}`);
//   }
// };

// export const editBlogCategory = async (id, blogData) => {
//   try {
//     const response = await axios.put(`${API_URL}/updateBlog/${id}`, blogData);
//     return response.data;
//   } catch (error) {
//     throw new Error(`Greška pri editovanju bloga: ${error.message}`);
//   }
// };
