import React from "react";
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Link } from "react-router-dom";

const UnitInfo = ({
  toggleUnitForm,
  unitList,
  handleDeleteUnit,
  isDraggingUnit,
  handleDragEndUnit,
  handleDragStartUnit,
  setSelectedUnitId,
  selectedUnitId,
  toggleImageForm,
  unitImagesPivot,
  handleDeleteImageUnit,
  filesList,
}) => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        margin: "30px auto",
      }}
    >
      {isDraggingUnit}
      <DragDropContext onDragEnd={handleDragEndUnit}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Move</TableCell>
              <TableCell>Order</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Total No Of People</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Action Buttons</TableCell>
            </TableRow>
          </TableHead>
          <Droppable droppableId="droppable-1">
            {(provided, snapshot) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {unitList
                  ?.sort((a, b) => a.order - b.order)
                  .map((unit, index) => (
                    <Draggable
                      key={unit.name}
                      draggableId={unit.name}
                      index={index}
                      onStart={handleDragStartUnit}
                    >
                      {(provided, snapshot) => (
                        <>
                          <TableRow
                            key={unit.title}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TableCell component="th" scope="row">
                              <ControlCameraIcon />
                            </TableCell>
                            <TableCell>{unit.order}</TableCell>
                            <TableCell>{unit.name}</TableCell>
                            <TableCell>{unit.total_number_of_people}</TableCell>
                            <TableCell>{unit.size}</TableCell>
                            <TableCell>
                              <Link
                                to={{
                                  pathname: `/edit-venue-units/${unit.id}`,
                                  state: { isEditMode: true },
                                }}
                              >
                                <Button variant="outlined">Edit Item</Button>
                              </Link>
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleDeleteUnit(unit.id)}
                              >
                                Delete
                              </Button>
                              <IconButton
                                onClick={() =>
                                  setSelectedUnitId(
                                    selectedUnitId === unit.id ? null : unit.id
                                  )
                                }
                              >
                                {selectedUnitId === unit.id ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={6}>
                              {selectedUnitId === unit.id && (
                                <Box>
                                  <Container
                                    style={{
                                      position: "relative",
                                      top: "0",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        color: "#49559d",
                                        fontWeight: "400",
                                        fontSize: "24px",
                                      }}
                                    >
                                      Images
                                    </Typography>
                                    <Button
                                      variant="outlined"
                                      style={{
                                        marginBottom: "20px",
                                        margin: "20px auto",
                                      }}
                                      onClick={() =>
                                        toggleImageForm(unit.id, "unit")
                                      }
                                    >
                                      Add images
                                    </Button>
                                  </Container>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      padding: "10px",
                                    }}
                                  >
                                    {unitImagesPivot.map((image) => {
                                      const matchedFile = filesList.find(
                                        (file) =>
                                          file.id === image.image_id &&
                                          unit.id === image.unit_id
                                      );
                                      if (matchedFile) {
                                        return (
                                          <div
                                            key={matchedFile.id}
                                            style={{
                                              padding: "10px",
                                            }}
                                          >
                                            <Box
                                              style={{
                                                backgroundColor: "#F0F0F0",
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: "10px",
                                                borderRadius: "10px",
                                                boxShadow:
                                                  "rgba(0, 0, 0, 0.3) 1px 2px 4px",
                                              }}
                                            >
                                              <img
                                                src={matchedFile.path}
                                                alt={matchedFile.alt_image_me}
                                                style={{
                                                  width: "170px",
                                                  height: "150px",
                                                  borderRadius: "10px",
                                                }}
                                              />
                                              <IconButton
                                                color="error"
                                                onClick={() =>
                                                  handleDeleteImageUnit(
                                                    unit.id,
                                                    matchedFile.id
                                                  )
                                                }
                                              >
                                                <DeleteForeverIcon />
                                              </IconButton>
                                            </Box>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                </Box>
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>

      <Container style={{ display: "flex", flexDirection: "column" }}>
        <Button
          variant="outlined"
          style={{ margin: "30px auto" }}
          onClick={toggleUnitForm}
        >
          Create New Unit
        </Button>
      </Container>
    </Container>
  );
};

export default UnitInfo;
