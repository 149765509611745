import React from "react";
import {
  TextField,
  Skeleton,
  Typography,
  Box,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";

const MainInfo = ({
  loading,
  isFormOpenUnit,
  isFormOpenGuestRoom,
  isFormOpenDistance,
  isFormOpenGallery,
  isFormOpenImages,
  isFormOpenVideo,
  venueData,
  handleInputChange,
  handleCity,
  citiesList,
  isEditMode,
  toggleImageForm,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      {/* Venue Name */}

      {loading && isEditMode ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={56}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px", width: "45%" }}
        />
      ) : (
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="name"
          label={
            isFormOpenUnit ||
            isFormOpenGuestRoom ||
            isFormOpenDistance ||
            isFormOpenGallery ||
            isFormOpenImages ||
            isFormOpenVideo
              ? ""
              : "Venue Name"
          }
          required
          value={venueData.name}
          onChange={handleInputChange}
        />
      )}
      {/* Slug */}
      {loading && isEditMode ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={56}
          sx={{ bgcolor: "#49559d40" }}
          style={{ marginTop: "30px", width: "45%" }}
        />
      ) : (
        <TextField
          sx={{ marginTop: "30px", width: "45%" }}
          name="slug"
          label={
            isFormOpenUnit ||
            isFormOpenGuestRoom ||
            isFormOpenDistance ||
            isFormOpenGallery ||
            isFormOpenImages ||
            isFormOpenVideo
              ? ""
              : "Slug"
          }
          required
          value={venueData.slug}
          onChange={handleInputChange}
        />
      )}

      {/* Provider */}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
          width: "45%",
        }}
      >
        {loading && isEditMode ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            height={56}
            sx={{ bgcolor: "#49559d40" }}
            style={{ marginTop: "30px", marginBottom: "30px" }}
          />
        ) : (
          <TextField
            sx={{ marginTop: "30px", marginBottom: "30px" }}
            name="provider"
            label={
              isFormOpenUnit ||
              isFormOpenGuestRoom ||
              isFormOpenDistance ||
              isFormOpenGallery ||
              isFormOpenImages ||
              isFormOpenVideo
                ? ""
                : "Provider"
            }
            value={venueData.provider}
            onChange={handleInputChange}
          />
        )}

        {/* IsPublic */}
        {loading && isEditMode ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            height={20}
            width={80}
            sx={{
              bgcolor: "#49559d40",
              marginBottom: "5px",
            }}
          />
        ) : (
          <label style={{ fontWeight: "600", color: "#49559d" }}>
            IsPublic
          </label>
        )}
        {loading && isEditMode ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            height={25}
            width={60}
            sx={{
              bgcolor: "#49559d40",
              marginBottom: "5px",
              marginLeft: "10px",
            }}
          />
        ) : (
          <Switch
            name="is_public"
            checked={venueData.is_public}
            onChange={(event) => {
              const newValue = event.target.checked;
              handleInputChange({
                target: {
                  name: "is_public",
                  value: newValue,
                },
              });
            }}
          />
        )}
        {/* Slider */}
        {loading && isEditMode ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            height={20}
            width={80}
            sx={{
              bgcolor: "#49559d40",
              marginBottom: "5px",
            }}
          />
        ) : (
          <label style={{ fontWeight: "600", color: "#49559d" }}>Slider</label>
        )}
        {loading && isEditMode ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            height={25}
            width={60}
            sx={{
              bgcolor: "#49559d40",
              marginLeft: "10px",
            }}
          />
        ) : (
          <Switch
            name="slider"
            checked={venueData.slider}
            onChange={(event) => {
              const newValue = event.target.checked;
              handleInputChange({
                target: {
                  name: "slider",
                  value: newValue,
                },
              });
            }}
          />
        )}

        {/* City List */}
        {loading && isEditMode ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            height={56}
            sx={{ bgcolor: "#49559d40" }}
            style={{ marginTop: "30px" }}
          />
        ) : (
          <FormControl sx={{ marginTop: "30px" }}>
            <InputLabel id="demo-simple-select-label">
              {isFormOpenUnit ||
              isFormOpenGuestRoom ||
              isFormOpenDistance ||
              isFormOpenGallery ||
              isFormOpenImages ||
              isFormOpenVideo
                ? ""
                : "City List"}
            </InputLabel>
            <Select
              name="cities"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={venueData.cities}
              label="VenueTypes"
              onChange={handleCity}
            >
              {citiesList?.map((list) => (
                <MenuItem key={list.id} value={list.id}>
                  {list.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Address */}
        {loading && isEditMode ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            height={56}
            sx={{ bgcolor: "#49559d40" }}
            style={{ marginTop: "30px" }}
          />
        ) : (
          <TextField
            sx={{ marginTop: "30px" }}
            name="address"
            label={
              isFormOpenUnit ||
              isFormOpenGuestRoom ||
              isFormOpenDistance ||
              isFormOpenGallery ||
              isFormOpenImages ||
              isFormOpenVideo
                ? ""
                : "Address"
            }
            required
            value={venueData.address}
            fullWidth
            onChange={handleInputChange}
          />
        )}
      </Box>

      {/* Featured Image */}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
          width: "45%",
          backgroundColor: "#f0f0f0",
          boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.3)",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <Typography
          style={{
            textAlign: "center",
            fontWeight: "600",
            color: "#49559d",
          }}
        >
          Featured Image
        </Typography>
        {loading && isEditMode ? (
          <>
            <Skeleton
              variant="rounded"
              animation="wave"
              height={56}
              sx={{ bgcolor: "#49559d40" }}
              style={{ marginBottom: "20px" }}
            />
            <Skeleton
              variant="rounded"
              sx={{ bgcolor: "#49559d40" }}
              style={{ width: "498px", height: "332px" }}
            />
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              style={{ marginBottom: "20px", margin: "20px auto" }}
              onClick={() => toggleImageForm(venueData.id, "featuredImage")}
            >
              Add image
            </Button>
            <TextField
              sx={{ marginBottom: "20px" }}
              name="featured_image"
              label={
                isFormOpenUnit ||
                isFormOpenGuestRoom ||
                isFormOpenDistance ||
                isFormOpenGallery ||
                isFormOpenImages ||
                isFormOpenVideo
                  ? ""
                  : "URL"
              }
              value={venueData.featured_image}
              onChange={handleInputChange}
            />
            <img
              src={venueData.featured_image}
              alt=""
              style={{ borderRadius: "10px" }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default MainInfo;
