import React from "react";
import Layout from "../layout/Layout";
import BasicButtons from "../components/Button";

function Dashboard() {
  return (
    <Layout>
      <h1>MICE CMS</h1>
      <BasicButtons text="send" type="contained" color="secondary" />
    </Layout>
  );
}

export default Dashboard;
