import React from "react";
import { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  Typography,
  Switch,
  Box,
  Container,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Layout from "../../layout/Layout";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { addBlog, getBlog, editBlog } from "../../services/blogAPI";
import { fetchCategories } from "../../services/categoryAPI";
import { fetchBlogsCategories } from "../../services/blogCategoryAPI";
import InputLabel from "@mui/material/InputLabel";
import Cookies from "js-cookie";
import ButtonBack from "../../components/ButtonBack";

export default function AddEditBlog() {
  const navigate = useNavigate();
  let location = useLocation();
  const { id } = useParams();
  const token = Cookies.get("token");
  const parts = token.split(".");
  const payload = JSON.parse(atob(parts[1]));
  const userId = payload.sub;

  location = location.pathname.slice(1);
  const isEditMode = location === "add-new-blog" ? false : true;

  const [blogData, setBlogData] = useState({
    card_image: "",
    main_image: "",
    title_me: "",
    title_en: "",
    short_description_me: "",
    short_description_en: "",
    big_description_me: "",
    big_description_en: "",
    slug_me: "",
    slug_en: "",
    meta_title_me: "",
    meta_title_en: "",
    meta_description_me: "",
    meta_description_en: "",
    date: getCurrentDateTime(),
    order: "",
    is_public: 0,
    author: userId,
    gallery: "",
    categories: [],
  });
  useEffect(() => {
    fetchCategories()
      .then((data) => {
        setCategoriesList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [categoriesList, setCategoriesList] = useState([]);

  const [blogCategoriesList, setBlogCategoriesList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesData = await fetchCategories();
        setCategoriesList(categoriesData);

        if (id) {
          const blogData = await getBlog(id);
          const categoriesData = await fetchBlogsCategories();
          const blogWithId = categoriesData.find((blog) => blog.id == id);
          const categoryNames = blogWithId.categories.map(
            (category) => category.id
          );
          setBlogCategoriesList(categoryNames);

          setBlogData({
            card_image: blogData.card_image,
            main_image: blogData.main_image,
            title_me: blogData.title_me,
            title_en: blogData.title_en,
            short_description_me: blogData.short_description_me,
            short_description_en: blogData.short_description_en,
            big_description_me: blogData.big_description_me,
            big_description_en: blogData.big_description_en,
            slug_me: blogData.slug_me,
            slug_en: blogData.slug_en,
            meta_title_me: blogData.meta_title_me,
            meta_title_en: blogData.meta_title_en,
            meta_description_me: blogData.meta_description_me,
            meta_description_en: blogData.meta_description_en,
            order: blogData.order,
            date: getCurrentDateTime(),
            is_public: blogData.is_public,
            author: blogData.author,
            gallery: blogData.gallery,
            categories: categoryNames,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  function getCurrentDateTime() {
    const now = new Date();

    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, "0");
    const day = String(now.getUTCDate()).padStart(2, "0");

    const hours = String(now.getUTCHours()).padStart(2, "0");
    const minutes = String(now.getUTCMinutes()).padStart(2, "0");
    const seconds = String(now.getUTCSeconds()).padStart(2, "0");
    const milliseconds = String(now.getUTCMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBlogData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isEditMode) {
      editBlog(id, blogData)
        .then(() => {
          navigate("/blog");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju bloga:", error);
        });
    } else {
      addBlog(blogData)
        .then(() => {
          navigate("/blog");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju bloga:", error);
        });
    }
  };

  const handleCategories = (event) => {
    setBlogData((prevData) => ({
      ...prevData,
      categories: event.target.value,
    }));
  };

  return (
    <Layout>
      <div>
        <ButtonBack />
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Blog
        </Typography>
        <form onSubmit={handleSubmit}>
          <Container
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "30px",
                width: "45%",
                backgroundColor: "#f0f0f0",
                boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.3)",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  color: "#49559d",
                  marginBottom: "10px",
                }}
              >
                Card Image
              </Typography>
              <img
                src={blogData.card_image}
                alt=""
                style={{ borderRadius: "10px" }}
              />
              <TextField
                sx={{ marginTop: "30px" }}
                name="card_image"
                label="URL"
                value={blogData.card_image}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "30px",
                width: "45%",
                backgroundColor: "#f0f0f0",
                boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.3)",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  color: "#49559d",
                  marginBottom: "10px",
                }}
              >
                Main Image
              </Typography>
              <img
                src={blogData.main_image}
                alt=""
                style={{ borderRadius: "10px" }}
              />
              <TextField
                sx={{ marginTop: "30px" }}
                name="main_image"
                label="URL"
                value={blogData.main_image}
                onChange={handleInputChange}
              />
            </Box>
          </Container>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <label style={{ fontWeight: "600", color: "#49559d" }}>
                Are Your Event dates Flex
              </label>
              <Switch
                name="is_public"
                checked={blogData.is_public}
                onChange={(event) => {
                  const newValue = event.target.checked;
                  handleInputChange({
                    target: {
                      name: "is_public",
                      value: newValue,
                    },
                  });
                }}
              />
            </div>
          </Box>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <h3
                style={{
                  fontWeight: "600",
                  color: "#49559d",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Mne
              </h3>

              <div>
                <TextField
                  name="title_me"
                  label="Title"
                  type="text"
                  fullWidth
                  value={blogData.title_me}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="short_description_me"
                  label="Short Description"
                  type="text"
                  multiline
                  rows={3}
                  fullWidth
                  value={blogData.short_description_me}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="big_description_me"
                  label="Description"
                  type="text"
                  multiline
                  rows={6}
                  fullWidth
                  value={blogData.big_description_me}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="slug_me"
                  label="Slug"
                  type="text"
                  fullWidth
                  value={blogData.slug_me}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="meta_title_me"
                  label="Meta Title"
                  type="text"
                  fullWidth
                  value={blogData.meta_title_me}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="meta_description_me"
                  label="Meta Description"
                  type="text"
                  fullWidth
                  value={blogData.meta_description_me}
                  onChange={handleInputChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <h3
                style={{
                  fontWeight: "600",
                  color: "#49559d",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Eng
              </h3>
              <div>
                <TextField
                  name="title_en"
                  label="Title"
                  type="text"
                  fullWidth
                  value={blogData.title_en}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="short_description_en"
                  label="Short Description"
                  type="text"
                  multiline
                  rows={3}
                  fullWidth
                  value={blogData.short_description_en}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="big_description_en"
                  label="Description"
                  type="text"
                  multiline
                  rows={6}
                  fullWidth
                  value={blogData.big_description_en}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="slug_en"
                  label="Slug"
                  type="text"
                  fullWidth
                  value={blogData.slug_en}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="meta_title_en"
                  label="Meta Title"
                  type="text"
                  fullWidth
                  value={blogData.meta_title_en}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  name="meta_description_en"
                  label="Meta Description"
                  type="text"
                  fullWidth
                  value={blogData.meta_description_en}
                  onChange={handleInputChange}
                />
              </div>
            </Grid>
          </Grid>
          <div>
            <TextField
              name="order"
              type="number"
              label="Order"
              fullWidth
              value={blogData.order}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Categories</InputLabel>
              <Select
                name="categories"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple
                fullWidth
                value={blogData.categories}
                label="VenueTypes"
                onChange={handleCategories}
              >
                {categoriesList?.map((list) => (
                  <MenuItem key={list.id} value={list.id}>
                    {list.name_me}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <TextField
              name="gallery"
              type="number"
              label="Gallery"
              fullWidth
              value={blogData.gallery}
              onChange={handleInputChange}
            />
          </div>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button type="submit" variant="outlined">
              {location === "add-new-blog" ? "Publish" : "Save"}
            </Button>
          </Box>
        </form>
      </div>
    </Layout>
  );
}
