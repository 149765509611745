import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";

const ButtonBack = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Box style={{ display: "flex", marginLeft: "26%" }}>
      <Link onClick={handleBack} style={{ textDecoration: "none" }}>
        <Button variant="outlined">
          <ArrowBackIcon />
          Back
        </Button>
      </Link>
    </Box>
  );
};

export default ButtonBack;
