import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchExhibits = () => {
  return axios
    .get(`${API_URL}/exhibits`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteExhibit = (exhibitsId) => {
  return axios
    .delete(`${API_URL}/exhibits/${exhibitsId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju dokumenta: ${error.message}`);
    });
};

export const getExhibit = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/exhibits/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju dokumenta: ${error.message}`);
  }
};

export const editExhibit = async (id, exhibitsData) => {
  try {
    const response = await axios.put(`${API_URL}/exhibits/${id}`, exhibitsData);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju dokumenta: ${error.message}`);
  }
};

export const addExhibit = (exhibitsData) => {
  return axios
    .post(`${API_URL}/exhibits`, exhibitsData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju dokumenta: ${error.message}`);
    });
};
