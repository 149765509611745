import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addVenuePhotoGallery,
  getVenuePhotoGallery,
  editVenuePhotoGallery,
} from "../../../services/venues/venuePhotoGalleryAPI";
import ButtonBack from "../../../components/ButtonBack";

const AddEditVenuePhotoGallery = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode = location === "add-new-venue-photo-gallery" ? false : true;

  const [venuePhotoGalleryData, setVenuePhotoGalleryData] = useState({
    name: "",
    image: "",
    altText: "",
  });

  useEffect(() => {
    if (id) {
      getVenuePhotoGallery(id)
        .then((data) => {
          setVenuePhotoGalleryData({
            name: data.name,
            image: data.image,
            altText: data.alt_text,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setVenuePhotoGalleryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newVenuePhotoGalleryData = {
      name: venuePhotoGalleryData.name,
      image: venuePhotoGalleryData.image,
      alt_text: venuePhotoGalleryData.altText,
    };
    if (isEditMode) {
      editVenuePhotoGallery(id, newVenuePhotoGalleryData)
        .then((response) => {
          setVenuePhotoGalleryData({
            name: "",
            image: "",
            altText: "",
          });

          navigate("/venue-photo-gallery");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju ", error);
        });
    } else {
      addVenuePhotoGallery(newVenuePhotoGalleryData)
        .then((response) => {
          setVenuePhotoGalleryData({
            ...venuePhotoGalleryData,
            name: response.name,
            image: response.image,
            altText: response.alt_text,
          });
          navigate("/venue-photo-gallery");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Venue Photo Gallery
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="name"
                    label="Name"
                    required
                    value={venuePhotoGalleryData.name}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="image"
                    label="Image"
                    required
                    value={venuePhotoGalleryData.image}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="altText"
                    label="Alt Text"
                    required
                    value={venuePhotoGalleryData.altText}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </div>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-venue-photo-gallery"
                    ? "Publish"
                    : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditVenuePhotoGallery;
