import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import Layout from "../../../layout/Layout";
import { Link } from "react-router-dom";

import TableChartIcon from "@mui/icons-material/TableChart";
import Pagination from "@mui/material/Pagination";
import {
  fetchGeneralInformation,
  deleteGeneralInformation,
} from "../../../services/coworking/generalInformationAPI";

export default function CoworkingGeneralInformation() {
  const [generalInformation, setGeneralInformation] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("title");
  const [isTabularView, setIsTabularView] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [generalInformationIdToDelete, setGeneralInformationIdToDelete] =
    useState([]);
  const itemsPerPage = 6;

  useEffect(() => {
    fetchGeneralInformation()
      .then((data) => {
        setGeneralInformation(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDelete = () => {
    if (generalInformationIdToDelete) {
      deleteGeneralInformation(generalInformationIdToDelete)
        .then(() => {
          setDeleteDialogOpen(false);
          setGeneralInformation((prevGeneralInformation) =>
            prevGeneralInformation.filter(
              (generalInfo) => generalInfo.id !== generalInformationIdToDelete
            )
          );
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };

  const handleDeleteButtonClicked = (generalInfoId) => {
    setDeleteDialogOpen(true);
    setGeneralInformationIdToDelete(generalInfoId);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleTabularViewToggle = () => {
    setIsTabularView(!isTabularView);
  };

  const filteredData = generalInformation.filter((item) => {
    if (searchBy === "title") {
      return item.title.toLowerCase().includes(searchValue.toLowerCase());
    }
    return true;
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  return (
    <Layout>
      <div>
        <div>
          <TextField
            value={searchValue}
            onChange={handleSearchInputChange}
            label="Search"
            variant="outlined"
            fullWidth
          />

          <select value={searchBy} onChange={handleSearchByChange}>
            <option value="title">Title</option>
          </select>
        </div>

        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Coworking General Information
        </Typography>

        <div>
          <Link
            to={{
              pathname: "/add-new-coworking-general-information",
              state: { isEditMode: false },
            }}
          >
            <Button variant="outlined">Add New</Button>
          </Link>

          <Button
            startIcon={<TableChartIcon />}
            onClick={handleTabularViewToggle}
          ></Button>
        </div>

        {filteredData.length === 0 ? (
          <Typography
            variant="h6"
            style={{
              marginTop: "20px",
              color: "red",
              textAlign: "center",
            }}
          >
            There are no results
          </Typography>
        ) : (
          ""
        )}

        {!isTabularView ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Icon Alt Text</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.reverse().map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>{item.icon_alt_text}</TableCell>
                    <TableCell>
                      <div>
                        <Link
                          to={{
                            pathname: `/edit-coworking-general-information/${item.id}`,
                            state: { isEditMode: true },
                          }}
                        >
                          <Button variant="outlined">Edit Item</Button>
                        </Link>
                        <Button
                          variant="outlined"
                          onClick={() => handleDeleteButtonClicked(item.id)}
                        >
                          Delete
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid container spacing={2}>
            {paginatedData.reverse().map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {item.title}
                    </Typography>
                    <Typography color="textSecondary">
                      {item.icon_alt_text}
                    </Typography>
                    <Link
                      to={{
                        pathname: `/edit-coworking-general-information/${item.id}`,
                        state: { isEditMode: true },
                      }}
                    >
                      <Button variant="outlined">Edit item</Button>
                    </Link>
                    <Button
                      variant="outlined"
                      onClick={() => handleDeleteButtonClicked(item.id)}
                    >
                      Delete
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        <Pagination
          page={currentPage}
          onChange={handlePageChange}
          style={{ marginTop: "1rem", float: "right" }}
          count={Math.ceil(filteredData.length / itemsPerPage)}
        />

        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Delete Confirmation</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this general information?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              onClick={handleDelete}
              color="error"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Layout>
  );
}
