import axios from "axios";
import { API_URL } from "./const";

export const fetchReviews = async () => {
  return axios
    .get(`${API_URL}/coworking/review`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteReview = async (id) => {
  return axios
    .delete(`${API_URL}/coworking/review/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const addReview = async (data) => {
  return axios
    .post(`${API_URL}/coworking/review`, data)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getReview = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/coworking/review/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editReview = async (id, data) => {
  try {
    const response = await axios.put(`${API_URL}/coworking/review/${id}`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
