import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  OutlinedInput,
  Box,
} from "@mui/material";
import Layout from "../../../layout/Layout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addUnit,
  editUnit,
  getUnit,
} from "../../../services/coworking/unit/Unit";
import { fetchAmenitiesSections } from "../../../services/coworking/unit/AmenitiesSectionAPI";
import { fetchExhibits } from "../../../services/coworking/unit/ExhibitAPI";
import { fetchPhotoGalleries } from "../../../services/coworking/unit/PhotoGalleryAPI";
import { fetchMeetingRooms } from "../../../services/coworking/unit/MeetingRoom";

const AddEditCoworkingUnits = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);
  const [data, setData] = useState({
    name: "",
    total_number_of_people: "",
    description: "",
    size: "",
    ceiling_height: "",
    dimensions: "",
    featured_image: "",
    floor_plan_image: "",
    slug: "",
    meta_keyword: "",
    meta_title: "",
    meta_description: "",
    photo_gallery: [],
    meeting_room: [],
    exhibits: [],
    amenities_sections: [],
  });
  const [photoGalleries, setPhotoGalleries] = useState([]);
  const [meetingRooms, setMeetingRooms] = useState([]);
  const [exhibits, setExhibits] = useState([]);
  const [amenities, setAmenities] = useState([]);

  const isEditMode = location === "add-new-coworking-unit" ? false : true;

  const [selectedTab, setSelectedTab] = useState("general");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEditMode) {
      editUnit(id, data)
        .then(() => {
          navigate("/coworking-unit");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju kategorije:", error);
        });
    } else {
      addUnit(data)
        .then(() => {
          navigate("/coworking-unit");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju kategorije:", error);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (id) {
      getUnit(id)
        .then((data) => {
          setData({
            name: data.name,
            total_number_of_people: data.total_number_of_people,
            description: data.description,
            size: data.size,
            ceiling_height: data.ceiling_height,
            dimensions: data.dimensions,
            featured_image: data.featured_image,
            floor_plan_image: data.floor_plan_image,
            slug: data.slug,
            meta_keyword: data.meta_keyword,
            meta_title: data.meta_title,
            meta_description: data.meta_description,
            photo_gallery: data.photo_gallery
              ? data.photo_gallery?.map((list) => list.id)
              : [],
            meeting_room: data.meeting_room
              ? data.meeting_room?.map((list) => list.id)
              : [],
            exhibits: data.exhibits
              ? data.exhibits?.map((list) => list.id)
              : [],
            amenities_sections: data.amenities_sections
              ? data.amenities_sections?.map((list) => list.id)
              : [],
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchAmenitiesSections().then((data) => {
      setAmenities(data);
    });
    fetchExhibits().then((data) => {
      setExhibits(data);
    });
    fetchPhotoGalleries().then((data) => {
      setPhotoGalleries(data);
    });
    fetchMeetingRooms().then((data) => {
      setMeetingRooms(data);
    });
  }, []);

  const handleSelectAmenities = (event) => {
    setData((prevData) => ({
      ...prevData,
      amenities_sections: event.target.value,
    }));
  };

  const handleSelectExhibits = (event) => {
    setData((prevData) => ({
      ...prevData,
      exhibits: event.target.value,
    }));
  };

  const handleSelectMeetingRooms = (event) => {
    setData((prevData) => ({
      ...prevData,
      meeting_room: event.target.value,
    }));
  };

  const handleSelectPhotoGalleries = (event) => {
    setData((prevData) => ({
      ...prevData,
      photo_gallery: event.target.value,
    }));
  };
  return (
    <Layout>
      <div>
        <Box style={{ display: "flex" }}>
          <Link to="/coworking-unit" style={{ textDecoration: "none" }}>
            <Button variant="outlined" onClick={handleBack}>
              <ArrowBackIcon />
              Back
            </Button>
          </Link>
        </Box>
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Coworking Units
        </Typography>
        <Container
          sx={{
            marginTop: "10px",
            marginBottom: "15px",
            display: "flex",
            justifyContent: "space-around",
            paddingBottom: "15px",
            paddingTop: "15px",
            borderTop: "2px solid #49559d",
            borderBottom: "2px solid #49559d",
          }}
        >
          <Button variant="outlined" onClick={() => handleTabChange("general")}>
            General Info
          </Button>
          <Button variant="outlined" onClick={() => handleTabChange("meta")}>
            Meta Tags
          </Button>
          <Button variant="outlined" onClick={() => handleTabChange("other")}>
            Other
          </Button>
        </Container>
        <form onSubmit={handleSubmit}>
          {selectedTab === "general" && (
            <>
              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="name"
                  label="Name"
                  fullWidth
                  value={data.name}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="total_number_of_people"
                  label="Total Number Of People"
                  type="number"
                  fullWidth
                  value={data.total_number_of_people}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="description"
                  label="Description"
                  fullWidth
                  value={data.description}
                  onChange={handleInputChange}
                />
                <div>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="size"
                    label="Size"
                    type="number"
                    fullWidth
                    value={data.size}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="ceiling_height"
                  label="Ceiling Height"
                  type="number"
                  fullWidth
                  value={data.ceiling_height}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="dimensions"
                  label="Dimensions"
                  fullWidth
                  value={data.dimensions}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="featured_image"
                  label="Featured Image"
                  fullWidth
                  value={data.featured_image}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="floor_plan_image"
                  label="Floor Plan Image"
                  fullWidth
                  value={data.floor_plan_image}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="slug"
                  label="slug"
                  fullWidth
                  value={data.slug}
                  onChange={handleInputChange}
                />
              </div>
            </>
          )}

          {selectedTab === "meta" && (
            <>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="meta_keyword"
                  label="Meta Keyword"
                  fullWidth
                  value={data.meta_keyword}
                  onChange={handleInputChange}
                />
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="meta_title"
                  label="Meta Title"
                  fullWidth
                  value={data.meta_title}
                  onChange={handleInputChange}
                />
                <TextField
                  sx={{ marginTop: "30px" }}
                  name="meta_description"
                  label="Meta Description"
                  fullWidth
                  value={data.meta_description}
                  onChange={handleInputChange}
                />
              </Container>
            </>
          )}

          {selectedTab === "other" && (
            <>
              <FormControl sx={{ marginTop: "30px" }} fullWidth>
                <InputLabel id="demo-multiple-name-label">
                  Coworking Photo Gallery
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={data.photo_gallery}
                  onChange={handleSelectPhotoGalleries}
                  input={<OutlinedInput label="Name" />}
                >
                  {photoGalleries?.map((list) => (
                    <MenuItem key={list.id} value={list.id}>
                      <img src={list.image} alt={list.alt_text} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ marginTop: "30px" }} fullWidth>
                <InputLabel id="demo-multiple-name-label">
                  Coworking Meeting Rooms
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={data.meeting_room}
                  onChange={handleSelectMeetingRooms}
                  input={<OutlinedInput label="Name" />}
                >
                  {meetingRooms?.map((list) => (
                    <MenuItem key={list.id} value={list.id}>
                      {list.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ marginTop: "30px" }} fullWidth>
                <InputLabel id="demo-multiple-name-label">
                  Coworking Exhibits
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={data.exhibits}
                  onChange={handleSelectExhibits}
                  input={<OutlinedInput label="Name" />}
                >
                  {exhibits?.map((list) => (
                    <MenuItem key={list.id} value={list.id}>
                      {list.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ marginTop: "30px" }} fullWidth>
                <InputLabel id="demo-multiple-name-label">
                  Coworking Amenities
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={data.amenities_sections}
                  onChange={handleSelectAmenities}
                  input={<OutlinedInput label="Name" />}
                >
                  {amenities?.map((list) => (
                    <MenuItem key={list.id} value={list.id}>
                      {list.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              {location === "add-new-coworking-unit" ? "Publish" : "Save"}
            </Button>
          </Container>
        </form>
      </div>
    </Layout>
  );
};

export default AddEditCoworkingUnits;
