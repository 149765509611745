import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchTypeOfGuestRoomGalleries = () => {
  return axios
    .get(`${API_URL}/type-of-guest-room-gallery`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteTypeOfGuestRoomGallery = (typeOfGuestRoomGalleryId) => {
  return axios
    .delete(`${API_URL}/type-of-guest-room-gallery/${typeOfGuestRoomGalleryId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju dokumenta: ${error.message}`);
    });
};

export const getTypeOfGuestRoomGallery = async (id) => {
  try {
    const response = await axios.get(
      `${API_URL}/type-of-guest-room-gallery/${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju dokumenta: ${error.message}`);
  }
};

export const editTypeOfGuestRoomGallery = async (
  id,
  typeOfGuestRoomGalleryData
) => {
  try {
    const response = await axios.put(
      `${API_URL}/type-of-guest-room-gallery/${id}`,
      typeOfGuestRoomGalleryData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju dokumenta: ${error.message}`);
  }
};

export const addTypeOfGuestRoomGallery = (typeOfGuestRoomGalleryData) => {
  return axios
    .post(`${API_URL}/type-of-guest-room-gallery`, typeOfGuestRoomGalleryData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju dokumenta: ${error.message}`);
    });
};
