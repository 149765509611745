import React from "react";
import { Button, Container, Typography, TextField } from "@mui/material";
import Modal from "react-modal";

const GalleryModal = ({
  isFormOpenGallery,
  closeFormModalGallery,
  handleGallerySubmit,
  galleryListData,
  handleInputChangeGallery,
}) => {
  return (
    <Modal
      isOpen={isFormOpenGallery}
      onRequestClose={closeFormModalGallery}
      contentLabel="Add New Unit Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(73, 85, 157, 0.5)",
        },
        content: {
          backgroundColor: "white",
          top: "20%",
          left: "29%",
          right: "5%",
          bottom: "15%",
          zIndex: "10",
        },
      }}
    >
      <div>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: "center", marginTop: "30px" }}
        >
          Gallery
        </Typography>
        <form onSubmit={handleGallerySubmit}>
          <Container
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <TextField
              sx={{ marginTop: "30px", width: "45%" }}
              name="name"
              label="Name"
              required
              value={galleryListData.name}
              onChange={handleInputChangeGallery}
            />
            <TextField
              sx={{ marginTop: "30px", width: "45%" }}
              name="image"
              label="Image"
              required
              value={galleryListData.image}
              onChange={handleInputChangeGallery}
            />
            <TextField
              sx={{ marginTop: "30px", width: "45%" }}
              name="alt_text"
              label="Alt Text"
              value={galleryListData.alt_text}
              onChange={handleInputChangeGallery}
              inputProps={{ step: "0.01", min: "0" }}
            />
          </Container>
          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              Publish
            </Button>
          </Container>
        </form>
      </div>
      <Button variant="outlined" onClick={closeFormModalGallery}>
        Close
      </Button>
    </Modal>
  );
};

export default GalleryModal;
