import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container, Box } from "@mui/material";
import Layout from "../../../../layout/Layout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addPhotoGallery,
  editPhotoGallery,
  getPhotoGallery,
} from "../../../../services/coworking/unit/PhotoGalleryAPI";

const AddEditCoworkingUnitPhotoGallery = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);
  const [data, setData] = useState({
    image: "",
    alt_text: "",
  });

  const isEditMode =
    location === "add-new-coworking-units-photo-gallery" ? false : true;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEditMode) {
      editPhotoGallery(id, data)
        .then(() => {
          navigate("/coworking-units-photo-gallery");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju kategorije:", error);
        });
    } else {
      addPhotoGallery(data)
        .then(() => {
          navigate("/coworking-units-photo-gallery");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju kategorije:", error);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      getPhotoGallery(id)
        .then((data) => {
          setData({
            image: data.image,
            alt_text: data.alt_text,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  return (
    <Layout>
      <div>
        <Box style={{ display: "flex" }}>
          <Link
            to="/coworking-units-photo-gallery"
            style={{ textDecoration: "none" }}
          >
            <Button variant="outlined" onClick={handleBack}>
              <ArrowBackIcon />
              Back
            </Button>
          </Link>
        </Box>
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Coworking Unit Photo Gallery
        </Typography>

        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="image"
              label="Image"
              fullWidth
              value={data.image}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <TextField
              sx={{ marginTop: "30px" }}
              name="alt_text"
              label="Alt Text"
              fullWidth
              value={data.alt_text}
              onChange={handleInputChange}
            />
          </div>

          <Container
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              {location === "add-new-coworking-units-photo-gallery"
                ? "Publish"
                : "Save"}
            </Button>
          </Container>
        </form>
      </div>
    </Layout>
  );
};

export default AddEditCoworkingUnitPhotoGallery;
