import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import Layout from "../layout/Layout";
import {
  Typography,
  Container,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  fetchFiles,
  deleteFile,
  getFile,
  editFile,
  addFile,
} from "../services/fileAPI";
import GalleryMenu from "../components/GalleryMenu";

export default function Gallery() {
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [imageToEdit, setImageToEdit] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [activeButton, setActiveButton] = useState("All");
  // const [visibleImages, setVisibleImages] = useState(8);
  // const imagesPerPage = 8;

  const [data, setData] = useState({
    name: "",
    path: "",
    alt_image_me: "",
    alt_image_en: "",
    folder_id: 1,
    profile_image: "",
  });

  useEffect(() => {
    fetchFiles()
      .then((data) => {
        setFiles(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (id) {
      getFile(id)
        .then((data) => {
          setData({
            name: data.name,
            path: data.path,
            alt_image_me: data.alt_image_me,
            alt_image_en: data.alt_image_en,
          });
        })
        .catch((error) => {
          console.error(error);
        });
      console.log(id);
    }
  }, [id]);

  const handleDelete = () => {
    if (imageToDelete) {
      deleteFile(imageToDelete)
        .then(() => {
          setDeleteDialogOpen(false);
          setFiles((prevFiles) =>
            prevFiles.filter((file) => file.id !== imageToDelete)
          );
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    editFile(imageToEdit, data)
      .then(() => {
        alert("Uspjesno editovana slika");
        setEditDialogOpen(false);
        setFiles((prevFiles) =>
          prevFiles.map((file) => {
            if (file.id === imageToEdit) {
              return {
                ...file,
                name: data.name,
                alt_image_me: data.alt_image_me,
                alt_image_en: data.alt_image_en,
              };
            }
            return file;
          })
        );
      })
      .catch((error) => {
        console.log("Greska");
        console.error("Greška pri editovanju:", error);
      });
    console.log(data);
  };

  const handleEditButtonClicked = (imageId) => {
    const selectedImage = files.find((image) => image.id === imageId);
    if (selectedImage) {
      setData({
        name: selectedImage.name,
        alt_image_me: selectedImage.alt_image_me,
        alt_image_en: selectedImage.alt_image_en,
        folder_id: selectedImage.folder_id,
      });
      setEditDialogOpen(true);
      setImageToEdit(imageId);
    }
  };

  const handleCloseAddDialog = () => {
    setAddDialogOpen(false);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  const handleDeleteButtonClicked = (imageId) => {
    setDeleteDialogOpen(true);
    setImageToDelete(imageId);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };
  // const loadMoreImages = () => {
  //   setVisibleImages(visibleImages + imagesPerPage);
  // };

  async function handleFileUpload(e) {
    const files = e.target.files || (e.dataTransfer && e.dataTransfer.files);

    if (files.length > 0) {
      const allowedExtensions = ["jpeg", "jpg", "png", "webp"];

      setFiles([]);

      for (const file of files) {
        const fileName = file.name.toLowerCase();
        const fileExtension = fileName.split(".").pop();

        if (
          allowedExtensions.includes(fileExtension) &&
          file.size <= 2 * 1024 * 1024
        ) {
          setFiles((prevFiles) => [...prevFiles, file]);
        } else {
          alert(
            "Molimo vas da odaberete sliku manju od 2 MB i sa dozvoljenim ekstenzijama."
          );
        }
      }

      // Postavljanje prvog odabrane slike kao trenutno selektovane
      setSelectedFile(files[0]);
      setData({
        name: files[0].name,
        alt_image_me: "",
        alt_image_en: "",
        folder_id: 1,
      });

      setAddDialogOpen(true);
    }
  }

  const handleFileSubmit = async (e) => {
    e.preventDefault();

    if (files.length > 0) {
      try {
        setFiles([]);
        for (const file of files) {
          const formData = new FormData();
          formData.append("profile_image", file);
          formData.append("name", file.name);
          formData.append("alt_image_me", file.alt_image_me);
          formData.append("alt_image_en", file.alt_image_en);
          formData.append("folder_id", 1);
          formData.append("path", "selectedFile");

          const response = await addFile(formData);
          setFiles((prevImages) => [...prevImages, response]);
        }

        console.log(files);

        const allImages = await fetchFiles();
        setFiles(allImages);

        setAddDialogOpen(false);
      } catch (error) {
        console.error("Greška pri dodavanju slika:", error);
      }
    }
  };

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  }

  function handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = e.dataTransfer.files;

    handleFileUpload({ target: { files: droppedFiles } });
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const sortedData = files
    .slice()
    .sort((a, b) => a.order_venue_in_list - b.order_venue_in_list);

  const filteredData = sortedData
    .filter((item) => {
      // Implementirajte filtriranje na osnovu ekstenzije
      if (activeButton === "All") {
        return true; // Ako je odabran "All", prikaži sve slike
      } else {
        const allowedExtensions = {
          JPG: ["jpg"],
          JPGE: ["jpge"],
          PNG: ["png"],
          WEBP: ["webp"],
        };

        const fileExtension = item.name.split(".").pop().toLowerCase();

        return allowedExtensions[activeButton].includes(fileExtension);
      }
    })
    .filter((item) =>
      (item.name || "").toLowerCase().includes(searchValue.toLowerCase())
    );

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    // Dodajte logiku koju želite izvršiti kada se pritisne gumb
    // Na primjer, možete ažurirati prikazani sadržaj ovisno o odabranom gumbu
  };

  return (
    <Layout>
      <GalleryMenu />
      <div style={{ width: "73%", marginLeft: "26%" }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: "center", marginBottom: "20px" }}
        >
          Venue Gallery
        </Typography>
        <Container
          style={{
            backgroundColor: "#49559d",
            width: "90%",
            height: "125px",
            borderRadius: "15px",
            cursor: "pointer",
          }}
          onClick={() => fileInputRef.current.click()}
          onDragOver={(e) => handleDragOver(e)}
          onDragEnter={(e) => handleDragEnter(e)}
          onDragLeave={(e) => handleDragLeave(e)}
          onDrop={(e) => handleDrop(e)}
        >
          <input
            type="file"
            name="profile_image"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={(e) => handleFileUpload(e)}
            multiple
          />
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: "center", color: "white", paddingTop: "20px" }}
          >
            Drag and Drop Images here or simply click here
          </Typography>
          <Box style={{ textAlign: "center" }}>
            <FileUploadIcon fontSize="large" style={{ color: "white" }} />
          </Box>
        </Container>
        <Container
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: "30px",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "40%",
            }}
          >
            <Button
              variant="outlined"
              style={{
                backgroundColor: activeButton === "All" ? "#49559d" : "white",
                color: activeButton === "All" ? "#f0f0f0" : "",
              }}
              onClick={() => handleButtonClick("All")}
            >
              All
            </Button>
            <Button
              variant="outlined"
              style={{
                backgroundColor: activeButton === "JPG" ? "#49559d" : "white",
                color: activeButton === "JPG" ? "#f0f0f0" : "",
              }}
              onClick={() => handleButtonClick("JPG")}
            >
              JPG
            </Button>
            <Button
              variant="outlined"
              style={{
                backgroundColor: activeButton === "JPGE" ? "#49559d" : "white",
                color: activeButton === "JPGE" ? "#f0f0f0" : "",
              }}
              onClick={() => handleButtonClick("JPGE")}
            >
              JPGE
            </Button>
            <Button
              variant="outlined"
              style={{
                backgroundColor: activeButton === "PNG" ? "#49559d" : "white",
                color: activeButton === "PNG" ? "#f0f0f0" : "",
              }}
              onClick={() => handleButtonClick("PNG")}
            >
              PNG
            </Button>
            <Button
              variant="outlined"
              style={{
                backgroundColor: activeButton === "WEBP" ? "#49559d" : "white",
                color: activeButton === "WEBP" ? "#f0f0f0" : "",
              }}
              onClick={() => handleButtonClick("WEBP")}
            >
              WEBP
            </Button>
          </Box>
          <Box style={{ width: "40%" }}>
            <TextField
              value={searchValue}
              onChange={handleSearchInputChange}
              label="Search by image name"
              variant="outlined"
              fullWidth
            />
          </Box>
        </Container>
        <Container
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "40px",
          }}
        >
          {filteredData
            .sort((a, b) => b.id - a.id)
            .map((item) => (
              <Box
                key={item.id}
                style={{
                  margin: "10px",
                  position: "relative",
                  width: "calc(25% - 20px)",
                  backgroundColor: "#f0f0f0",
                  boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.3)",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    color: "#49559d",
                    textTransform: "uppercase",
                    margin: "10px auto",
                  }}
                >
                  {(item.name || "").substring(0, 20)}
                </Typography>
                <div
                  style={{
                    position: "relative",
                    height: "165px",
                    width: "250px",
                    marginBottom: "15px",
                  }}
                >
                  <img
                    src={item.path}
                    alt={item.alt_image_me}
                    style={{
                      width: "100%",
                      maxHeight: "100%",
                      borderRadius: "5px",
                      boxShadow: "1px 3px 5px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                </div>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      const textArea = document.createElement("textarea");
                      textArea.value = item.path;
                      document.body.appendChild(textArea);
                      textArea.select();
                      document.execCommand("copy");
                      document.body.removeChild(textArea);
                      alert("URL copied to clipboard");
                    }}
                  >
                    Copy URL
                  </Button>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        paddingRight: "10px",
                      }}
                      onClick={() => handleEditButtonClicked(item.id)}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.color = "#49559d";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.color = "";
                      }}
                    >
                      <EditIcon />
                    </div>

                    <div
                      style={{
                        cursor: "pointer",
                        padding: "0",
                      }}
                      onClick={() => handleDeleteButtonClicked(item.id)}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.color = "#49559d";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.color = "";
                      }}
                    >
                      <DeleteForeverIcon />
                    </div>
                  </Box>
                </Box>
              </Box>
            ))}
        </Container>
      </div>
      {/* {visibleImages < files.length && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0",
          }}
        >
          <Button variant="outlined" onClick={loadMoreImages}>
            Load More
          </Button>
        </Box>
      )} */}
      <Dialog open={addDialogOpen} onClose={handleCloseAddDialog}>
        <DialogTitle>Add Images</DialogTitle>
        <form onSubmit={handleFileSubmit} encType="multipart/form-data">
          <DialogContent>
            {files.map((file, index) => (
              <div key={index}>
                <Typography>{`Selected file ${index + 1}: ${
                  file.name
                }`}</Typography>
                {/* <img
                  src={URL.createObjectURL(file)}
                  alt={`Preview ${file.name}`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "150px",
                    marginTop: "10px",
                  }}
                /> */}
                <TextField
                  sx={{ marginTop: "10px" }}
                  name={`alt_image_me_${index}`}
                  label="Alt MNE"
                  value={data[`alt_image_me_${index}`]}
                  fullWidth
                  onChange={handleInputChange}
                />
                <TextField
                  sx={{ marginTop: "10px" }}
                  name={`alt_image_en_${index}`}
                  label="Alt ENG"
                  value={data[`alt_image_en_${index}`]}
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" type="submit">
              Add Images
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Image</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              sx={{ marginTop: "30px" }}
              name="name"
              label="Name"
              value={data.name}
              fullWidth
              onChange={handleInputChange}
            />
            <TextField
              sx={{ marginTop: "30px" }}
              name="alt_image_me"
              label="Alt MNE"
              value={data.alt_image_me}
              fullWidth
              onChange={handleInputChange}
            />
            <TextField
              sx={{ marginTop: "30px" }}
              name="alt_image_en"
              label="Alt ENG"
              value={data.alt_image_en}
              fullWidth
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ m: 1, width: "25ch" }}
              type="submit"
              size="medium"
              variant="outlined"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this Venue?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            onClick={handleDelete}
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}
