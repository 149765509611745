import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import Layout from "../../../../layout/Layout";
import { Link } from "react-router-dom";

import TableChartIcon from "@mui/icons-material/TableChart";
import Pagination from "@mui/material/Pagination";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  deleteAmenitiesSection,
  fetchAmenitiesSections,
} from "../../../../services/coworking/unit/AmenitiesSectionAPI";
import { PostAddOutlined } from "@mui/icons-material";

const pages = [
  {
    name: "Coworking Units Amenities List",
    icon: <PostAddOutlined />,
    link: "/coworking-units-amenities-list",
  },
];

export default function CoworkingUnitsAmenitiesSection() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("title");
  const [isTabularView, setIsTabularView] = useState(false);
  const [data, setData] = useState([]);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [active, setActive] = useState("");
  const itemsPerPage = 6;

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };
  const handleDelete = () => {
    if (dataToDelete) {
      deleteAmenitiesSection(dataToDelete)
        .then(() => {
          setDeleteDialogOpen(false);
          setData((prevData) =>
            prevData.filter((data) => data.id !== dataToDelete)
          );
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
    setDeleteDialogOpen(false); // Close the dialog after delete
  };

  const handleDeleteButtonClicked = (id) => {
    setDeleteDialogOpen(true);
    setDataToDelete(id);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleTabularViewToggle = () => {
    setIsTabularView(!isTabularView);
  };

  const handleItemClick = (name) => {
    setActive(name);
  };

  const filteredData = data.filter((item) => {
    if (searchValue === "") return true;
    if (searchBy === "title") {
      return item.title.toLowerCase().includes(searchValue.toLowerCase());
    } else if (searchBy === "category") {
      return item.category.toLowerCase().includes(searchValue.toLowerCase());
    }
    return true;
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  useEffect(() => {
    fetchAmenitiesSections().then((data) => {
      setData(data);
    });
  }, []);

  return (
    <Layout>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            width: "25%",
            height: "100%",
            borderRight: "1px solid #ccc",
            boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.1)",
            marginTop: "-50px",
          }}
        >
          <div style={{ marginTop: "20px", marginLeft: "20px" }}>
            <Link to="/coworking-unit" style={{ textDecoration: "none" }}>
              <Button variant="outlined">
                <ArrowBackIcon />
                Back
              </Button>
            </Link>
          </div>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ textAlign: "center", paddingTop: "20px" }}
          >
            Coworking Distance Section Menu
          </Typography>
          <div
            style={{
              height: "82vh",
              overflowY: "auto",
              borderTop: "1px solid #ccc",
            }}
          >
            <List>
              {pages.map((page) => (
                <ListItem key={page.name} disablePadding>
                  <Box style={{ width: "100%" }}>
                    <Link
                      to={page.link}
                      onClick={() => handleItemClick(page.name)}
                      style={{
                        textDecoration: "none",
                        color: active === page.name ? "#49559d" : "#5A5A5A",
                        backgroundColor:
                          active === page.name ? "#49559d" : "#e2e2e2",
                        width: "100%",
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon
                          style={{
                            color: active === page.name ? "#49559d" : "#49559d",
                          }}
                        >
                          {page.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={page.name}
                          style={{
                            color: active === page.name ? "#49559d" : "#49559d",
                          }}
                        />
                      </ListItemButton>
                    </Link>
                  </Box>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
        <div style={{ width: "79%" }}>
          <div>
            <div>
              <TextField
                value={searchValue}
                onChange={handleSearchInputChange}
                label="Search"
                variant="outlined"
                fullWidth
              />

              <select value={searchBy} onChange={handleSearchByChange}>
                <option value="title">Title</option>
              </select>
            </div>

            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Coworking Units Amenities Section
            </Typography>

            <div>
              <Link
                to={{
                  pathname: "/add-new-coworking-units-amenities-section",
                  state: { isEditMode: false },
                }}
              >
                <Button variant="outlined">Add New</Button>
              </Link>

              <Button
                startIcon={<TableChartIcon />}
                onClick={handleTabularViewToggle}
              ></Button>
            </div>

            {!isTabularView ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Amenities List</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.reverse().map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>
                          {item.lists.map((list) => (
                            <p>{list.title}</p>
                          ))}
                        </TableCell>
                        <TableCell>
                          <div>
                            <Link
                              to={{
                                pathname: `/edit-coworking-units-amenities-section/${item.id}`,
                                state: { isEditMode: true },
                              }}
                            >
                              <Button variant="outlined">Edit item</Button>
                            </Link>
                            <Button
                              variant="outlined"
                              onClick={() => handleDeleteButtonClicked(item.id)}
                            >
                              Delete
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Grid container spacing={2}>
                {paginatedData.reverse().map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" component="h2">
                          {item.title}
                        </Typography>
                        <Link
                          to={{
                            pathname: `/edit-coworking-units-amenities-section/${item.id}`,
                            state: { isEditMode: true },
                          }}
                        >
                          <Button variant="outlined">Edit item</Button>
                        </Link>
                        <Button
                          variant="outlined"
                          onClick={() => handleDeleteButtonClicked(item.id)}
                        >
                          Delete
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}

            <Pagination
              page={currentPage}
              onChange={handlePageChange}
              style={{ marginTop: "1rem", float: "right" }}
              count={Math.ceil(filteredData.length / itemsPerPage)}
            />

            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
              <DialogTitle>Delete Confirmation</DialogTitle>
              <DialogContent>
                <Typography>
                  Are you sure you want to delete this unit amenity section?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeleteDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  open={deleteDialogOpen}
                  onClose={() => setDeleteDialogOpen(false)}
                  onClick={handleDelete}
                  color="error"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </Layout>
  );
}
