import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container, Box } from "@mui/material";
import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addTestimonials,
  getTestimonials,
  editTestimonials,
} from "../../../services/venues/venueTestimonials";
import ButtonBack from "../../../components/ButtonBack";
import Modal from "react-modal";
import { fetchFiles } from "../../../services/fileAPI";

const AddEditTestimonials = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);

  const isEditMode = location === "add-new-testimonials" ? false : true;

  const [testimonialData, setTestimonialData] = useState({
    name: "",
    stars: "",
    reviewsText: "",
    image: "",
    position: "",
  });

  useEffect(() => {
    if (id) {
      getTestimonials(id)
        .then((data) => {
          setTestimonialData({
            name: data.name,
            stars: data.stars,
            reviewsText: data.reviews_text,
            image: data.image,
            position: data.position,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTestimonialData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newTestimonialData = {
      name: testimonialData.name,
      stars: testimonialData.stars,
      reviews_text: testimonialData.reviewsText,
      image: testimonialData.image,
      position: testimonialData.position,
    };
    if (isEditMode) {
      editTestimonials(id, newTestimonialData)
        .then((response) => {
          setTestimonialData({
            name: "",
            stars: "",
            reviewsText: "",
            image: "",
            position: "",
          });

          navigate("/testimonials");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju ", error);
        });
    } else {
      addTestimonials(newTestimonialData)
        .then((response) => {
          setTestimonialData({
            ...testimonialData,
            name: response.name,
            stars: response.stars,
            reviewsText: response.reviews_text,
            image: response.image,
            position: response.position,
          });
          navigate("/testimonials");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju", error);
        });
    }
  };

  ///////////
  //Image
  const [imageList, setImageList] = useState([]);
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const files = await fetchFiles();
        setImageList(files);
      } catch (error) {
        console.error("Greška pri dohvatanju slika:", error.message);
      }
    };

    fetchData();
  }, []);

  const openImageModal = () => {
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  const handleImageSelect = (selectedImage) => {
    setTestimonialData((prevData) => ({
      ...prevData,
      image: selectedImage,
    }));
    closeImageModal();
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Testimonials
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Container style={{ width: "45%" }}>
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="name"
                    label={isImageModalOpen ? "" : "Name"}
                    required
                    value={testimonialData.name}
                    fullWidth
                    onChange={handleInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="stars"
                    label={isImageModalOpen ? "" : "Stars"}
                    value={testimonialData.stars}
                    type="number"
                    fullWidth
                    inputProps={{ min: 1, max: 5 }}
                    onChange={handleInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="reviewsText"
                    label={isImageModalOpen ? "" : "Review Text"}
                    value={testimonialData.reviewsText}
                    fullWidth
                    onChange={handleInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="position"
                    label={isImageModalOpen ? "" : "Position"}
                    value={testimonialData.position}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </Container>
                <Container
                  style={{
                    width: "45%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "30px",
                    backgroundColor: "#f0f0f0",
                    boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.3)",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <Button
                    variant="outlined"
                    style={{ marginBottom: "30px" }}
                    onClick={openImageModal}
                  >
                    Add Image
                  </Button>
                  <TextField
                    sx={{ marginBottom: "30px" }}
                    name="icon"
                    fullWidth
                    label={isImageModalOpen ? "" : "Icon"}
                    value={testimonialData.image}
                    onChange={handleInputChange}
                  />
                  <img
                    src={testimonialData.image}
                    alt=""
                    style={{ width: "150px", borderRadius: "5px" }}
                  />
                </Container>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-testimonials" ? "Publish" : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isImageModalOpen}
        onRequestClose={closeImageModal}
        contentLabel="Choose Image"
        style={{
          overlay: {
            backgroundColor: "rgba(73, 85, 157, 0.5)",
          },
          content: {
            backgroundColor: "white",
            top: "20%",
            left: "29%",
            right: "5%",
            bottom: "15%",
            zIndex: "10",
          },
        }}
      >
        <div>
          <Typography
            style={{
              textAlign: "center",
              margin: "30px 0",
              color: "#49559d",
              fontWeight: "600",
              fontSize: "32px",
            }}
          >
            Choose an Image
          </Typography>
          <Box
            style={{
              listStyle: "none",
              padding: 0,
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {imageList.map((image) => (
              <img
                src={image.path}
                alt=""
                style={{
                  width: "200px",
                  padding: "20px",
                  cursor: "pointer",
                  borderRadius: "30px",
                }}
                onClick={() => handleImageSelect(image.path)}
              />
            ))}
          </Box>
          <Button
            variant="outlined"
            onClick={closeImageModal}
            style={{ marginTop: "20px" }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddEditTestimonials;
