import React, { useState, useEffect } from "react";
import { Button, Typography, Container } from "@mui/material";
import Layout from "../../layout/Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addVenue,
  getVenue,
  editVenue,
  fetchVenues,
} from "../../services/venues/venueAPI";
import { fetchDocumentations } from "../../services/venues/venueDocumentation";
import { fetchTypeOfEvents } from "../../services/venues/venueTypeOfEventAPI";
import { fetchGeneralInformation } from "../../services/venues/venueGeneralInformationAPI";
import {
  editVenuePhotoGallery,
  addVenuePhotoGallery,
  deleteVenuePhotoGallery,
  fetchVenuePhotoGalleries,
  getVenuePhotoGallery,
  addImageToGallery,
  removeImageFromGallery,
  getGalleryImages,
  checkIfImageExists,
} from "../../services/venues/venuePhotoGalleryAPI";
import { fetchReviews } from "../../services/venues/venueReviewsApi";
import { fetchSocialMedia } from "../../services/venues/VenueSocialMedia";
import { fetchTestimonials } from "../../services/venues/venueTestimonials";
import {
  fetchVenueUnits,
  deleteVenueUnit,
  addVenueUnit,
  editVenueUnit,
  addImageToUnit,
  removeImageFromUnit,
  getUnitImages,
  checkIfImageExistsUnit,
} from "../../services/venues/venueUnitAPI";
import { fetchAmenitiesLists } from "../../services/venues/amenitiesListAPI";
import {
  addDistanceList,
  deleteDistanceList,
  fetchDistanceLists,
  editDistanceList,
} from "../../services/venues/distanceListAPI";
import {
  fetchTypeOfGuestRooms,
  deleteTypeOfGuestRoom,
  addTypeOfGuestRoom,
  editTypeOfGuestRoom,
  checkIfImageExistsGuestRoom,
  getGuestRoomImages,
  addImageToGuestRoom,
  removeImageFromGuestRoom,
} from "../../services/venues/typeOfGuestRoomAPI";
import {
  addVenueVideoGallery,
  deleteVenueVideoGallery,
  fetchVenueVideoGalleries,
} from "../../services/venues/venueVideoGalleryAPI";
import { fetchCategories } from "../../services/venues/venueCategoryAPI";
import VenueMenu from "../../components/VenueMenu";
import { fetchExhibits } from "../../services/venues/unitExhibitsAPI";
import { fetchMeetingRoomCapacities } from "../../services/venues/unitMeetingRoomCapacitiesAPI";
import { fetchUnitPhotoGalleries } from "../../services/venues/unitPhotoGalleryAPI";
import { fetchUnitTypes } from "../../services/venues/unitType";
import { fetchCities } from "../../services/cityAPI";
import { fetchStyles } from "../../services/styleAPI";
import { fetchFiles, getFile } from "../../services/fileAPI";
import { fetchUnitAmenitiesLists } from "../../services/venues/unitAmenitiesListAPI";
import MainInfo from "../../components/Venue/MainInfo";
import OverviewInfo from "../../components/Venue/OverviewInfo";
import MediaGalleryInfo from "../../components/Venue/MediaGalleryInfo";
import DistanceInfo from "../../components/Venue/DistanceInfo";
import UnitInfo from "../../components/Venue/UnitInfo";
import GuestRoomInfo from "../../components/Venue/GuestRoomInfo";
import SeoInfo from "../../components/Venue/SeoInfo";
import MoreInfo from "../../components/Venue/MoreInfo";
import UnitModal from "../../components/Venue/Modals/UnitModal";
import GuestRoomModal from "../../components/Venue/Modals/GuestRoomModal";
import DistanceModal from "../../components/Venue/Modals/DistanceModal";
import GalleryModal from "../../components/Venue/Modals/GalleryModal";
import ImagesModal from "../../components/Venue/Modals/ImagesModal";
import VideoModal from "../../components/Venue/Modals/VideoModal";
import ButtonBack from "../../components/ButtonBack";

const AddEditVenue = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);
  const [isFormOpenUnit, setIsFormOpenUnit] = useState(false);
  const [isFormOpenGuestRoom, setIsFormOpenGuestRoom] = useState(false);
  const [isFormOpenDistance, setIsFormOpenDistance] = useState(false);
  const [isFormOpenGallery, setIsFormOpenGallery] = useState(false);
  const [isFormOpenVideo, setIsFormOpenVideo] = useState(false);
  const [isFormOpenImages, setIsFormOpenImages] = useState(false);
  const [currentGalleryId, setCurrentGalleryId] = useState(null);
  const [currentGuestRoomId, setCurrentGuestRoomId] = useState(null);
  const [currentUnitId, setCurrentUnitId] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);

  const [loading, setLoading] = useState(true);

  const closeFormModal = () => {
    setIsFormOpenUnit(false);
  };

  const closeFormModalGuestRoom = () => {
    setIsFormOpenGuestRoom(false);
  };

  const closeFormModalDistance = () => {
    setIsFormOpenDistance(false);
  };

  const closeFormModalGallery = () => {
    setIsFormOpenGallery(false);
  };

  const closeFormModalVideo = () => {
    setIsFormOpenVideo(false);
  };

  const closeFormModalImages = () => {
    setIsFormOpenImages(false);
  };

  const isEditMode = location === "add-new-venue" ? false : true;

  const [selectedTab, setSelectedTab] = useState("overview");
  const [selectedTabUnit, setSelectedTabUnit] = useState("general");

  const toggleUnitForm = () => {
    setIsFormOpenUnit(!isFormOpenUnit);
  };

  const toggleGuestRoomForm = () => {
    setIsFormOpenGuestRoom(!isFormOpenGuestRoom);
  };

  const toggleDistanceForm = () => {
    setIsFormOpenDistance(!isFormOpenDistance);
  };

  const toggleGalleryForm = () => {
    setIsFormOpenGallery(!isFormOpenGallery);
  };

  const toggleVideoForm = () => {
    setIsFormOpenVideo(!isFormOpenVideo);
  };

  const [path, setPath] = useState("");

  const toggleImageForm = (id, source) => {
    console.log(source);
    setPath(source);
    if (source === "gallery") {
      setCurrentGalleryId(id);
    } else if (source === "guestRoom") {
      setCurrentGuestRoomId(id);
    } else if (source === "featuredImage") {
      console.log(source);
    } else if (source === "unit") {
      setCurrentUnitId(id);
    } else if (source === "featuredImageUnit") {
      handleFeaturedImageUnitSelection();
    }
    setIsFormOpenImages(!isFormOpenImages);
  };

  const [venueData, setVenueData] = useState({
    name: "",
    featured_image: "",
    city: "data.city",
    cities: [],
    address: "",
    description: "",
    chain: "",
    styles: [],
    built: "",
    renovated: "",
    total_guest_rooms: "",
    brand: "",
    total_conference_room_space: "",
    total_number_of_conference_rooms: "",
    largest_rooms_space: "",
    total_number_of_people: "",
    altitude: "",
    longitude: "",
    slug: "",
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    order_venue_in_list: "",
    general_review_score: "",
    provider: "",
    is_public: false,
    venue_categories: [],
    documentations: [],
    events: [],
    general_informations: [],
    photo_galleries: [],
    reviews: [],
    social_media: [],
    testimonials: [],
    units: [],
    amenities_lists: [],
    guest_rooms: [],
    video_galleries: [],
    distance_lists: [],
    compare_right_venue_id: "",
    compare_left_venue_id: "",
    slider: false,
  });

  const fetchData = async () => {
    try {
      const [
        venues,
        categories,
        generalInformation,
        reviews,
        socialMedia,
        typeOfEvents,
        testimonials,
        amenitiesLists,
        documentations,
        citiesList,
        stylesList,
      ] = await Promise.all([
        fetchVenues(),
        fetchCategories(),
        fetchGeneralInformation(),
        fetchReviews(),
        fetchSocialMedia(),
        fetchTypeOfEvents(),
        fetchTestimonials(),
        fetchAmenitiesLists(),
        fetchDocumentations(),
        fetchCities(),
        fetchStyles(),
      ]);
      return {
        venues,
        categories,
        generalInformation,
        reviews,
        socialMedia,
        typeOfEvents,
        testimonials,
        amenitiesLists,
        documentations,
        citiesList,
        stylesList,
      };
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  useEffect(() => {
    fetchData().then((data) => {
      setVenue(data.venues);
      setCategoriesList(data.categories);
      setGeneralInformations(data.generalInformation);
      setReviewsList(data.reviews);
      setSocialMedia(data.socialMedia);
      setTypeOfEvent(data.typeOfEvents);
      setTestimonialsList(data.testimonials);
      setAmenitiesLists(data.amenitiesLists);
      setDocumentationsList(data.documentations);
      setCitiesList(data.citiesList);
      setStylesList(data.stylesList);
      setLoading(false);
    });
  }, []);

  const [venue, setVenue] = useState([]);
  const [generalInformations, setGeneralInformations] = useState([]);
  const [reviewsList, setReviewsList] = useState([]);
  const [socialMedia, setSocialMedia] = useState([]);
  const [typeOfEvent, setTypeOfEvent] = useState([]);
  const [testimonialsList, setTestimonialsList] = useState([]);
  const [amenitiesLists, setAmenitiesLists] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [documentationsList, setDocumentationsList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [stylesList, setStylesList] = useState([]);

  useEffect(() => {
    if (id) {
      getVenue(id)
        .then((data) => {
          setVenueData({
            name: data.name,
            featured_image: data.featured_image,
            city: "data.city",
            address: data.address,
            description: data.description,
            chain: data.chain,
            built: data.built,
            renovated: data.renovated,
            total_guest_rooms: data.total_guest_rooms,
            brand: data.brand,
            total_conference_room_space: data.total_conference_room_space,
            total_number_of_conference_rooms:
              data.total_number_of_conference_rooms,
            largest_rooms_space: data.largest_rooms_space,
            total_number_of_people: data.total_number_of_people,
            altitude: data.altitude,
            longitude: data.longitude,
            slug: data.slug,
            meta_title: data.meta_title,
            meta_description: data.meta_description,
            meta_keyword: data.meta_keyword,
            order_venue_in_list: data.order_venue_in_list,
            general_review_score: data.general_review_score,
            provider: data.provider,
            is_public: data.is_public,
            slider: data.slider,
            documentations: data.documentations
              ? data.documentations?.map((list) => list.id)
              : [],
            events: data.events ? data.events?.map((list) => list.id) : [],
            general_informations: data.general_informations
              ? data.general_informations?.map((list) => list.id)
              : [],
            photo_galleries: data.photo_galleries
              ? data.photo_galleries?.map((list) => list.id)
              : [],
            reviews: data.reviews ? data.reviews?.map((list) => list.id) : [],
            social_media: data.social_media
              ? data.social_media?.map((list) => list.id)
              : [],
            testimonials: data.testimonials
              ? data.testimonials?.map((list) => list.id)
              : [],
            units: data.units ? data.units?.map((list) => list.id) : [],
            amenities_lists: data.amenities_lists
              ? data.amenities_lists?.map((list) => list.id)
              : [],
            distance_lists: data.distance_lists
              ? data.distance_lists?.map((list) => list.id)
              : [],
            guest_rooms: data.guest_room
              ? data.guest_room?.map((list) => list.id)
              : [],
            video_galleries: data.video_galleries
              ? data.video_galleries?.map((list) => list.id)
              : [],
            venue_categories: data.venue_categories
              ? data.venue_categories?.map((list) => list.id)
              : [],
            styles: data.styles ? data.styles?.map((list) => list.id) : [],
            cities: data.cities ? data.cities?.map((list) => list.id) : [],
            compare_right_venue_id: data.compare_right_venue_id,
            compare_left_venue_id: data.compare_left_venue_id,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);
  const generateSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setVenueData((prevData) => {
      if (name === "name") {
        return {
          ...prevData,
          [name]: value,
          slug: generateSlug(value),
        };
      }

      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEditMode) {
      editVenue(id, venueData)
        .then(() => {
          navigate("/venues");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju bloga:", error);
        });
    } else {
      addVenue(venueData)
        .then(() => {
          navigate("/venues");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju bloga:", error);
        });
    }
  };

  const handleTabChange = (tab) => {
    if (!buttonClicked) {
      setButtonClicked(true);
    }
    switch (tab) {
      case "mediaGallery":
      case "distance":
      case "units":
      case "guestRooms":
        break;
      default:
        break;
    }
    setSelectedTab(tab);
  };

  const handleUnitTabChange = (tab) => {
    setSelectedTabUnit(tab);
  };

  const handleVenueLeft = (event) => {
    setVenueData((prevData) => ({
      ...prevData,
      compare_left_venue_id: event.target.value,
    }));
  };

  const handleVenueRight = (event) => {
    setVenueData((prevData) => ({
      ...prevData,
      compare_right_venue_id: event.target.value,
    }));
  };

  const handleCategories = (event) => {
    setVenueData((prevData) => ({
      ...prevData,
      venue_categories: event.target.value,
    }));
  };

  const handleDocumentationsList = (event) => {
    setVenueData((prevData) => ({
      ...prevData,
      documentations: event.target.value,
    }));
  };

  const handleTypeOfEvent = (event) => {
    setVenueData((prevData) => ({
      ...prevData,
      events: event.target.value,
    }));
  };

  const handleGeneralInformations = (event) => {
    setVenueData((prevData) => ({
      ...prevData,
      general_informations: event.target.value,
    }));
  };

  const handleReviewsList = (event) => {
    setVenueData((prevData) => ({
      ...prevData,
      reviews: event.target.value,
    }));
  };

  const handleSocialMedia = (event) => {
    setVenueData((prevData) => ({
      ...prevData,
      social_media: event.target.value,
    }));
  };

  const handleTestimonialsList = (event) => {
    setVenueData((prevData) => ({
      ...prevData,
      testimonials: event.target.value,
    }));
  };

  const handleAmenitiesLists = (event) => {
    setVenueData((prevData) => ({
      ...prevData,
      amenities_lists: event.target.value,
    }));
  };

  const handleCity = (event) => {
    setVenueData((prevData) => ({
      ...prevData,
      cities: event.target.value,
    }));
  };

  const handleStylesList = (event) => {
    setVenueData((prevData) => ({
      ...prevData,
      styles: event.target.value,
    }));
  };

  ///// Functions for Units

  const [unitList, setUnitList] = useState([]);

  useEffect(() => {
    if (buttonClicked) {
      fetchVenueUnits()
        .then((data) => {
          const filteredUnits = data.filter((unit) =>
            venueData.units.includes(unit.id)
          );
          const unitData = filteredUnits.map((unit) => ({
            id: unit.id,
            name: unit.name,
            total_number_of_people: unit.total_number_of_people,
            size: unit.size,
            order: unit.order,
          }));

          setUnitList(unitData);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [venueData.units, buttonClicked]);

  const handleDeleteUnit = (unitId) => {
    if (window.confirm("Da li ste sigurni da želite izbrisati ovaj unit?")) {
      deleteVenueUnit(unitId)
        .then(() => {
          setUnitList((prevUnits) =>
            prevUnits.filter((unit) => unit.id !== unitId)
          );
        })
        .catch((error) => {
          console.error("Greška pri brisanju", error);
        });
    }
  };

  const [venueUnitData, setVenueUnitData] = useState({
    name: "",
    cities: [],
    total_number_of_people: "",
    description: "",
    size: "",
    ceiling_height: "",
    dimensions: "",
    featured_image: "",
    floor_plan_image: "",
    slug: "",
    meta_keyword: "",
    meta_title: "",
    meta_description: "",
    photo_gallery: [],
    meeting_room: [],
    exhibits: [],
    amenities_lists: [],
    unit_types: [],
    order: "",
  });

  const [unitPhotoGalleries, setUnitPhotoGalleries] = useState([]);
  const [meetingRooms, setMeetingRooms] = useState([]);
  const [exhibits, setExhibits] = useState([]);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);

  const fetchUnitData = async () => {
    try {
      const unitTypesData = await fetchUnitTypes();
      const unitPhotoGalleriesData = await fetchUnitPhotoGalleries();
      const meetingRoomsData = await fetchMeetingRoomCapacities();
      const exhibitsData = await fetchExhibits();
      const amenitiesListData = await fetchUnitAmenitiesLists();

      setUnitTypes(unitTypesData);
      setUnitPhotoGalleries(unitPhotoGalleriesData);
      setMeetingRooms(meetingRoomsData);
      setExhibits(exhibitsData);
      setAmenitiesList(amenitiesListData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (buttonClicked) {
      fetchUnitData();
    }
  }, [buttonClicked]);

  const handleUnitInputChange = (event) => {
    const { name, value } = event.target;
    setVenueUnitData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUnitSubmit = (event) => {
    event.preventDefault();

    addVenueUnit(venueUnitData)
      .then((newUnit) => {
        const newUnitId = newUnit.id;

        setVenueData((prevVenueData) => ({
          ...prevVenueData,
          units: [...prevVenueData.units, newUnitId],
        }));
        setUnitList((prevUnits) => [
          ...prevUnits,
          {
            id: newUnitId,
            name: venueUnitData.name,
            total_number_of_people: venueUnitData.total_number_of_people,
            size: venueUnitData.size,
          },
        ]);
        setIsFormOpenUnit(false);
        setVenueUnitData({
          name: "",
          cities: [],
          total_number_of_people: "",
          description: "",
          size: "",
          ceiling_height: "",
          dimensions: "",
          featured_image: "",
          floor_plan_image: "",
          slug: "",
          meta_keyword: "",
          meta_title: "",
          meta_description: "",
          // photo_gallery: [],
          meeting_room: [],
          exhibits: [],
          amenities_lists: [],
          unit_types: [],
          order: "",
        });
      })
      .catch((error) => {
        console.error("Greška pri dodavanju", error);
      });
  };

  const handleUnitType = (event) => {
    setVenueUnitData((prevData) => ({
      ...prevData,
      unit_types: event.target.value,
    }));
  };

  const handleSelectAmenitiesList = (event) => {
    setVenueUnitData((prevData) => ({
      ...prevData,
      amenities_lists: event.target.value,
    }));
  };

  const handleSelectExhibits = (event) => {
    setVenueUnitData((prevData) => ({
      ...prevData,
      exhibits: event.target.value,
    }));
  };

  const handleSelectMeetingRooms = (event) => {
    setVenueUnitData((prevData) => ({
      ...prevData,
      meeting_room: event.target.value,
    }));
  };

  // const handleSelectPhotoGalleries = (event) => {
  //   setVenueUnitData((prevData) => ({
  //     ...prevData,
  //     photo_gallery: event.target.value,
  //   }));
  // };

  const [isDraggingUnit, setIsDraggingUnit] = useState(false);

  const handleDragStartUnit = () => {
    setIsDraggingUnit(true);
  };

  const handleDragEndUnit = async (e) => {
    if (!e.destination) return;
    setIsDraggingUnit(false);

    const tempData = Array.from(unitList);
    const [orderList] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, orderList);

    try {
      const updatedData = tempData.map((unit, index) => ({
        ...unit,
        order: index + 1,
      }));

      await Promise.all(
        updatedData.map((unit) =>
          editVenueUnit(unit.id, {
            order: unit.order,
            name: unit.name,
          })
        )
      );

      setUnitList(updatedData);
      console.log("Baza podataka je ažurirana sa novim redosledom distanci.");
    } catch (error) {
      console.error("Greška prilikom ažuriranja baze podataka:", error.message);
    }
  };

  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [unitImagesPivot, setUnitImagesPivot] = useState([]);

  useEffect(() => {
    if (buttonClicked) {
      const fetchData = async () => {
        try {
          const unitIds = venueData.units;

          const unitImagesPromises = unitIds.map((unitId) =>
            getUnitImages(unitId)
          );
          const unitImagesResults = await Promise.all(unitImagesPromises);

          const allImages = unitImagesResults.flatMap(
            (result) => result.images
          );

          setUnitImagesPivot(allImages);
        } catch (error) {
          console.error("Greška pri dohvatanju slika:", error.message);
        }
      };
      fetchData();
    }
  }, [venueData.units, buttonClicked]);

  const addImagesToUnit = async () => {
    try {
      for (const imageId of imagesToAdd) {
        const imageExist = await checkIfImageExistsUnit(currentUnitId, imageId);
        console.log(
          `Image ${imageId} exists: ${imageExist} galleri ${currentUnitId}`
        );
        console.log(`Image ${imageId} exists: ${imageExist}`);

        if (!imageExist) {
          await addImageToUnit(currentUnitId, imageId);
        } else {
          alert("Slika već postoji u galeriji.");
        }
      }

      const updatedImages = await getUnitImages(currentUnitId);

      setUnitImagesPivot(updatedImages.images);

      setImagesToAdd([]);

      setIsFormOpenImages(false);
    } catch (error) {
      console.error("Greška pri dodavanju slika u galeriju:", error.message);
    }
  };

  const handleDeleteImageUnit = async (unitId, imageIdToDelete) => {
    try {
      const imageIdsToDelete = imageIdToDelete;

      await removeImageFromUnit(unitId, imageIdsToDelete);

      const updatedImages = await getUnitImages(unitId);

      setUnitImagesPivot(updatedImages.images);
    } catch (error) {
      console.error("Greška pri brisanju slike iz galerije:", error.message);
    }
  };

  ///// Functions for Guest Rooms

  const [guestRoomList, setGuestRoomList] = useState([]);

  useEffect(() => {
    if (buttonClicked) {
      fetchTypeOfGuestRooms()
        .then((data) => {
          const filteredGuestRoom = data.filter((guestRoom) =>
            venueData.guest_rooms.includes(guestRoom.id)
          );
          const guestRoomData = filteredGuestRoom.map((guestRoom) => ({
            id: guestRoom.id,
            icon: guestRoom.icon,
            title: guestRoom.title,
            amount: guestRoom.amount,
            order: guestRoom.order,
          }));

          setGuestRoomList(guestRoomData);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [venueData.guest_rooms, buttonClicked]);

  const handleDeleteGuestRoom = (guestRoomId) => {
    if (window.confirm("Da li ste sigurni da želite izbrisati ovaj sobu?")) {
      deleteTypeOfGuestRoom(guestRoomId)
        .then(() => {
          setGuestRoomList((prevGuestRoom) =>
            prevGuestRoom.filter((guestRoom) => guestRoom.id !== guestRoomId)
          );
        })
        .catch((error) => {
          console.error("Greška pri brisanju", error);
        });
    }
  };

  const [typeOfGuestRoomData, setTypeOfGuestRoomData] = useState({
    icon: "",
    icon_alt_text: "",
    title: "",
    amount: "",
    galleries: [],
    order: "",
  });

  const handleGuestRoomSubmit = (event) => {
    event.preventDefault();

    addTypeOfGuestRoom(typeOfGuestRoomData)
      .then((newGuestRoomData) => {
        const newGuestRoomId = newGuestRoomData.id;

        setVenueData((prevVenueData) => ({
          ...prevVenueData,
          guest_rooms: [...prevVenueData.guest_rooms, newGuestRoomId],
        }));
        setGuestRoomList((prevGuestRoom) => [
          ...prevGuestRoom,
          {
            id: newGuestRoomId,
            icon: typeOfGuestRoomData.icon,
            title: typeOfGuestRoomData.title,
            amount: typeOfGuestRoomData.amount,
          },
        ]);
        setIsFormOpenGuestRoom(false);

        setTypeOfGuestRoomData({
          icon: "",
          icon_alt_text: "",
          title: "",
          amount: "",
          galleries: [],
          order: "",
        });
      })
      .catch((error) => {
        console.error("Greška pri dodavanju", error);
      });
  };

  const handleInputChangeGuestRoom = (event) => {
    const { name, value } = event.target;
    setTypeOfGuestRoomData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isDraggingGuestRoom, setIsDraggingGuestroom] = useState(false);

  const handleDragStartGuestRoom = () => {
    setIsDraggingGuestroom(true);
  };

  const handleDragEndGuestRoom = async (e) => {
    if (!e.destination) return;
    setIsDraggingGuestroom(false);

    const tempData = Array.from(guestRoomList);
    const [orderList] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, orderList);

    try {
      const updatedData = tempData.map((guestRoom, index) => ({
        ...guestRoom,
        order: index + 1,
      }));

      await Promise.all(
        updatedData.map((guestRoom) =>
          editTypeOfGuestRoom(guestRoom.id, {
            order: guestRoom.order,
            title: guestRoom.title,
            amount: guestRoom.amount,
          })
        )
      );

      setGuestRoomList(updatedData);
      console.log("Baza podataka je ažurirana sa novim redosledom distanci.");
    } catch (error) {
      console.error("Greška prilikom ažuriranja baze podataka:", error.message);
    }
  };
  const [selectedGuestRoomId, setSelectedGuestRoomId] = useState(null);
  const [guestRoomImagesPivot, setGuestRoomImagesPivot] = useState([]);

  useEffect(() => {
    if (buttonClicked) {
      const fetchData = async () => {
        try {
          const guestRoomIds = venueData.guest_rooms;

          const guestRoomImagesPromises = guestRoomIds.map((guestRoomId) =>
            getGuestRoomImages(guestRoomId)
          );
          const guestRoomImagesResults = await Promise.all(
            guestRoomImagesPromises
          );

          const allImages = guestRoomImagesResults.flatMap(
            (result) => result.images
          );

          setGuestRoomImagesPivot(allImages);
        } catch (error) {
          console.error("Greška pri dohvatanju slika:", error.message);
        }
      };

      fetchData();
    }
  }, [venueData.guest_rooms, buttonClicked]);

  const addImagesToGuestRoom = async () => {
    try {
      for (const imageId of imagesToAdd) {
        const imageExist = await checkIfImageExistsGuestRoom(
          currentGuestRoomId,
          imageId
        );
        console.log(
          `Image ${imageId} exists: ${imageExist} galleri ${currentGuestRoomId}`
        );
        console.log(`Image ${imageId} exists: ${imageExist}`);

        if (!imageExist) {
          await addImageToGuestRoom(currentGuestRoomId, imageId);
        } else {
          alert("Slika već postoji u galeriji.");
        }
      }

      const updatedImages = await getGuestRoomImages(currentGuestRoomId);

      setGuestRoomImagesPivot(updatedImages.images);

      setImagesToAdd([]);

      setIsFormOpenImages(false);
    } catch (error) {
      console.error("Greška pri dodavanju slika u galeriju:", error.message);
    }
  };

  const handleDeleteImageGuestRoom = async (guestRoomId, imageIdToDelete) => {
    try {
      const imageIdsToDelete = imageIdToDelete;

      await removeImageFromGuestRoom(guestRoomId, imageIdsToDelete);

      const updatedImages = await getGuestRoomImages(guestRoomId);

      setGuestRoomImagesPivot(updatedImages.images);
    } catch (error) {
      console.error("Greška pri brisanju slike iz galerije:", error.message);
    }
  };

  ///// Functions for distance list

  const [distanceSectionList, setDistanceSectionList] = useState([]);

  useEffect(() => {
    if (buttonClicked) {
      fetchDistanceLists()
        .then((data) => {
          const filteredDistanceList = data.filter((distanceLists) =>
            venueData.distance_lists.includes(distanceLists.id)
          );
          const distanceSectionListData = filteredDistanceList.map(
            (distanceLists) => ({
              id: distanceLists.id,
              title: distanceLists.title,
              distance_amount: distanceLists.distance_amount,
              order: distanceLists.order,
            })
          );

          setDistanceSectionList(distanceSectionListData);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [venueData.distance_lists, buttonClicked]);

  const handleInputChangeDistance = (event) => {
    const { name, value } = event.target;
    setDistanceSectionListData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDeleteDistance = (distanceId) => {
    if (
      window.confirm("Da li ste sigurni da želite izbrisati ovaj distancu?")
    ) {
      deleteDistanceList(distanceId)
        .then(() => {
          setDistanceSectionList((prevDistance) =>
            prevDistance.filter((distance) => distance.id !== distanceId)
          );
        })
        .catch((error) => {
          console.error("Greška pri brisanju", error);
        });
    }
  };

  const [distanceSectionListData, setDistanceSectionListData] = useState({
    title: "",
    distance_amount: "",
    order: "",
  });

  const handleDistanceSubmit = (event) => {
    event.preventDefault();

    const dataToSend = {
      ...distanceSectionListData,
      distance_amount: `${distanceSectionListData.distance_amount} ${selectedDistanceUnit}`,
    };

    addDistanceList(dataToSend)
      .then((newDistanceListData) => {
        const newDistanceListId = newDistanceListData.id;

        setVenueData((prevVenueData) => ({
          ...prevVenueData,
          distance_lists: [...prevVenueData.distance_lists, newDistanceListId],
        }));
        setDistanceSectionList((prevDistanceList) => [
          ...prevDistanceList,
          {
            id: newDistanceListId,
            title: distanceSectionListData.title,
            distance_amount: `${distanceSectionListData.distance_amount} ${selectedDistanceUnit}`,
            order: distanceSectionListData.order,
          },
        ]);
        setDistanceSectionListData({
          title: "",
          distance_amount: "",
          distance_unit: "km",
          order: "",
        });
        setIsFormOpenDistance(false);
      })
      .catch((error) => {
        console.error("Greška pri dodavanju", error);
      });
  };

  const [selectedDistanceUnit, setSelectedDistanceUnit] = useState("km");

  const handleInputChangeDistanceUnit = (event) => {
    const selectedValue = event.target.value;

    setSelectedDistanceUnit(selectedValue);

    console.log("Odabrana udaljenost:", selectedValue);
  };

  const [isDraggingDistance, setIsDraggingDistance] = useState(false);

  const handleDragStart = () => {
    setIsDraggingDistance(true);
  };

  const handleDragEnd = async (e) => {
    if (!e.destination) return;
    setIsDraggingDistance(false);

    const tempData = Array.from(distanceSectionList);
    const [draggedItem] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, draggedItem);

    try {
      const updatedData = tempData.map((distance, index) => ({
        ...distance,
        order: index + 1,
      }));

      await Promise.all(
        updatedData.map((distance) =>
          editDistanceList(distance.id, {
            order: distance.order,
            title: distance.title,
          })
        )
      );

      setDistanceSectionList(updatedData);
      console.log("Baza podataka je ažurirana sa novim redosledom distanci.");
    } catch (error) {
      console.error("Greška prilikom ažuriranja baze podataka:", error.message);
    }
  };

  ///// Functions for Galleries

  const [galleriesList, setGalleriesList] = useState([]);

  useEffect(() => {
    if (buttonClicked) {
      fetchVenuePhotoGalleries()
        .then((data) => {
          const filteredGalleriesList = data.filter((galleriesList) =>
            venueData.photo_galleries.includes(galleriesList.id)
          );
          const galleryListData = filteredGalleriesList.map((galleryLists) => ({
            id: galleryLists.id,
            image: galleryLists.image,
            alt_text: galleryLists.alt_text,
            name: galleryLists.name,
          }));

          setGalleriesList(galleryListData);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [venueData.photo_galleries, buttonClicked]);

  const handleInputChangeGallery = (event) => {
    const { name, value } = event.target;
    setGalleryListData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDeleteGallery = (galleryId) => {
    if (
      window.confirm("Da li ste sigurni da želite izbrisati ovaj galeriju?")
    ) {
      deleteVenuePhotoGallery(galleryId)
        .then(() => {
          setGalleriesList((prevGalleries) =>
            prevGalleries.filter((gallery) => gallery.id !== galleryId)
          );
        })
        .catch((error) => {
          console.error("Greška pri brisanju", error);
        });
    }
  };

  const [galleryListData, setGalleryListData] = useState({
    image: "",
    alt_text: "",
    name: "",
  });

  const handleGallerySubmit = (event) => {
    event.preventDefault();

    addVenuePhotoGallery(galleryListData)
      .then((newGalleryListData) => {
        const newGalleryListId = newGalleryListData.id;

        setVenueData((prevVenueData) => ({
          ...prevVenueData,
          photo_galleries: [...prevVenueData.photo_galleries, newGalleryListId],
        }));
        setGalleriesList((prevGalleryList) => [
          ...prevGalleryList,
          {
            id: newGalleryListId,
            image: galleryListData.image,
            alt_text: galleryListData.alt_text,
            name: galleryListData.name,
          },
        ]);
        setIsFormOpenGallery(false);

        setGalleryListData({
          image: "",
          alt_text: "",
          name: "",
        });
      })
      .catch((error) => {
        console.error("Greška pri dodavanju", error);
      });
  };

  //// Gallery section

  const [selectedGalleryId, setSelectedGalleryId] = useState(null);
  const [galleriesImagesPivot, setGalleriesImagesPivot] = useState([]);

  useEffect(() => {
    if (buttonClicked) {
      const fetchData = async () => {
        try {
          const galleryIds = venueData.photo_galleries;

          const galleryImagesPromises = galleryIds.map((galleryId) =>
            getGalleryImages(galleryId)
          );
          const galleryImagesResults = await Promise.all(galleryImagesPromises);

          const allImages = galleryImagesResults.flatMap(
            (result) => result.images
          );

          setGalleriesImagesPivot(allImages);
        } catch (error) {
          console.error("Greška pri dohvatanju slika:", error.message);
        }
      };

      fetchData();
    }
  }, [venueData.photo_galleries, buttonClicked]);

  const [addedImages, setAddedImages] = useState([]);

  useEffect(() => {
    fetchFiles()
      .then((data) => {
        setAddedImages(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [imagesToAdd, setImagesToAdd] = useState([]);

  const handleImageClick = (imageId) => {
    if (!imagesToAdd.includes(imageId)) {
      setImagesToAdd([...imagesToAdd, imageId]);
    } else {
      const updatedImages = imagesToAdd.filter((id) => id !== imageId);
      setImagesToAdd(updatedImages);
    }
  };

  const addImagesToGallery = async () => {
    try {
      for (const imageId of imagesToAdd) {
        const imageExist = await checkIfImageExists(currentGalleryId, imageId);
        console.log(`Image ${imageId} exists: ${imageExist}`);

        if (!imageExist) {
          await addImageToGallery(currentGalleryId, imageId);
        } else {
          alert("Slika već postoji u galeriji.");
        }
      }

      const updatedImages = await getGalleryImages(currentGalleryId);

      setGalleriesImagesPivot(updatedImages.images);

      setImagesToAdd([]);

      setIsFormOpenImages(false);

      setImagesToAdd([]);
    } catch (error) {
      console.error("Greška pri dodavanju slika u galeriju:", error.message);
    }
  };

  const handleDeleteImage = async (galleryId, imageIdToDelete) => {
    try {
      const imageIdsToDelete = imageIdToDelete;

      await removeImageFromGallery(galleryId, imageIdsToDelete);

      const updatedImages = await getGalleryImages(galleryId);

      setGalleriesImagesPivot(updatedImages.images);
    } catch (error) {
      console.error("Greška pri brisanju slike iz galerije:", error.message);
    }
  };

  const handleAddToGalleryImage = async (galleryId, imagePath) => {
    try {
      const currentGallery = await getVenuePhotoGallery(galleryId);
      const updatedGallery = await editVenuePhotoGallery(galleryId, {
        image: imagePath,
        name: currentGallery.name,
      });

      setGalleriesList((prevGalleries) => {
        const updatedGalleries = prevGalleries.map((gallery) => {
          if (gallery.id === galleryId) {
            return updatedGallery;
          }
          return gallery;
        });
        return updatedGalleries;
      });

      alert("Image added to gallery successfully!");
    } catch (error) {
      console.error(`Greška pri dodavanju slike u galeriju: ${error.message}`);
    }
  };

  // Video section

  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    if (buttonClicked) {
      fetchVenueVideoGalleries()
        .then((data) => {
          const filteredVideoList = data.filter((videoList) =>
            venueData.video_galleries.includes(videoList.id)
          );
          const videoListData = filteredVideoList.map((videoLists) => ({
            id: videoLists.id,
            video_url: videoLists.video_url,
            description: videoLists.description,
            thumbnail_image: videoLists.thumbnail_image,
          }));

          setVideoList(videoListData);
          console.log(filteredVideoList);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [venueData.video_galleries, buttonClicked]);

  const handleDeleteVideoGallery = (videoId) => {
    if (window.confirm("Da li ste sigurni da želite izbrisati ovaj video?")) {
      deleteVenueVideoGallery(videoId)
        .then(() => {
          setVideoList((prevVideos) =>
            prevVideos.filter((video) => video.id !== videoId)
          );
        })
        .catch((error) => {
          console.error("Greška pri brisanju", error);
        });
    }
  };

  const [videoListData, setVideoListData] = useState({
    video_url: "",
    description: "",
    thumbnail_image: "",
  });

  const handleInputChangeVideo = (event) => {
    const { name, value } = event.target;
    setVideoListData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleVideoSubmit = (event) => {
    event.preventDefault();

    addVenueVideoGallery(videoListData)
      .then((newVideoListData) => {
        const newVideoListId = newVideoListData.id;

        setVenueData((prevVenueData) => ({
          ...prevVenueData,
          video_galleries: [...prevVenueData.video_galleries, newVideoListId],
        }));
        setVideoList((prevGalleryList) => [
          ...prevGalleryList,
          {
            id: newVideoListId,
            video_url: galleryListData.video_url,
            description: galleryListData.description,
            thumbnail_image: galleryListData.thumbnail_image,
          },
        ]);
        setIsFormOpenVideo(false);

        setVideoListData({
          video_url: "",
          description: "",
          thumbnail_image: "",
        });
      })
      .catch((error) => {
        console.error("Greška pri dodavanju", error);
      });
  };

  ///////////////Featured image

  const handleImageSelection = async (selectedImage) => {
    try {
      const fileId = selectedImage[1];
      const fileData = await getFile(fileId);

      setVenueData({ ...venueData, featured_image: fileData.path });

      closeFormModalImages();
    } catch (error) {
      console.error(`Greška pri dobavljanju slike: ${error.message}`);
    }
  };

  const handleFeaturedImageUnitSelection = async (selectedImage) => {
    try {
      const fileId = selectedImage[1];
      const fileData = await getFile(fileId);

      setVenueUnitData({ ...venueUnitData, featured_image: fileData.path });
      closeFormModalImages();
    } catch (error) {
      console.error(`Greška pri dobavljanju slike: ${error.message}`);
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <Typography
            variant="h4"
            gutterBottom
            sx={{ textAlign: "center", marginLeft: "25%" }}
          >
            Venue {isEditMode ? venueData.name : ""}
          </Typography>

          <form onSubmit={handleSubmit}>
            <Container style={{ marginLeft: "25%", maxWidth: "75%" }}>
              <Container>
                <MainInfo
                  loading={loading}
                  isFormOpenUnit={isFormOpenUnit}
                  isFormOpenGuestRoom={isFormOpenGuestRoom}
                  isFormOpenDistance={isFormOpenDistance}
                  isFormOpenGallery={isFormOpenGallery}
                  isFormOpenImages={isFormOpenImages}
                  isFormOpenVideo={isFormOpenVideo}
                  venueData={venueData}
                  handleInputChange={handleInputChange}
                  handleCity={handleCity}
                  citiesList={citiesList}
                  isEditMode={isEditMode}
                  toggleImageForm={toggleImageForm}
                />
              </Container>

              <Container
                sx={{
                  marginTop: "10px",
                  marginBottom: "15px",
                  display: "flex",
                  justifyContent: "space-around",
                  paddingBottom: "15px",
                  paddingTop: "15px",
                  borderTop: "2px solid #49559d",
                  borderBottom: "2px solid #49559d",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => handleTabChange("overview")}
                  sx={{
                    backgroundColor:
                      selectedTab === "overview" ? "#49559d" : "initial",
                    color: selectedTab === "overview" ? "white" : "#49559d",
                    "&:hover": {
                      color: "#49559d",
                    },
                  }}
                >
                  Overview
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleTabChange("mediaGallery")}
                  sx={{
                    backgroundColor:
                      selectedTab === "mediaGallery" ? "#49559d" : "initial",
                    color: selectedTab === "mediaGallery" ? "white" : "#49559d",
                    "&:hover": {
                      color: "#49559d",
                    },
                  }}
                >
                  Media Gallery
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleTabChange("distance")}
                  sx={{
                    backgroundColor:
                      selectedTab === "distance" ? "#49559d" : "initial",
                    color: selectedTab === "distance" ? "white" : "#49559d",
                    "&:hover": {
                      color: "#49559d",
                    },
                  }}
                >
                  Distance
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleTabChange("units")}
                  sx={{
                    backgroundColor:
                      selectedTab === "units" ? "#49559d" : "initial",
                    color: selectedTab === "units" ? "white" : "#49559d",
                    "&:hover": {
                      color: "#49559d",
                    },
                  }}
                >
                  Units
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleTabChange("guestRooms")}
                  sx={{
                    backgroundColor:
                      selectedTab === "guestRooms" ? "#49559d" : "initial",
                    color: selectedTab === "guestRooms" ? "white" : "#49559d",
                    "&:hover": {
                      color: "#49559d",
                    },
                  }}
                >
                  Guest Rooms
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleTabChange("seo")}
                  sx={{
                    backgroundColor:
                      selectedTab === "seo" ? "#49559d" : "initial",
                    color: selectedTab === "seo" ? "white" : "#49559d",
                    "&:hover": {
                      color: "#49559d",
                    },
                  }}
                >
                  SEO
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleTabChange("more")}
                  sx={{
                    backgroundColor:
                      selectedTab === "more" ? "#49559d" : "initial",
                    color: selectedTab === "more" ? "white" : "#49559d",
                    "&:hover": {
                      color: "#49559d",
                    },
                  }}
                >
                  More
                </Button>
              </Container>
              {selectedTab === "overview" && (
                <OverviewInfo
                  loading={loading}
                  venueData={venueData}
                  handleInputChange={handleInputChange}
                  handleCategories={handleCategories}
                  categoriesList={categoriesList}
                  handleGeneralInformations={handleGeneralInformations}
                  generalInformations={generalInformations}
                  handleAmenitiesLists={handleAmenitiesLists}
                  amenitiesLists={amenitiesLists}
                  handleTypeOfEvent={handleTypeOfEvent}
                  typeOfEvent={typeOfEvent}
                  handleStylesList={handleStylesList}
                  stylesList={stylesList}
                />
              )}

              {selectedTab === "mediaGallery" && (
                <MediaGalleryInfo
                  galleriesList={galleriesList}
                  handleDeleteGallery={handleDeleteGallery}
                  setSelectedGalleryId={setSelectedGalleryId}
                  selectedGalleryId={selectedGalleryId}
                  toggleImageForm={toggleImageForm}
                  galleriesImagesPivot={galleriesImagesPivot}
                  filesList={addedImages}
                  handleDeleteImage={handleDeleteImage}
                  handleAddToGalleryImage={handleAddToGalleryImage}
                  toggleGalleryForm={toggleGalleryForm}
                  handleDeleteVideoGallery={handleDeleteVideoGallery}
                  toggleVideoForm={toggleVideoForm}
                  videoList={videoList}
                />
              )}

              {selectedTab === "distance" && (
                <DistanceInfo
                  toggleDistanceForm={toggleDistanceForm}
                  distanceSectionList={distanceSectionList}
                  handleDeleteDistance={handleDeleteDistance}
                  handleDragEnd={handleDragEnd}
                  handleDragStart={handleDragStart}
                  isDraggingDistance={isDraggingDistance}
                />
              )}

              {selectedTab === "units" && (
                <UnitInfo
                  toggleUnitForm={toggleUnitForm}
                  unitList={unitList}
                  handleDeleteUnit={handleDeleteUnit}
                  isDraggingUnit={isDraggingUnit}
                  handleDragEndUnit={handleDragEndUnit}
                  handleDragStartUnit={handleDragStartUnit}
                  setSelectedUnitId={setSelectedUnitId}
                  selectedUnitId={selectedUnitId}
                  toggleImageForm={toggleImageForm}
                  unitImagesPivot={unitImagesPivot}
                  handleDeleteImageUnit={handleDeleteImageUnit}
                  filesList={addedImages}
                />
              )}

              {selectedTab === "guestRooms" && (
                <GuestRoomInfo
                  toggleGuestRoomForm={toggleGuestRoomForm}
                  guestRoomList={guestRoomList}
                  handleDeleteGuestRoom={handleDeleteGuestRoom}
                  isDraggingGuestRoom={isDraggingGuestRoom}
                  handleDragEndGuestRoom={handleDragEndGuestRoom}
                  handleDragStartGuestRoom={handleDragStartGuestRoom}
                  toggleImageForm={toggleImageForm}
                  guestRoomImagesPivot={guestRoomImagesPivot}
                  setSelectedGuestRoomId={setSelectedGuestRoomId}
                  selectedGuestRoomId={selectedGuestRoomId}
                  filesList={addedImages}
                  handleDeleteImageGuestRoom={handleDeleteImageGuestRoom}
                />
              )}

              {selectedTab === "seo" && (
                <SeoInfo
                  venueData={venueData}
                  handleInputChange={handleInputChange}
                />
              )}

              {selectedTab === "more" && (
                <MoreInfo
                  venueData={venueData}
                  handleInputChange={handleInputChange}
                  handleDocumentationsList={handleDocumentationsList}
                  documentationsList={documentationsList}
                  handleTestimonialsList={handleTestimonialsList}
                  testimonialsList={testimonialsList}
                  handleReviewsList={handleReviewsList}
                  reviewsList={reviewsList}
                  handleVenueRight={handleVenueRight}
                  venue={venue}
                  handleVenueLeft={handleVenueLeft}
                  handleSocialMedia={handleSocialMedia}
                  socialMedia={socialMedia}
                />
              )}
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-venue" ? "Publish" : "Save"}
                </Button>
              </Container>
            </Container>
          </form>
          <UnitModal
            isFormOpenUnit={isFormOpenUnit}
            closeFormModal={closeFormModal}
            handleUnitTabChange={handleUnitTabChange}
            selectedTabUnit={selectedTabUnit}
            handleUnitSubmit={handleUnitSubmit}
            venueUnitData={venueUnitData}
            handleUnitInputChange={handleUnitInputChange}
            // handleSelectPhotoGalleries={handleSelectPhotoGalleries}
            unitPhotoGalleries={unitPhotoGalleries}
            handleSelectMeetingRooms={handleSelectMeetingRooms}
            meetingRooms={meetingRooms}
            handleSelectExhibits={handleSelectExhibits}
            exhibits={exhibits}
            handleSelectAmenitiesList={handleSelectAmenitiesList}
            amenitiesList={amenitiesList}
            handleUnitType={handleUnitType}
            unitTypes={unitTypes}
            toggleImageForm={toggleImageForm}
          />
          <GuestRoomModal
            isFormOpenGuestRoom={isFormOpenGuestRoom}
            closeFormModalGuestRoom={closeFormModalGuestRoom}
            handleGuestRoomSubmit={handleGuestRoomSubmit}
            typeOfGuestRoomData={typeOfGuestRoomData}
            handleInputChangeGuestRoom={handleInputChangeGuestRoom}
          />
          <DistanceModal
            isFormOpenDistance={isFormOpenDistance}
            closeFormModalDistance={closeFormModalDistance}
            handleDistanceSubmit={handleDistanceSubmit}
            distanceSectionList={distanceSectionList}
            handleInputChangeDistance={handleInputChangeDistance}
            distanceSectionListData={distanceSectionListData}
            handleInputChangeDistanceUnit={handleInputChangeDistanceUnit}
          />
          <GalleryModal
            isFormOpenGallery={isFormOpenGallery}
            closeFormModalGallery={closeFormModalGallery}
            handleGallerySubmit={handleGallerySubmit}
            galleryListData={galleryListData}
            handleInputChangeGallery={handleInputChangeGallery}
          />
          <ImagesModal
            isFormOpenImages={isFormOpenImages}
            closeFormModalImages={closeFormModalImages}
            addImagesToGallery={addImagesToGallery}
            addImagesToGuestRoom={addImagesToGuestRoom}
            addedImages={addedImages}
            imagesToAdd={imagesToAdd}
            handleImageClick={handleImageClick}
            handleImageSelection={handleImageSelection}
            image={imagesToAdd}
            path={path}
            addImagesToUnit={addImagesToUnit}
            handleFeaturedImageUnitSelection={handleFeaturedImageUnitSelection}
          />
          <VideoModal
            isFormOpenVideo={isFormOpenVideo}
            closeFormModalVideo={closeFormModalVideo}
            handleVideoSubmit={handleVideoSubmit}
            handleInputChangeVideo={handleInputChangeVideo}
            videoListData={videoListData}
          />
        </div>
      </div>
    </Layout>
  );
};

export default AddEditVenue;
