import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchUnitAmenitiesLists = () => {
  return axios
    .get(`${API_URL}/venue-unit-amenity-list`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteUnitAmenitiesList = (unitAmenitiesListId) => {
  return axios
    .delete(`${API_URL}/venue-unit-amenity-list/${unitAmenitiesListId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju dokumenta: ${error.message}`);
    });
};

export const getUnitAmenitiesList = async (id) => {
  try {
    const response = await axios.get(
      `${API_URL}/venue-unit-amenity-list/${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju dokumenta: ${error.message}`);
  }
};

export const editUnitAmenitiesList = async (id, unitAmenitiesListData) => {
  try {
    const response = await axios.put(
      `${API_URL}/venue-unit-amenity-list/${id}`,
      unitAmenitiesListData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju dokumenta: ${error.message}`);
  }
};

export const addUnitAmenitiesList = (unitAmenitiesListData) => {
  return axios
    .post(`${API_URL}/venue-unit-amenity-list`, unitAmenitiesListData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju dokumenta: ${error.message}`);
    });
};
