import axios from "axios";
import { API_URL } from "../const";

export const fetchExhibits = async () => {
  return axios
    .get(`${API_URL}/coworking/exhibits`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteExhibit = async (id) => {
  return axios
    .delete(`${API_URL}/coworking/exhibits/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const addExhibit = async (data) => {
  return axios
    .post(`${API_URL}/coworking/exhibits`, data)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getExhibit = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/coworking/exhibits/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editExhibit = async (id, data) => {
  try {
    const response = await axios.put(`${API_URL}/coworking/exhibits/${id}`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
