import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import Layout from "../../../layout/Layout";
import VenueMenu from "../../../components/VenueMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addSocialMedia,
  getSocialMedia,
  editSocialMedia,
} from "../../../services/venues/VenueSocialMedia";
import ButtonBack from "../../../components/ButtonBack";
import IconModal from "../../../components/Venue/Modals/IconModal";

const AddEditSocialMedia = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  location = location.pathname.slice(1);
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  const isEditMode = location === "add-new-social-media" ? false : true;

  const [socialMediaData, setSocialMediaData] = useState({
    title: "",
    icon: "",
    iconAltText: "",
    link: "",
  });

  useEffect(() => {
    if (id) {
      getSocialMedia(id)
        .then((data) => {
          setSocialMediaData({
            icon: data.icon,
            iconAltText: data.icon_alt_text,
            title: data.title,
            link: data.link,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSocialMediaData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newSocialMediaData = {
      icon: socialMediaData.icon,
      icon_alt_text: socialMediaData.iconAltText,
      title: socialMediaData.title,
      link: socialMediaData.link,
    };
    if (isEditMode) {
      editSocialMedia(id, newSocialMediaData)
        .then((response) => {
          setSocialMediaData({
            icon: "",
            iconAltText: "",
            title: "",
            link: "",
          });

          navigate("/social-media");
        })
        .catch((error) => {
          console.error("Greška pri ažuriranju:", error);
        });
    } else {
      addSocialMedia(newSocialMediaData)
        .then((response) => {
          setSocialMediaData({
            ...socialMediaData,
            icon: response.icon,
            iconAltText: response.icon_alt_text,
            title: response.title,
            documentLink: response.document_link,
          });
          navigate("/social-media");
        })
        .catch((error) => {
          console.error("Greška pri dodavanju:", error);
        });
    }
  };

  return (
    <Layout>
      <div>
        <VenueMenu />
        <div stile={{ width: "79%" }}>
          <ButtonBack />
          <div style={{ marginLeft: "26%" }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
              Social Media
            </Typography>

            <form onSubmit={handleSubmit}>
              <Container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <IconModal
                  setData={setSocialMediaData}
                  data={socialMediaData.icon}
                  handleInputChange={handleInputChange}
                  setImageModalOpen={setImageModalOpen}
                  isImageModalOpen={isImageModalOpen}
                />
                <Container style={{ marginTop: "30px" }}>
                  <TextField
                    name="title"
                    label={isImageModalOpen ? "" : "Title"}
                    required
                    value={socialMediaData.title}
                    fullWidth
                    onChange={handleInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="iconAltText"
                    label={isImageModalOpen ? "" : "Icon Alt Text"}
                    value={socialMediaData.iconAltText}
                    fullWidth
                    onChange={handleInputChange}
                  />
                  <TextField
                    sx={{ marginTop: "30px" }}
                    name="link"
                    label={isImageModalOpen ? "" : "Link"}
                    required
                    value={socialMediaData.link}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </Container>
              </Container>
              <Container
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  type="submit"
                  size="medium"
                  variant="outlined"
                >
                  {location === "add-new-social-media" ? "Publish" : "Save"}
                </Button>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEditSocialMedia;
