import axios from "axios";
import { API_URL } from "./apiUrl";

// export const API_URL = 'https://montenegromice.com/mice-api';

export const fetchVenueInquiries = () => {
  return axios
    .get(`${API_URL}/venue-inquiries`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const deleteVenueInquiry = (venueInquiryId) => {
  return axios
    .delete(`${API_URL}/venue-inquiries/${venueInquiryId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri brisanju: ${error.message}`);
    });
};

export const getVenueInquiry = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/venue-inquiries/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri dobavljanju: ${error.message}`);
  }
};

export const editVenueInquiry = async (id, venueInquiryData) => {
  try {
    const response = await axios.put(
      `${API_URL}/venue-inquiries/${id}`,
      venueInquiryData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Greška pri editovanju: ${error.message}`);
  }
};

export const addVenueInquiry = (venueInquiryData) => {
  return axios
    .post(`${API_URL}/venue-inquiries`, venueInquiryData)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Greška pri dodavanju: ${error.message}`);
    });
};
